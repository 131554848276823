import React, { useEffect, useState } from "react";

import {
    Button,
    Modal,
    ModalHeader,
    ModalFooter,
    ModalBody,
} from "reactstrap";
import externalUrl from "config/externalUrl";

import axios from "axios";
import LenderSelect from "./LenderSelect/LenderSelect";
import AccessTable from "./AccessTable/AccessTable";
import LoadingCircles from "common/components/AnimatedIcons/LoadingCircles/LoadingCircles";

function ModalAccess(props) {
    const { modal, toggle, notify, data } = props;
    const [selectedLender, setSelectedLender] = useState({ id: '' });
    const [lenders, setLenders] = useState({ id: '' });
    const [creditos, setCreditos] = useState([]);
    const [loading, setLoading] = useState(false);

    const [userData] = useState(JSON.parse(localStorage.getItem('userData')));

    useEffect(() => {
        fetchData(); // eslint-disable-next-line
    }, [selectedLender.id, userData.private_token]);

    useEffect(() => {
        fetchDataLenders(); // eslint-disable-next-line
    }, [userData.private_token, data.uid]);

    const fetchData = () => {
        setLoading(true);
        if (selectedLender.id !== '') {
            axios.get(`${externalUrl.lenderUrl}/v1/lender-user/get-cedulas-access?uid=${data.uid}&id=${selectedLender.id}`, {
                headers: { Authorization: `Bearer ${userData.private_token}`, },
            }).then(function (response) {
                if (response.data.error === 1) {
                    setCreditos([]);
                } else {
                    const newCreditos = response.data.data.map(row => (
                        {
                            ...row,
                            index_row: (parseInt(row.index_row)),
                            isSelected: parseInt(row.isSelected) === 1,
                            id: (parseInt(row.index_row)),
                            cedulas: row.position?.split(',').map((item, index) => ({ id: (index + 1), value: item, owner: row.owner, owner_name: row.owner_name, isSelected: parseInt(row.isSelectedCedula?.split(',')[index]) === 1, ownership: row.cedula_ownership.split(',')[index], actual: row.actual.split(',')[index] }))
                        }));
                    setCreditos(newCreditos);
                }
            }).catch(function (response) { console.error(response); })
                .finally(() => setLoading(false));
        }
    }

    const fetchDataLenders = () => {
        axios.get(`${externalUrl.lenderUrl}/v1/lender/enabled-with-access?uid=${data.uid}`, {
            headers: { Authorization: `Bearer ${userData.private_token}`, },
        }).then(function (response) {
            if (response.data.error === '1') {
                setLenders([]);
            } else {
                let selected = 0;
                const data = response.data.msg.map((lender => {
                    if (selectedLender.id === lender.id) {
                        selected = lender;
                    }
                    return { value: lender.id, label: lender.id + " - " + lender.display_name + " - " + lender.nit, data: lender };
                }));
                setLenders(data);
                let count = document.getElementById('count-access');
                if (count !== null) {
                    count.innerHTML = selected.count;
                }
            }
        }).catch(function (response) { console.error(response); });
    }

    const saveAccess = () => {
        const selectedValues = [];
        creditos.map(item => item.cedulas.map(row => selectedValues.push(row)));
        const body = { data: selectedValues, uid: data.uid, user: userData.display_name, lender: selectedLender.id };
        axios.post(`${externalUrl.lenderUrl}/v1/lender-user/create-or-update-access`, body, {
            headers: { Authorization: `Bearer ${userData.private_token}`, },
        }).then(function (response) {
            notify("br", response.data.msg, response.data.error ? "danger" : "success");
            fetchDataLenders();
            /* fetchData(); */
        }).catch(function () {
            /* localStorage.removeItem('userData');
            window.location.replace(`${externalUrl.lenderUrl}/v1/auth/logout`); */
        });
    }

    return (
        <Modal className="modalAccess" isOpen={modal} toggle={toggle} style={{ top: '5vh' }} size="md" id='modalAccess'>
            <ModalHeader toggle={toggle}>
                Agregar acceso usuario
            </ModalHeader>
            <ModalBody style={{ paddingBottom: '0', maxHeight: '70vh' }}>
                <LenderSelect selectedLender={selectedLender} setSelectedLender={setSelectedLender} uid={data?.uid} lenders={lenders} />
                <div style={{ marginTop: '1rem' }} />
                {!loading ?
                    creditos.length === 0 ? <></> :
                        <AccessTable data={creditos} setData={setCreditos} selectedLender={selectedLender} />
                    :
                    <div>
                        <center>
                            Obteniendo información... &nbsp;&nbsp;<LoadingCircles />
                        </center>
                        <br />
                    </div>
                }
            </ModalBody>
            <ModalFooter style={{ paddingTop: '0.3rem', paddingBottom: '0.3rem' }}>
                <Button
                    className="btn button-alapar-success"
                    onClick={saveAccess}>
                    Guardar
                </Button>

                <Button
                    className="button-alapar-cancel button-right"
                    outline
                    color="primary"
                    onClick={toggle}>
                    Cerrar
                </Button>
            </ModalFooter>
        </Modal >
    );
}

export default ModalAccess;
