import React from "react";

import {
    Row,
    FormGroup,
    Collapse,
} from "reactstrap";

import { useEffect } from "react";

const styleTitle = {
    fontSize: '1rem',
    margin: 0,
    fontWeight: 700,
    lineHeight: 1.42857143,
};

const styleLabel = {
    fontSize: '1rem',
    color: '#252422',
    lineHeight: 1.42857143,
};

function ViewCedulaLender(props) {

    useEffect(() => {
        props.lendersCedula.map((lender, j) => {
            if (!lender.hasOwnProperty('view')) {
                const newLender = JSON.parse(JSON.stringify(props.cedulaOwnership));
                newLender[props.index].lenders[j] = { ...newLender[props.index].lenders[j], view: true };
                props.setCedulaOwnership(newLender);
            }
            return lender;
        });
    }, [props]);

    return (
        <>
            {
                props.lendersCedula.map((lender, i) => (
                    <Collapse key={"lender-" + i} isOpen={lender.view}>
                        <FormGroup>
                            <Row style={{ display: 'flex', justifyContent: "flex-start", gap: '0.2rem', marginLeft: '0rem', flexDirection: 'row' }}>
                                {/* <div style={{ marginTop: '-0.8rem' }}>
                                    <Button className='btn' color='link' style={{ color: '#51cbce' }} onClick={() => props.addLenders(props.index)} ><BsPlusLg /></Button>
                                </div> */}
                                <div style={{ maxWidth: '8rem', width: '100%' }}>
                                    <FormGroup>
                                        <p style={styleTitle}>Lender</p>
                                        <div style={{ float: 'left' }}>
                                            <label style={styleLabel}>{props.lenders.filter(option =>
                                                option.id === lender.lender)[0]?.id + " - " + props.lenders.filter(option =>
                                                    option.id === lender.lender)[0]?.display_name}</label>
                                        </div>
                                    </FormGroup>
                                </div>
                                <div style={{ paddingLeft: '0.4rem' }}>
                                    <FormGroup>
                                        <p style={styleTitle}>% Pago</p>
                                        <div style={{ float: 'right' }}>
                                            <label style={styleLabel}>{lender.percentage}</label>
                                        </div>
                                    </FormGroup>
                                </div>
                            </Row>
                        </FormGroup>
                    </Collapse>
                ))
            }
        </>
    );
}

export default ViewCedulaLender;