import React, { useEffect, useState } from "react";

import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  FormGroup,
  Input,
  Button,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import NotificationAlert from "react-notification-alert";

import Select from 'react-select';
import countries from "data/countries";
import axios from "axios";
import externalUrl from "config/externalUrl";
import { Link, Redirect, useParams } from "react-router-dom";
import currency from "data/currency";
import FormPersonNatural from "./PersonNatural";
import FormPersonLegal from "./PersonLegal";

function FormLender(props) {
  const notificationAlert = React.useRef();
  const [formType, setFormType] = useState(1);
  const [userData] = useState(JSON.parse(localStorage.getItem('userData')));
  const [lender, setLender] = useState({ type: '', code: '', nit: '', display_name: '', notes: '', person_type: 1, person_entity_ref: 0, nationality: '', preferred_currency: '', created_by: userData.display_name });
  const [personLegal, setPersonLegal] = useState({ code: '', display_name: '', main_country: '', status: 1, created_by: userData.display_name });
  const [personNatural, setPersonNatural] = useState({ first_name: '', second_name: '', first_surname: '', second_surname: '', family_lastname: '', birthday: '', country_residence: '', created_by: userData.display_name });

  const [redirect, setRedirect] = useState({ status: false, to: '/admin/lender' });
  const [lenderType, setLenderType] = useState([]);

  const [nitError, setNitError] = useState(false);
  const [nameError, setNameError] = useState(false);
  /* const [codeError, setCodeError] = useState(false); */
  const [firstNameError, setFirstNameError] = useState(false);
  const [firstSurnameError, setFirstSurnameError] = useState(false);
  const [displayError, setDisplayError] = useState(false);
  const [options/* , setOptions */] = useState({});
  const [modal, setModal] = useState(false);
  const [modalType, setModalType] = useState(false);
  const [pressedToggle, setPressedToggle] = useState(false);

  let { id } = useParams();

  useEffect(() => {
    if (props.location.update || id != null) {
      fetchData();
    }
    getLendersType();
  }, []); // eslint-disable-line

  async function getLendersType() {
    axios.get(`${externalUrl.lenderUrl}/v1/lender-type/enabled`, {
      headers: { Authorization: `Bearer ${userData.private_token}`, },
    }).then(function (response) {
      if (response.data.error === '1') {
        toggle();
      } else {
        const data = response.data.msg.map((type => ({ value: type.id, label: type.name })));
        setLenderType(data);
      }
    }).catch(
      function () {
      }
    );
  };

  async function fetchData() {
    axios.get(`${externalUrl.lenderUrl}/v1/lender/search?id=${id}`, {
      headers: { Authorization: `Bearer ${userData.private_token}`, },
    }).then(function (response) {
      if (response.data.error !== '0') {
        setRedirect({ ...redirect, status: true });
      } else {
        setLender(response.data.msg);
        var url = '';
        if (response.data.msg.person_type === 1) {
          url = `${externalUrl.lenderUrl}/v1/person-natural/search?id=${response.data.msg.person_entity_ref}`;
        } else {
          url = `${externalUrl.lenderUrl}/v1/person-legal/search?id=${response.data.msg.person_entity_ref}`;
        }

        setFormType(response.data.msg.person_type);

        axios.get(url, {
          headers: { Authorization: `Bearer ${userData.private_token}`, },
        }).then(function (res) {
          if (res.data.error !== '0') {
            setRedirect({ ...redirect, status: true });
          } else {
            if (response.data.msg.person_type === 1) {
              setPersonNatural(res.data.msg);
            } else {
              setPersonLegal(res.data.msg);
            }
          }
        }).catch(function (error) { console.error(error); });
      }
    }).catch(function (error) {
      if (error.response.status === 401) {
        localStorage.removeItem('userData');
        window.location.replace(`${externalUrl.lenderUrl}/v1/auth/logout`);
      } else {
        console.error(error);
      }
    });
  }

  const create = () => {
    var error = false;
    if (lender.display_name === '') {
      setNameError(true);
      notify("br", "El nombre de despliegue es obligatorio.", "danger");
      error = true;
    }

    if (lender.nit === '') {
      setNitError(true);
      notify("br", "El nit es obligatorio.", "danger");
      error = true;
    }

    if (formType === 1) {
      if (personNatural.first_name === '') {
        setFirstNameError(true);
        notify("br", "El primer nombre es obligatorio.", "danger");
        error = true;
      }

      if (personNatural.first_surname === '') {
        setFirstSurnameError(true);
        notify("br", "El primer apellido es obligatorio.", "danger");
        error = true;
      }
    } else {
      if (personLegal.display_name === '') {
        setDisplayError(true);
        notify("br", "El nombre es obligatorio.", "danger");
        error = true;
      }
    }

    if (error) {
      return;
    }

    var body = {};
    var url = '';

    if (formType === 1) {
      url = `${externalUrl.lenderUrl}/v1/person-natural/add`;
      body = personNatural;
    } else {
      url = `${externalUrl.lenderUrl}/v1/person-legal/add`;
      body = personLegal;
    }

    axios.post(url, body, {
      headers: { Authorization: `Bearer ${userData.private_token}`, },
    }).then(function (response) {
      if (response.data.error === '0') {
        const lenderBody = { ...lender, person_type: formType, person_entity_ref: response.data.msg.id };
        axios.post(`${externalUrl.lenderUrl}/v1/lender/add`, lenderBody, {
          headers: { Authorization: `Bearer ${userData.private_token}`, },
        }).then(function (res) {
          if (res.data.error === '1') {
            setNitError(true);
            notify("br", res.data.msg, "danger");
          } else {
            notify("br", "Creado correctamente.", "success");
            setRedirect({ to: 'view-lender/' + res.data.msg.id, status: true });
          }

        }).catch(
          function (error) {
            console.error(error);
            notify("br", "No se ha podido crear.", "danger");
          }
        );
      } else {
        notify("br", "No se ha podido crear. Hubo un error en la base de datos.", "danger");
      }
    }).catch(
      function (error) {
        console.error(error);
        notify("br", "No se ha podido crear.", "danger");
      }
    );
  };

  const update = () => {
    var error = false;
    if (lender.display_name === '') {
      setNameError(true);
      notify("br", "El nombre de despliegue es obligatorio.", "danger");
      error = true;
    }

    if (lender.nit === '') {
      setNitError(true);
      notify("br", "El nit es obligatorio.", "danger");
      error = true;
    }

    if (formType === 1) {
      if (personNatural.first_name === '') {
        setFirstNameError(true);
        notify("br", "El primer nombre es obligatorio.", "danger");
        error = true;
      }

      if (personNatural.first_surname === '') {
        setFirstSurnameError(true);
        notify("br", "El primer apellido es obligatorio.", "danger");
        error = true;
      }
    } else {
      if (personLegal.display_name === '') {
        setDisplayError(true);
        notify("br", "El nombre es obligatorio.", "danger");
        error = true;
      }
    }

    if (error) {
      return;
    }

    var body = {};
    var url = '';

    if (formType === 1) {
      if (lender.person_type === 1) {
        url = `${externalUrl.lenderUrl}/v1/person-natural/modify`;
        body = personNatural;
      } else {
        url = `${externalUrl.lenderUrl}/v1/person-natural/add`;
        body = personNatural;
      }
    } else {
      if (lender.person_type === 1) {
        url = `${externalUrl.lenderUrl}/v1/person-legal/add`;
        body = personLegal;
      } else {
        url = `${externalUrl.lenderUrl}/v1/person-legal/modify`;
        body = personLegal;
      }
    }

    axios.post(url, body, {
      headers: { Authorization: `Bearer ${userData.private_token}`, },
    }).then(function (response) {
      if (response.data.error === '0') {
        const lenderBody = { ...lender, person_type: formType, person_entity_ref: response.data.msg.id };

        axios.post(`${externalUrl.lenderUrl}/v1/lender/modify`, lenderBody, {
          headers: { Authorization: `Bearer ${userData.private_token}`, },
        }).then(function (res) {
          notify("br", "Actualizado correctamente.", "success");
          setRedirect({ to: '/admin/view-lender/' + res.data.msg.id, status: true });
        }).catch(
          function (error) {
            notify("br", "No se ha podido actualizar.", "danger");
          }
        );
      } else {
        notify("br", "No se ha podido crear. Hubo un error en la base de datos.", "danger");
      }
    }).catch(
      function (error) {
        console.error(error);
        notify("br", "No se ha podido crear.", "danger");
      }
    );
  };

  const changeType = (e) => {
    if (props.location.update || id != null) {
      toggleChangeType(false, 'radio');
    } else {
      if (e.target.id === 'legal') {
        setFormType(1);
      } else if (e.target.id === 'natural') {
        setFormType(2);
      }
    }
  };

  const notify = (place, text, type) => {
    var options = {
      place: place,
      message: (
        <div>
          <div>
            {text}
          </div>
        </div>
      ),
      type: type,
      icon: "nc-icon nc-simple-remove",
      autoDismiss: 7,
    };
    notificationAlert.current.notificationAlert(options);
  };

  const toggle = () => {
    setModal(!modal);
  }

  const toggleChangeType = (res, type) => {
    if (res) {
      setFormType(formType === 1 ? 2 : 1);
    }

    if (type === 'radio') {
      if (pressedToggle) {
        setPressedToggle(false);
      } else {
        setPressedToggle(true);
        setModalType(!modalType);
      }
    }
    else {
      setModalType(!modalType);
    }
  }

  const changeValue = (e, setter, obj) => {
    setter({ ...obj, [e.target.name]: e.target.value });
  }

  const changeValueSelect = (e, setter, obj, name) => {
    setter({ ...obj, [name]: e.value });
  }

  return (
    <>
      <NotificationAlert ref={notificationAlert} />
      <div className="content">
        <Row>
          <Col md="12">
            <Card className="card-user">
              <CardHeader>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <CardTitle tag="h5">{props.location.update || id != null ? 'Actualizar' : 'Crear'} lender</CardTitle>
                  <div>
                    <Button
                      className="btn button-alapar-success"
                      color="primary"
                      type="submit"
                      onClick={props.location.update || id != null ? update : create}
                    >
                      {props.location.update || id != null ? 'Actualizar' : 'Crear'}
                    </Button>
                    <Link to={'/admin/lender'}>
                      <Button
                        className="button-alapar-cancel"
                        outline
                        color="primary"
                      >
                        Cancelar
                      </Button>
                    </Link>
                  </div>
                </div>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col className="col-4" lg="4" md="4" xl="3" >
                    <FormGroup>
                      <label>Tipo de lender *</label>
                      <Select
                        options={lenderType}
                        placeholder={lender.type === '' ? 'Seleccione...' :
                          lenderType.filter(option =>
                            option.value === lender.type)[0]?.label}
                        noOptionsMessage={({ inputValue }) => !inputValue ? "Cargando..." : "Sin resultados"}
                        name='type'
                        onChange={(e) => changeValueSelect(e, setLender, lender, 'type')}
                        className="create-select"
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="5" md="5" xl="4" className="col-8 nit-field" style={{ display: 'flex' }}>
                    <FormGroup>
                      <label>NIT *</label>
                      <Input
                        placeholder={lender.nit === '' ? "NIT" : lender.nit}
                        type="text"
                        name='nit'
                        onChange={(e) => { changeValue(e, setLender, lender); setNitError(false); }}
                        style={{ width: '7rem' }}
                        invalid={nitError}
                        autoComplete="off"
                      />
                    </FormGroup>
                    <FormGroup tag="fieldset" className="persona-form" style={{ marginTop: '-0.5rem', marginBottom: 0 }}>
                      <FormGroup check style={{ paddingTop: '1rem' }} >
                        <Label check style={{ padding: '0', userSelect: 'none' }} onClick={changeType} id='legal'>
                          <Input
                            style={{ cursor: 'pointer' }}
                            name="radio1"
                            type="radio"
                            checked={formType === 1}
                            id='legal'
                          />
                          Persona natural
                        </Label>
                      </FormGroup>
                      <FormGroup check style={{ marginTop: '-0.5rem' }}>
                        <Label check style={{ padding: '0', userSelect: 'none' }} onClick={changeType} id='natural'>
                          <Input
                            style={{ cursor: 'pointer' }}
                            name="radio1"
                            type="radio"
                            checked={formType === 2}
                            id='natural'
                          />
                          Persona legal
                        </Label>
                      </FormGroup>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col className="pr-10" md="6" xl="7">
                    <FormGroup>
                      <label>Nombre de despliegue *</label>
                      <Input
                        placeholder={lender.display_name === '' ? "Nombre de despliegue" : lender.display_name}
                        type="text"
                        name='display_name'
                        invalid={nameError}
                        onChange={(e) => { changeValue(e, setLender, lender); setNameError(false); }}
                        autoComplete="off"
                      />
                    </FormGroup>
                  </Col>
                  <Col className="pr-10" md="6" xl="5" style={{ display: 'flex' }}>
                    <FormGroup style={{ width: '8.6rem' }}>
                      <label>Moneda preferida</label>
                      <Select
                        options={currency}
                        placeholder={lender.preferred_currency === '' ? 'Seleccione...' :
                          currency.filter(option =>
                            option.value === lender.preferred_currency)[0]?.label}
                        noOptionsMessage={({ inputValue }) => !inputValue ? "Cargando..." : "Sin resultados"}
                        name='preferred_currency'
                        onChange={(e) => changeValueSelect(e, setLender, lender, 'preferred_currency')}
                        className="create-select"
                      />
                    </FormGroup>
                    <FormGroup style={{ width: 'calc(100% - 8.6rem)', paddingLeft: '2rem' }}>
                      <label>Nacionalidad</label>
                      <Select
                        options={countries}
                        placeholder={lender.nationality === '' ? 'Seleccione...' :
                          countries.filter(option =>
                            option.value === lender.nationality)[0]?.label}
                        noOptionsMessage={({ inputValue }) => !inputValue ? "Cargando..." : "Sin resultados"}
                        name='nationality'
                        onChange={(e) => { changeValueSelect(e, setLender, lender, 'nationality'); }}
                        className="create-select"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <FormGroup>
                      <label>Notas</label>
                      <Input
                        type="textarea"
                        defaultValue={lender.notes === '' ? "" : lender.notes}
                        name='notes'
                        onChange={(e) => { changeValue(e, setLender, lender); }}
                        autoComplete="off"
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            {
              formType === 1 ?
                <FormPersonNatural buttonText={props.location.update || id != null ? 'Actualizar' : 'Crear'} create={props.location.update || id != null ? update : create} setPersonNatural={setPersonNatural} personNatural={personNatural} changeValue={changeValue} changeValueSelect={changeValueSelect} firstNameError={firstNameError} setFirstNameError={setFirstNameError} firstSurnameError={firstSurnameError} setFirstSurnameError={setFirstSurnameError} />
                :
                <FormPersonLegal buttonText={props.location.update || id != null ? 'Actualizar' : 'Crear'} create={props.location.update || id != null ? update : create} setPersonLegal={setPersonLegal} personLegal={personLegal} changeValue={changeValue} changeValueSelect={changeValueSelect} displayError={displayError} setDisplayError={setDisplayError} />
            }
          </Col>
        </Row>
      </div>

      <Modal isOpen={modal}
        style={{ top: '5vh' }}>
        <ModalHeader style={{ userSelect: 'none' }}>
          No se puede {props.location.update || id != null ? 'actualizar' : 'crear'} el lender
        </ModalHeader>
        <ModalBody style={{ paddingBottom: '0.3rem' }}>
          <Label style={{ userSelect: 'none' }}>
            No hay ningún tipo de lender creado o todos están deshabilitados. Pulse el botón para ir a crear uno.
          </Label>
        </ModalBody>
        <ModalFooter style={{ paddingTop: '0.3rem', paddingBottom: '0.3rem' }}>
          <Link to={'/admin/create-lender-type'}>
            <Button
              className="button-alapar-success"
              color="primary">
              Ir a crear
            </Button>
          </Link>
        </ModalFooter>
      </Modal>

      <Modal isOpen={modalType}
        style={{ top: '5vh' }}>
        <ModalHeader style={{ justifyContent: 'center !important' }}>
          ¡Advertencia!
        </ModalHeader>
        <ModalBody style={{ paddingBottom: '0.3rem' }}>
          <Label>
            Si cambia el tipo de persona, se perderá la información que tenía antes al guardar. ¿Desea continuar?
          </Label>
        </ModalBody>
        <ModalFooter style={{ paddingTop: '0.3rem', paddingBottom: '0.3rem' }}>
          <Button
            className={"btn button-alapar-warning button-left"}
            onClick={() => toggleChangeType(true, 'normal')}
          >
            Acepto
          </Button>
          {' '}
          <Button
            className="button-alapar-cancel button-right"
            outline
            color="primary"
            onClick={() => toggleChangeType(false, 'normal')}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
      {redirect.status ? <Redirect to={{ pathname: redirect.to, state: { options: options } }} /> : null}
    </>
  );
}

export default FormLender;
