import React, { useContext } from "react";

import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Col,
    Nav,
    NavItem,
    Row,
} from "reactstrap";


import { useState } from "react";
import axios from "axios";
import externalUrl from "config/externalUrl";
import { CedulaLenderContext } from "components/Context/CedulaLenderContext";
import PerfectScrollbar from 'react-perfect-scrollbar'
import FormCedulaLender from "./CedulaLender";

function ModalCedulasLender() {
    const [id, cedulas, notify, setCedulas, cedulasModal, setCedulasModal, toggleCedulasModal] = useContext(CedulaLenderContext);

    const [toggle] = useState(false);
    const [active, setActive] = useState(-1);

    const [selectOptions, setSelectOptions] = useState([]);
    const [cedulaOptions, setCedulaOptions] = useState({ billing_type: 0, isr_retention: 0, alapar_service: 0, force_iva: 0 });

    const [selectedCedula, setSelectedCedula] = useState(-1);
    const [userData] = useState(JSON.parse(localStorage.getItem('userData')));

    /* if(cedulas === undefined) return;
    console.log(cedulas); */
    /* const onCheckboxBtnClick = (selected) => {
        const index = props.cSelected.indexOf(selected);
        if (index < 0) {
            props.cSelected.push(selected);
        } else {
            props.cSelected.splice(index, 1);
        }
        props.setCSelected([...props.cSelected]);
    };

    if (props.distribution.length == 0) {
        return null;
    } */

    const changeActive = (e) => {
        setActive(e);
    };

    const changeGlobalDistribution = () => {

    };

    const saveDistribution = () => {
        let cedula_ownership = '';
        if (active === -1) {
            if (cedulaOptions.billing_type === 0) {
                notify('br', 'Seleccione un tipo de facturación.', 'warning');
                return;
            }
            for (let index = 0; index < cedulas.length; index++) {
                const element = cedulas[index];
                cedula_ownership += element.cedula_ownership;
                if ((index + 1) !== cedulas.length) {
                    cedula_ownership += ',';
                }
            }
        } else {
            if (selectedCedula === -1) {

                notify('br', 'Primero selecciona una cédula.', 'warning');
                return;
            } else if (selectedCedula === -2) {
                if (cedulaOptions.billing_type === 0) {
                    notify('br', 'Seleccione un tipo de facturación.', 'warning');
                    return;
                }
                cedula_ownership = cedulas[active].cedula_ownership;
            } else {
                cedula_ownership = cedulas[active].cedula_ownership.split(',')[selectedCedula];
            }
        }

        const body = {
            cedula_ownership: cedula_ownership,
            billingType: cedulaOptions.billing_type,
            isr: cedulaOptions.isr_retention,
            alapar: cedulaOptions.alapar_service,
            fIva: cedulaOptions.force_iva,
            lender: id
        }

        axios.post(`${externalUrl.lenderUrl}/v1/cedula-ownership/save-distribution-by-lender`, body, {
            headers: { Authorization: `Bearer ${userData.private_token}`, },
        }).then(function (response) {
            /* console.log(response.data.cedulas); */
            notify("br", "Repartición actualizada correctamente.", "success");
            setCedulas(response.data.cedulas);
            /* setError({ 1: false, 2: false, 3: false, 4: false, 7: false, 'null': false });
            props.toggle();
            props.changeCedula(props.selectedCedula.code); */
        }).catch(function () {
        });
    };


    return (
        <>
            <Modal isOpen={cedulasModal}
                size={'lg'}
                style={{ top: '5vh' }}>
                <ModalHeader style={{ userSelect: 'none' }}>
                    Cédulas
                </ModalHeader>
                <ModalBody style={{ paddingBottom: '0.3rem' }}>
                    <Row>
                        <Col md={4} style={{ borderRight: '0.5px solid black', paddingRight: '0' }}>
                            <PerfectScrollbar>
                                <Nav vertical style={{ maxHeight: '60vh', overflowY: 'auto', flexWrap: 'nowrap', overflowX: 'hidden' }}>
                                    <NavItem style={{ borderBottom: '0.5px solid black', maxWidth: '95%' }} onClick={() => changeActive(-1)}>
                                        <Button className='btn' color={active === -1 ? '' : 'link'} style={{ textAlign: 'left', color: active === -1 ? 'white' : '#51cbce', width: '100%' }}>
                                            Aplicar a todos
                                        </Button>
                                    </NavItem>
                                    {
                                        cedulas.map((cedula, i) => (
                                            <NavItem key={i} onClick={() => changeActive(cedula.index_row)} style={{ maxWidth: '95%' }}>
                                                <Button className='btn' color={parseInt(active) === parseInt(cedula.index_row) ? '' : 'link'} style={{ textAlign: 'left', color: parseInt(active) === parseInt(cedula.index_row) ? 'white' : '#51cbce', width: '100%' }}>
                                                    {cedula.loan + ' - ' + cedula.loan_name}
                                                </Button>
                                            </NavItem>
                                        ))
                                    }
                                </Nav>
                            </PerfectScrollbar>
                        </Col>
                        <Col md={8}>
                            <FormCedulaLender
                                active={active}
                                selectOptions={selectOptions}
                                setSelectOptions={setSelectOptions}
                                cedulaOptions={cedulaOptions}
                                setCedulaOptions={setCedulaOptions}
                                selectedCedula={selectedCedula}
                                setSelectedCedula={setSelectedCedula}
                            />
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter style={{ paddingTop: '0.3rem', paddingBottom: '0.3rem' }}>
                    <Button
                        className={"btn button-alapar-success button-left"}
                        onClick={saveDistribution}
                    >
                        Guardar
                    </Button>
                    {' '}
                    <Button
                        className="button-alapar-cancel button-right"
                        outline
                        onClick={toggleCedulasModal}
                        color="primary">
                        Cancelar
                    </Button>
                </ModalFooter>
            </Modal>

            <Modal isOpen={toggle}
                style={{ top: '5vh' }}>
                <ModalHeader style={{ userSelect: 'none' }}>
                    ¿Quieres continuar?
                </ModalHeader>
                <ModalBody style={{ paddingBottom: '0.3rem' }}>
                    <Row>
                        <Col className="pr-10">
                            Si cambias el valor del porcentaje de distribución del lender general, se cambiaran también los porcentajes del detalle de distribución para este lender.
                            <br />
                            ¿Quieres continuar?
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter style={{ paddingTop: '0.3rem', paddingBottom: '0.3rem' }}>
                    <Button
                        className={"btn button-alapar-success button-left"}
                        onClick={changeGlobalDistribution}
                    >
                        Continuar
                    </Button>
                    {' '}
                    <Button
                        className="button-alapar-cancel button-right"
                        outline
                        onClick={() => (setCedulasModal(false))}
                        color="primary">
                        Cancelar
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
}

export default ModalCedulasLender;
