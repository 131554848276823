import React, { useEffect, useState } from "react";

import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  FormGroup,
  Input,
  Button,
} from "reactstrap";

import Select from 'react-select';
import countries from "data/countries";

import NotificationAlert from "react-notification-alert";
import externalUrl from "config/externalUrl";
import axios from "axios";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

function FormBank(props) {
  console.log(props.location.update);

  const notificationAlert = React.useRef();
  const [userData] = React.useState(JSON.parse(localStorage.getItem('userData')));

  const [name, setName] = useState('');
  const [nameError, setNameError] = useState(false);
  const [brandName, setBrandName] = useState('');
  const [description, setDescription] = useState('');
  const [address, setAddress] = useState('');
  const [phone, setPhone] = useState('');
  const [whatsapp, setWhatsapp] = useState('');
  const [swift, setSwift] = useState('');
  const [aba, setAba] = useState('');
  const [country, setCountry] = useState();

  const [redirect, setRedirect] = useState({ status: false, to: '/admin/view-banks' });

  let { id } = useParams();

  useEffect(() => {
    if (props.location.update || (id !== null && id !== undefined)) {
      fetchData();
    }
  }, []); // eslint-disable-line

  async function fetchData() {
    axios.get(`${externalUrl.lenderUrl}/v1/bank/search?id=${id}`, {
      headers: { Authorization: `Bearer ${userData.private_token}`, },
    }).then(function (response) {
      if (response.data.error !== '0') {
        setRedirect({ ...redirect, status: true });
      } else {
        setName(response.data.msg.name);
        setDescription(response.data.msg.description);
      }
    }).catch(
      function () {
      }
    );
  }

  const create = () => {
    if (name === '') {
      setNameError(true);
      notify("br", "El nombre es obligatorio.", "danger");
      return;
    }

    const body = {
      name: name,
      brandname: brandName,
      address: address,
      phone: phone,
      whatsapp: whatsapp,
      swift: swift,
      aba: aba,
      country: country,
      description: description,
      created_by: userData.display_name
    }

    axios.post(`${externalUrl.lenderUrl}/v1/lender-type/add`, body, {
      headers: { Authorization: `Bearer ${userData.private_token}`, },
    }).then(function (response) {
      notify("br", "Creado correctamente.", "success");
      setRedirect({ to: 'view-lender-type/' + response.data.msg.id, status: true });
    }).catch(
      function (error) {
        notify("br", "No se ha podido crear.", "danger");
      }
    );
  };

  const notify = (place, text, type) => {
    /* type = "primary";
    type = "success";
    type = "danger";
    type = "warning";
    type = "info"; */
    var options = {};
    options = {
      place: place,
      message: (
        <div>
          <div>
            {text}
          </div>
        </div>
      ),
      type: type,
      icon: "nc-icon nc-simple-remove",
      autoDismiss: 7,
    };
    notificationAlert.current.notificationAlert(options);
  };

  return (
    <>
      <NotificationAlert ref={notificationAlert} />
      <div className="content">
        <Row>
          <Col md="12">
            <Card className="card-user">
              <CardHeader>
                <CardTitle tag="h5">{props.location.update ? 'Actualizar' : 'Crear'} banco</CardTitle>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col className="pr-10" lg="3" md="3">
                    <FormGroup>
                      <label>Nombre *</label>
                      <Input
                        type="text"
                        placeholder={name === '' ? "Nombre" : name}
                        onChange={(e) => { setName(e.target.value); setNameError(false); }}
                        invalid={nameError}
                      />
                    </FormGroup>
                  </Col>
                  <Col className="pr-10" lg="3" md="3">
                    <FormGroup>
                      <label>Marca</label>
                      <Input
                        type="text"
                        placeholder={brandName === '' ? "Marca" : brandName}
                        onChange={(e) => setBrandName(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                  <Col className="pr-10" lg="3" md="3">
                    <FormGroup>
                      <label>Dirección</label>
                      <Input
                        placeholder={address === "" ? "Dirección" : address}
                        type="text"
                        onChange={(e) => setAddress(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                  <Col className="pr-10" lg="3" md="3">
                    <FormGroup>
                      <label>País</label>
                      <Select
                        options={countries}
                        placeholder="Seleccione..."
                        noOptionsMessage={({ inputValue }) => !inputValue ? "Cargando..." : "Sin resultados"}
                        onChange={(e) => setCountry(e.value)}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col className="pr-10" lg="3" md="3">
                    <FormGroup>
                      <label>Teléfono</label>
                      <Input
                        placeholder={phone === "" ? "Teléfono" : phone}
                        type="text"
                        onChange={(e) => setPhone(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                  <Col className="pr-10" lg="3" md="3">
                    <FormGroup>
                      <label>Whatsapp</label>
                      <Input
                        placeholder={whatsapp === "" ? "Whatsapp" : whatsapp}
                        type="text"
                        onChange={(e) => setWhatsapp(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                  <Col className="pr-10" lg="3" md="3">
                    <FormGroup>
                      <label>Swift</label>
                      <Input
                        placeholder={swift === "" ? "SWIFT" : swift}
                        type="text"
                        onChange={(e) => setSwift(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                  <Col className="pr-10" lg="3" md="3">
                    <FormGroup>
                      <label>ABA</label>
                      <Input
                        placeholder={aba === "" ? "ABA" : aba}
                        type="text"
                        onChange={(e) => setAba(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <FormGroup>
                      <label>Descripción</label>
                      <Input
                        type="textarea"
                        onChange={(e) => setDescription(e.target.value)}
                        defaultValue={description}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <div className="update ml-auto mr-auto">
                    <Button
                                                className="btn button-alapar-success"
                                                color="primary"
                      type="submit"
                      onClick={create}
                    >
                      Crear
                    </Button>
                  </div>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default FormBank;
