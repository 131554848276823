import React, { useEffect, useState } from "react";

import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  FormGroup,
  Button,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Label,
  Input,
} from "reactstrap";
import NotificationAlert from "react-notification-alert";

import { Redirect, useParams } from "react-router-dom";

import ViewPersonLegal from "./PersonLegal";
import ViewPersonNatural from "./PersonNatural";
import externalUrl from "config/externalUrl";
import countries from "data/countries";
import currency from "data/currency";

import axios from "axios";
import fileDownload from 'js-file-download';
import Skeleton from 'react-loading-skeleton';
import ModalCedulasLender from "./ModalCedulasLender";

import { CedulaLenderContext } from "components/Context/CedulaLenderContext";
/* import ModalCreateUser from "./Modal/CreateUser"; */

const styleTitle = {
  fontSize: '1rem',
  margin: 0
};

const styleLabel = {
  fontSize: '0.8rem'
}

function ViewLender(props) {
  const notificationAlert = React.useRef();
  let { id } = useParams();

  const [modal, setModal] = useState(false);
  const [modal2, setModal2] = useState(false);

  const toggle = () => setModal(!modal);
  const toggle2 = () => setModal2(!modal2);

  /* const [modalCreateUser, setModalCreateUser] = useState(false);
  const toggleCreateUser = () => setModalCreateUser(prevState => !prevState); */

  const [redirect, setRedirect] = useState({ status: false, to: '/admin/lender' });
  const [userData] = useState(JSON.parse(localStorage.getItem('userData')));

  const [lender, setLender] = useState({ type: ' ', code: ' ', nit: ' ', display_name: ' ', notes: ' ', person_type: 1, person_entity_ref: 0, created_by: ' ', nationality: ' ', preferred_currency: ' ', created_on: ' ', last_update: ' ' });
  const [lenderType, setLenderType] = useState({ name: ' ' });
  const [personLegal, setPersonLegal] = useState({ code: '', display_name: ' ', nationality: ' ', main_country: ' ', status: 1 });
  const [personNatural, setPersonNatural] = useState({ first_name: ' ', second_name: ' ', first_surname: '', second_surname: ' ', family_lastname: ' ', birthday: ' ', nationality: ' ', country_residence: ' ' });

  const [startDate, setStartDate] = useState('');
  const [finishDate, setFinishDate] = useState(subtractHours(new Date(Date.now()), 6).toISOString().split('T')[0]);

  const [loading, setLoading] = useState(false);

  /* CedulaContext */
  const [cedulas, setCedulas] = useState([]);

  const [cedulasModal, setCedulasModal] = useState(false);
  const toggleCedulasModal = () => setCedulasModal(prevState => !prevState);

  useEffect(() => {
    if (id !== null) {
      fetchData();
    }

    /* if (state !== undefined) {
      if (state.options.hasOwnProperty('text')) {
        if (sessionStorage.getItem('show') !== null) {
          notify(state.options.place, state.options.text, state.options.type);
          sessionStorage.removeItem('show');
        }
      };
    } */
  }, []); // eslint-disable-line

  async function fetchData() {
    if (!loading) { setLoading(true); }

    axios.get(`${externalUrl.lenderUrl}/v1/lender/search?id=${id}`, {
      headers: { Authorization: `Bearer ${userData.private_token}`, },
    }).then(function (response) {
      if (response.data.error !== '0') {
        setRedirect({ ...redirect, status: true });
      } else {
        setLender(response.data.msg);
        setLenderType(response.data.type);
        setCedulas(response.data.cedulas);
        var url = '';
        if (response.data.msg.person_type === 1) {
          url = `${externalUrl.lenderUrl}/v1/person-natural/search?id=${response.data.msg.person_entity_ref}`;
        } else {
          url = `${externalUrl.lenderUrl}/v1/person-legal/search?id=${response.data.msg.person_entity_ref}`;
        }

        axios.get(url, {
          headers: { Authorization: `Bearer ${userData.private_token}`, },
        }).then(function (res) {
          if (res.data.error !== '0') {
            setRedirect({ ...redirect, status: true });
          } else {
            if (response.data.msg.person_type === 1) {
              setPersonNatural(res.data.msg);
            } else {
              setPersonLegal(res.data.msg);
            }
          }
        })
          .catch(function (error) { console.error(error) })
          .finally(function () { setLoading(false); });
      }
    }).catch(
      function () {
        localStorage.removeItem('userData');
        window.location.replace(`${externalUrl.lenderUrl}/v1/auth/logout`);
      }
    );
  }

  const deleteLender = () => {
    var to = '';
    if (lender.status === 1) {
      to = 'disable';
    } else if (lender.status === 2) {
      to = 'drop';
    } else {
      notify("br", "Este lender ya se encuentra eliminado.", "info");
      return;
    }

    axios.get(`${externalUrl.lenderUrl}/v1/lender/${to}?id=${lender.id}`, {
      headers: { Authorization: `Bearer ${userData.private_token}`, },
    }).then(function (response) {
      if (response.data.error === '1') {
        notify("br", response.data.msg, "danger");
        toggle(null);
      } else {
        notify("br", `El lender se ${lender.status === 1 ? ' deshabilitó' : '  eliminó'} correctamente.`, "info");
        fetchData();
        toggle(null);
      }
    }).catch(
      function (error) {
        // Arreglar este mensaje 
        notify("br", "Ocurrio un error en la base de datos", "danger");
        toggle(null);
      }
    );
  };

  const notify = (place, text, type) => {
    var icon = '';
    if (type === 'success') {
      icon = 'nc-check-2';
    } else if (type === 'info') {
      icon = 'nc-alert-circle-i';
    } else if (type === 'warning') {
      icon = 'nc-bell-55';
    } else if (type === 'danger') {
      icon = 'nc-simple-remove';
    }

    var options = {
      place: place,
      message: (
        <div>
          <div>
            {text}
          </div>
        </div>
      ),
      type: type,
      icon: `nc-icon ${icon}`,
      autoDismiss: 7,
    };
    notificationAlert.current.notificationAlert(options);
  };

  function subtractHours(date, hours) {
    date.setHours(date.getHours() - hours);

    return date;
  }

  const getCartera = () => {
    const body = {
      id: id,
      startDate: startDate,
      finishDate: finishDate
    }

    axios.post(`${externalUrl.reportsUrl}/v1/external/generate-repartition-document-lender`,
      body,
      {
        responseType: 'blob',
        headers: { Authorization: `Bearer ${userData.private_token}`, },
      }).then(function (response) {
        console.log(response.data);
        response.data.text().then(
          function (res) {
            const resp = JSON.parse(res);
            if (resp.error) {
              notify('br', resp.msg, 'danger');
            }
          }
        ).catch(function (e) {
          fileDownload(response.data, `Cartera_Lender_${lender.display_name}.xlsx`);
        });
      }).catch(function () { });
  }

  return (
    <>
      <NotificationAlert ref={notificationAlert} />
      <div className="content">
        <Row>
          <Col md="12">
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Card className="card-user" style={{ width: '80%', minWidth: '340px' }}>
                <CardHeader>
                  <CardTitle tag="h5">
                    {loading ?
                      <div >
                        <Skeleton count={1} width='40%' inline='false' style={{ marginRight: '1rem' }} />
                        <Skeleton count={1} width='20%' inline='false' style={{ marginRight: '0.8rem', right: 0, position: 'absolute' }} />
                      </div>
                      :
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        Lender no. {id}
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        {lender.code === '' ? null : 'Código: ' + lender.code}
                        <div>{lender.status === 1 ? '(Habilitado)' : lender.status === 2 ? '(Deshabilitado)' : <div style={{ color: 'red' }}>Eliminado</div>}</div>
                      </div>
                    }
                  </CardTitle>
                </CardHeader>
                <CardBody style={{ minHeight: 0, paddingLeft: '2rem' }}>
                  <Row>
                    <Col md="3">
                      <FormGroup>
                        <p style={styleTitle}>Tipo de lender</p>
                        {loading ? <Skeleton count={1} /> : <label style={styleLabel}>{lenderType.name}</label>}
                      </FormGroup>
                    </Col>
                    <Col md="3">
                      <FormGroup>
                        <p style={styleTitle}>Nombre de despliegue</p>
                        {loading ? <Skeleton count={1} /> : <label style={styleLabel}>{lender.display_name}</label>}
                      </FormGroup>
                    </Col>
                    <Col md="3">
                      <FormGroup>
                        <p style={styleTitle}>Moneda preferida</p>
                        {loading ? <Skeleton count={1} /> : <label style={styleLabel}>{currency.filter(option =>
                          option.value === lender.preferred_currency)[0]?.label}</label>}
                      </FormGroup>
                    </Col>
                    <Col md="3">
                      <FormGroup>
                        <p style={styleTitle}>Nacionalidad</p>
                        {loading ? <Skeleton count={1} /> : <label style={styleLabel}>
                          {countries.filter(option =>
                            option.value === lender.nationality)[0]?.label}</label>}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="3">
                      <FormGroup>
                        <p style={styleTitle}>NIT</p>
                        {loading ? <Skeleton count={1} /> : <label style={styleLabel}>{lender.nit}</label>}

                      </FormGroup>
                    </Col>
                    <Col md="3">
                      <FormGroup>
                        <p style={styleTitle}>Creado el</p>
                        {loading ? <Skeleton count={1} /> : <label style={styleLabel}>{lender.created_on}</label>}
                      </FormGroup>
                    </Col>
                    <Col md="3">
                      <FormGroup>
                        <p style={styleTitle}>Última actualización</p>
                        {loading ? <Skeleton count={1} /> : <label style={styleLabel}>{lender.last_update}</label>}
                      </FormGroup>
                    </Col>
                    <Col md="3">
                      <FormGroup>
                        <p style={styleTitle}>Creado por</p>
                        {loading ? <Skeleton count={1} /> : <label style={styleLabel}>{lender.created_by}</label>}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <p style={styleTitle}>Notas</p>
                        {loading ? <Skeleton count={1} /> : <label style={styleLabel}>{lender.notes}</label>}
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card className="card-user" style={{ marginLeft: '1rem', height: 'fit-content' }}>
                <CardHeader>
                  <CardTitle tag="h5">Acciones</CardTitle>
                </CardHeader>
                <CardBody style={{ minHeight: 0 }} className="mx-auto">
                  <center>
                    <div>
                      <Button
                        className="btn button-alapar-success"
                        color="primary"
                        type="submit"
                        onClick={toggle2}
                      >
                        Cartera Lender
                      </Button>
                      <br />
                      {'  '}
                      {/* <Link to={{ pathname: '/admin/update-lender/' + id, update: true }}>
                        <Button
                          className="btn button-alapar-success"
                          color="primary"
                          type="submit"
                        >
                          Actualizar
                        </Button>
                      </Link> */}
                      <Button
                        className="btn button-alapar-success"
                        color="primary"
                        type="submit"
                        onClick={() => setCedulasModal(true)}
                      >
                        Ver cédulas actuales
                      </Button>
                      {'  '}
                      {/* <Button
                        className="btn button-alapar-success"
                        color="primary"
                        type="submit"
                        onClick={toggleCreateUser}
                      >
                        Agregar usuario
                      </Button>
                      <br />
                      {'  '} */}
                      <Button
                        className="btn button-alapar-danger"
                        color="danger"
                        type="submit"
                        onClick={toggle}
                      >
                        Eliminar
                      </Button>
                    </div>
                  </center>
                </CardBody>
              </Card>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            {
              loading ? <></> :
                lender.person_type === 1 ?
                  <ViewPersonNatural personNatural={personNatural} loading={loading} />
                  :
                  <ViewPersonLegal personLegal={personLegal} loading={loading} />
            }
          </Col>
        </Row>
      </div>
      <Modal isOpen={modal}
        toggle={toggle}
        style={{ top: '5vh' }}>
        <ModalHeader toggle={toggle}>
          {lender.status === 1 ? 'Deshabilitar' : 'Eliminar'} lender
        </ModalHeader>
        <ModalBody style={{ paddingBottom: '0.3rem' }}>
          <Label>
            ¿Desea eliminar el lender con nombre <b>{lender.display_name}</b> y NIT <b>{lender.nit}</b>?
          </Label>
        </ModalBody>
        <ModalFooter style={{ paddingTop: '0.3rem', paddingBottom: '0.3rem' }}>
          <Button
            className={"btn " + (lender.status === 1 ? 'button-alapar-warning' : 'button-alapar-danger') + " button-left"}
            onClick={deleteLender}
          >
            {lender.status === 1 ? 'Deshabilitar' : 'Eliminar'}
          </Button>
          {' '}
          <Button
            className="button-alapar-cancel button-right"
            outline
            color="primary"
            onClick={toggle}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={modal2}
        toggle={toggle2}
        style={{ top: '5vh' }}>
        <ModalHeader toggle={toggle2}>
          Cartera de lender
        </ModalHeader>
        <ModalBody style={{ paddingBottom: '0.3rem' }}>
          <Label>
            Ingrese el rango de fechas para obtener los pagos al lender:
          </Label>
          <div style={{ paddingLeft: '1rem', paddingBottom: '0.5rem' }}>
            <div className="d-flex">
              <div style={{ width: '30%' }}>
                <Label>
                  Fecha de inicio:
                </Label>
              </div>
              <div style={{ width: '40%' }}>
                <Input
                  max={subtractHours(new Date(Date.now()), 6).toISOString().split('T')[0]}
                  type="date"
                  name='start_date'
                  id='start_date'
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </div>
            </div>
            <br />
            <div className="d-flex">
              <div style={{ width: '30%' }}>
                <Label>
                  Fecha de fin:
                </Label>
              </div>
              <div style={{ width: '40%' }}>
                <Input
                  defaultValue={subtractHours(new Date(Date.now()), 6).toISOString().split('T')[0]}
                  max={subtractHours(new Date(Date.now()), 6).toISOString().split('T')[0]}
                  type="date"
                  name='finish_date'
                  id='finish_date'
                  onChange={(e) => setFinishDate(e.target.value)}
                />
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter style={{ paddingTop: '0.3rem', paddingBottom: '0.3rem' }}>
          <Button
            className="btn button-alapar-success"
            onClick={getCartera}
          >
            Obtener cartera
          </Button>
          {' '}
          <Button
            className="button-alapar-cancel button-right"
            outline
            color="primary"
            onClick={toggle2}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
      <CedulaLenderContext.Provider value={[id, cedulas, notify, setCedulas, cedulasModal, setCedulasModal, toggleCedulasModal]}>
        <ModalCedulasLender />
      </CedulaLenderContext.Provider>

      {/* <ModalCreateUser id={id} modal={modalCreateUser} toggle={toggleCreateUser} notify={notify} /> */}
      {redirect.status ? <Redirect to={redirect.to} /> : null}
    </>
  );
}

export default ViewLender;
