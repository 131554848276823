/*!

=========================================================
* Paper Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect } from "react";
import { Link } from "react-router-dom";

// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Table,
    Row,
    Col,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from "reactstrap";

import { BsFillTrashFill } from "react-icons/bs";
import { BsFillPencilFill } from "react-icons/bs";
import externalUrl from "config/externalUrl";
import axios from "axios";

function BankList() {
    const [userData] = React.useState(JSON.parse(localStorage.getItem('userData')));
    const [modal, setModal] = React.useState(false);
    const [banks, setBanks] = React.useState([]);
    const [filterData, setFilterData] = React.useState([]);
    const toggle = () => setModal(!modal);

    useEffect(() => {
        fetchData();
    }, []); // eslint-disable-line

    async function fetchData() {
        axios.get(`${externalUrl.lenderUrl}/v1/bank/index`, {
            headers: { Authorization: `Bearer ${userData.private_token}`, },
        }).then(function (response) {
            if (response.data.error === '1') {
                setBanks([]);
            } else {
                setBanks(response.data.msg);
                setFilterData(response.data.msg);
            }
        }).catch(function (error) {
            if (error.response.status === 401) {
                localStorage.removeItem('userData');
                window.location.replace(`${externalUrl.lenderUrl}/v1/auth/logout`);
            } else {
                console.error(error);
            }
        });
    };


    return (
        <>
            <div className="content">
                <Row>
                    <Col md="12">
                        <Card>
                            <CardHeader>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <CardTitle tag="h4">Bancos</CardTitle>
                                    <div >
                                        <Link to={'/admin/bank/create'}>
                                            <Button
                                                className="btn button-alapar-success"
                                                color="primary"
                                                type="submit"
                                            >
                                                Crear
                                            </Button>
                                        </Link>
                                    </div>
                                </div>
                            </CardHeader>
                            <CardBody>

                                <Table >
                                    <thead className="text-primary">
                                        <tr>
                                            <th style={{ width: '2%' }}>ID</th>
                                            <th>Nombre</th>
                                            <th>Teléfono</th>
                                            <th>Whatsapp</th>
                                            <th>Estado</th>
                                            <th style={{ width: '10%' }}>Acciones</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            filterData.length === 0 ? <tr>
                                                <td colSpan={6}><center>No hay ningún banco creado por el momento.</center></td>
                                            </tr> :

                                                filterData.map((bank, index) => (
                                                    <tr key={index}>
                                                        <td>1</td>
                                                        <td>
                                                            <Link>
                                                                {bank?.name}
                                                            </Link>
                                                        </td>
                                                        <td>{bank?.phone}</td>
                                                        <td>{bank?.whatsapp}</td>
                                                        <td>{bank?.status === 1 ? 'Activo' : 'Deshabilitado'}</td>
                                                        <td className="text-center">
                                                            <Link to={{ pathname: '/admin/update-bank/' + bank.id, update: true }}><Button className='btn' color='link' style={{ color: '#51cbce' }}><BsFillPencilFill /></Button></Link>
                                                            <Button className='btn' color='link' onClick={toggle} style={{ color: '#51cbce' }}><BsFillTrashFill /></Button>
                                                        </td>
                                                    </tr>
                                                ))
                                        }
                                    </tbody>
                                </Table>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
            <div className="content">
                <Row>
                    <Col md="12">
                        <div style={{
                            display: 'block', width: 700, padding: 30
                        }}>
                            <Modal isOpen={modal}
                                toggle={toggle}>
                                <ModalHeader toggle={function noRefCheck() { }}>
                                    Eliminar banco
                                </ModalHeader>
                                <ModalBody>
                                    ¿Desea eliminar el banco con nombre <b>Banco industrial</b>?
                                </ModalBody>
                                <ModalFooter>
                                    <Button
                                        color="danger"
                                        onClick={function noRefCheck() { }}
                                    >
                                        Eliminar
                                    </Button>
                                    {' '}
                                    <Button onClick={function noRefCheck() { }}>
                                        Cancelar
                                    </Button>
                                </ModalFooter>
                            </Modal>
                        </div >
                    </Col>
                </Row>
            </div>
        </>
    );
}



export default BankList;
