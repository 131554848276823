import {
    Col,
    Input,
    Label,
    Row,
} from "reactstrap";

function CreateUser(props) {
    const { data, setData } = props;

    const changeValue = (key, data) => {
        setData(prevData => ({ ...prevData, [key]: data }));
    }

    return (
        <Col>
            <Row>
                <Col md={2} />
                <Col md={8}>
                    <Label>
                        Nombre de despliegue:
                    </Label>
                    <Input
                        defaultValue={data?.display_name}
                        onChange={(e) => changeValue('display_name', e.target.value)} />
                </Col>
            </Row>
            <Row>
                <Col md={2} />
                <Col md={8}>
                    <Label>
                        Nombres:
                    </Label>
                    <Input
                        defaultValue={data?.name}
                        onChange={(e) => changeValue('name', e.target.value)} />
                </Col>
            </Row>
            <Row>
                <Col md={2} />
                <Col md={8}>
                    <Label>
                        Apellidos:
                    </Label>
                    <Input
                        defaultValue={data?.last_name}
                        onChange={(e) => changeValue('last_name', e.target.value)} />
                </Col>
            </Row>
            <Row>
                <Col md={2} />
                <Col md={8}>
                    <Label>
                        Correo electrónico:
                    </Label>
                    <Input
                        defaultValue={data?.email_address}
                        onChange={(e) => changeValue('email_address', e.target.value)} />
                </Col>
            </Row>
        </Col>
    );
}

export default CreateUser;
