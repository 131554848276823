import React from "react";

import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  FormGroup,
} from "reactstrap";

import Select from 'react-select';

function ViewCedula(props) {

  const setCedula = (e) => {
    props.setCedula(props.selectedCedula.hasOwnProperty('code'), e.value);
  }

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card className="card-user">
              <CardHeader>
                <CardTitle tag="h5">Datos cédula</CardTitle>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col className="pr-10" md="1" />
                  <Col className="pr-10" md="10">
                    <FormGroup>
                      <label>Cédula</label>
                      <Select
                        options={props.cedulas}
                        placeholder={'Seleccione...'}
                        value={!props.selectedCedula.hasOwnProperty('code') ? '' : { value: props.selectedCedula.code, label: 'Cédula # ' + props.selectedCedula.position }}
                        noOptionsMessage={({ inputValue }) => !inputValue ? "Cargando..." : "Sin resultados"}
                        onChange={(e) => setCedula(e)}
                        className="create-select"
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col className="pr-10" md="1" />
                  <Col className="pr-10" md="10">
                    <Row>
                      <Col className="col-6" md="6">
                        <p>Código: </p>
                      </Col>
                      <Col className="col-6" md="6">
                        <p className="text-right">{props.selectedCedula?.code}</p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col className="pr-10" md="1" />
                  <Col className="pr-10" md="10">
                    <Row>
                      <Col className="col-6" md="6">
                        <p>Monto emitido: </p>
                      </Col>
                      <Col className="col-6" md="6">
                        <p className="text-right">{!props.selectedCedula.hasOwnProperty('auth_amnt') ? null : props.loan?.currency === 'USD' ? '$ ' : 'Q '}{props.selectedCedula?.auth_amnt}</p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col className="pr-10" md="1" />
                  <Col className="pr-10" md="10">
                    <Row>
                      <Col className="col-6" md="6">
                        <p>Desembolso: </p>
                      </Col>
                      <Col className="col-6" md="6">
                        <p className="text-right">{props.selectedCedula?.valid_since?.split(' ')[0]}</p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                {
                  (!props.selectedCedula.hasOwnProperty('code') && props.selectedCedula?.canceled_on === '') || props.selectedCedula?.canceled_on === null || props.selectedCedula?.canceled_on === undefined ? null :
                    <>
                      <Row>
                        <Col className="pr-10" md="1" />
                        <Col className="pr-10" md="10">
                          <Row>
                            <Col className="col-6" md="6">
                              <p>Cancelado el:</p>
                            </Col>
                            <Col className="col-6" md="6">
                              <p className="text-right">{props.selectedCedula?.canceled_on?.split(' ')[0]}</p>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="pr-10" md="1" />
                        <Col className="pr-10" md="10">
                          <Row>
                            <Col className="col-6" md="6">
                              <p>Cancelado por: </p>
                            </Col>
                            <Col className="col-6" md="6">
                              <p className="text-right">{props.selectedCedula?.canceled_by}</p>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </>
                }
                <Row>
                  <Col className="pr-10" md="1" />
                  <Col className="pr-10" md="10">
                    <Row>
                      <Col className="col-8" md="8">
                        <p>No. cédulas emitidas: </p>
                      </Col>
                      <Col className="col-4" md="4">
                        <p className="text-right">{props.cedulasData?.length/* props.loan?.numero_cedulas_autorizadas */}</p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default ViewCedula;