import React, { useEffect, useState } from "react";

import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  FormGroup,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  Label,
  ModalHeader,
} from "reactstrap";


import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import axios from "axios";
import externalUrl from "config/externalUrl";
import { Redirect } from "react-router-dom";
import NotificationAlert from "react-notification-alert";

const styleTitle = {
  fontSize: '1.1rem',
  margin: 0
};

const styleLabel = {
  fontSize: '0.9rem'
}

function ViewLenderType(props) {
  const notificationAlert = React.useRef();
  const [state] = useState(props.location.state);
  let { id } = useParams();

  const [modal, setModal] = useState(false);
  const [userData] = useState(JSON.parse(localStorage.getItem('userData')));
  const [lenderType, setLenderType] = useState({ id: 0, name: 'Nombre', description: 'Descripción', created_by: 'Sistema', created_on: '2022-06-08 00:00:00', last_update: '2022-06-08 00:00:00', status: 3 });

  const [redirect, setRedirect] = useState({ status: false, to: '/admin/lenders-type' });

  useEffect(() => {
    if (id !== null) {
      fetchData();
    }

    if (state !== undefined) {
      if (state.options.hasOwnProperty('text')) {
        if (sessionStorage.getItem('show') !== null) {
          notify(state.options.place, state.options.text, state.options.type);
          sessionStorage.removeItem('show');
        }
      };
    }
  }, []); // eslint-disable-line

  async function fetchData() {
    axios.get(`${externalUrl.lenderUrl}/v1/lender-type/search?id=${id}`, {
      headers: { Authorization: `Bearer ${userData.private_token}`, },
    }).then(function (response) {
      console.log(response.data)
      if (response.data.error !== '0') {
        setRedirect({ ...redirect, status: true });
      } else {
        setLenderType(response.data.msg);
      }
    }).catch(
      function () {
        localStorage.removeItem('userData');
        window.location.replace(`${externalUrl.lenderUrl}/v1/auth/logout`);
      }
    );
  };

  const toggle = (e) => {
    if (e?.status === 3) {
      notify("br", 'Este tipo de lender ya se encuentra eliminado.', "info");
    } else {
      setModal(!modal);
    }
  }

  const deleteLender = () => {
    var to = '';
    if (lenderType.status === 1) {
      to = 'disable';
    } else if (lenderType.status === 2) {
      to = 'drop';
    } else {
      notify("br", "Este tipo de lender ya se encuentra eliminado.", "info");
      return;
    }

    axios.get(`${externalUrl.lenderUrl}/v1/lender-type/${to}?id=${lenderType.id}`, {
      headers: { Authorization: `Bearer ${userData.private_token}`, },
    }).then(function (response) {
      if (response.data.error === '1') {
        notify("br", response.data.msg, "danger");
        toggle(null);
      } else {
        notify("br", `El tipo de lender se ${lenderType.status === 1 ? ' deshabilitó' : '  eliminó'} correctamente.`, "info");
        fetchData();
        toggle(null);
      }
    }).catch(
      function (error) {
        // Arreglar este mensaje 
        notify("br", "Ocurrio un error en la base de datos", "danger");
        toggle(null);
      }
    );
  };

  const notify = (place, text, type) => {
    var options = {
      place: place,
      message: (
        <div>
          <div>
            {text}
          </div>
        </div>
      ),
      type: type,
      icon: "nc-icon nc-simple-remove",
      autoDismiss: 7,
    };
    notificationAlert.current.notificationAlert(options);
  };

  return (
    <>
      <NotificationAlert ref={notificationAlert} />
      <div className="content">
        <Row>
          <Col md="12">
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Card className="card-user" style={{ width: '60%', minWidth: '305px' }}>
                <CardHeader>
                  <CardTitle tag="h5">
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      Tipo de lender no. {id}
                      <div>{lenderType.status === 1 ? '(Habilitado)' : lenderType.status === 2 ? '(Deshabilitado)' : <div style={{ color: 'red' }}>Eliminado</div>}</div>
                    </div>
                  </CardTitle>
                </CardHeader>
                <CardBody style={{ minHeight: 0, paddingLeft: '2rem' }}>
                  <Row>
                    <Col md="4">
                      <FormGroup>
                        <p style={styleTitle}>Nombre</p>
                        <label style={styleLabel}>{lenderType.name}</label>
                      </FormGroup>
                    </Col>
                    <Col md="8">
                      <FormGroup>
                        <p style={styleTitle}>Descripción</p>
                        <label style={styleLabel}>{lenderType.description}</label>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="4">
                      <FormGroup>
                        <p style={styleTitle}>Creado el</p>
                        <label style={styleLabel}>{lenderType.created_on}</label>
                      </FormGroup>
                    </Col>
                    <Col md="5">
                      <FormGroup>
                        <p style={styleTitle}>Última actualización</p>
                        <label style={styleLabel}>{lenderType.last_update}</label>
                      </FormGroup>
                    </Col>
                    <Col md="3">
                      <FormGroup>
                        <p style={styleTitle}>Creado por</p>
                        <label style={styleLabel}>{lenderType.created_by}</label>
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card className="card-user" style={{ marginLeft: '1rem', height: 'fit-content' }}>
                <CardHeader style={{ marginBottom: '-1rem' }}>
                  <CardTitle tag="h5">Acciones</CardTitle>
                </CardHeader>
                <CardBody style={{ minHeight: 0, }} className="mx-auto">
                  <center>
                    <div>
                      <Link to={{ pathname: '/admin/update-lender-type/' + id, update: true }}>
                        <Button
                          className="btn button-alapar-success"
                          color="primary"
                          type="submit"
                        >
                          Actualizar
                        </Button>
                      </Link>
                      {'  '}
                      <Button
                        className="btn button-alapar-danger"
                        type="submit"
                        onClick={toggle}
                      >
                        Eliminar
                      </Button>
                    </div>
                  </center>
                </CardBody>
              </Card>
            </div>
          </Col>
        </Row>
      </div>
      <Modal isOpen={modal}
        toggle={() => toggle(null)}
        style={{ top: '5vh' }}>
        <ModalHeader toggle={() => toggle(null)}>
          {lenderType.status === 1 ? 'Deshabilitar' : 'Eliminar'} tipo de lender
        </ModalHeader>
        <ModalBody style={{ paddingBottom: '0.3rem' }}>
          <Label>
            ¿Desea eliminar el tipo de lender con nombre <b>{lenderType.name}</b>?
          </Label>
        </ModalBody>
        <ModalFooter style={{ paddingTop: '0.3rem', paddingBottom: '0.3rem' }}>
          <Button
            className={"btn " + (lenderType.status === 1 ? 'button-alapar-warning' : 'button-alapar-danger') + " button-left"}
            onClick={deleteLender}
          >
            {lenderType.status === 1 ? 'Deshabilitar' : 'Eliminar'}
          </Button>
          {' '}
          <Button
            className="button-alapar-cancel button-right"
            outline
            color="primary"
            onClick={() => toggle(null)}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
      {redirect.status ? <Redirect to={redirect.to} /> : null}
    </>
  );
}

export default ViewLenderType;
