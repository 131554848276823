import { useState, useEffect } from "react";

// react-router components
import { Route, Switch, Redirect } from "react-router-dom";

import axios from 'axios';
import externalUrl from './config/externalUrl';
import './style/App.css';

import AdminLayout from "layouts/Admin.js";

export default function App() {
    const [user, setUser] = useState({});
    try {
        useEffect(() => {
            fetchData();
        }, []); // eslint-disable-line

        async function fetchData() {
            try {
                const search = window.location.search;
                let token = { private_token: '' };
                const params = new URLSearchParams(search);
                const tokenParam = params.get('token');

                if (tokenParam) {
                    token.private_token = tokenParam;
                }

                let userLocal = {};
                if (token.private_token === '') {
                    userLocal = localStorage.getItem('userData')
                        ? JSON.parse(localStorage.getItem('userData'))
                        : null;

                    if (null != userLocal) {
                        if (userLocal.private_token) {
                            token = userLocal;
                            if (!userLocal.permissions?.LND00001) {
                                localStorage.removeItem('userData');
                                window.location.replace(`https://${window.location.host}/user/dashboard`);
                                return;
                            }
                        } else {
                            localStorage.removeItem('userData');
                            localStorage.removeItem('userInfo');
                            userLocal = null;
                            window.location.replace(`${externalUrl.lenderUrl}/v1/auth/logout?id=11`);
                            return;
                        }
                    } else {
                        var match = document.cookie.match(new RegExp('(^| )' + externalUrl.cookies + '=([^;]+)'));
                        if (match) {
                            token.private_token = match[2];
                        } else {
                            userLocal = null;
                            window.location.replace(`${externalUrl.lenderUrl}/v1/auth/logout?id=11`);
                            localStorage.removeItem('userInfo');
                            return;
                        }
                    }
                }

                const body = { app: 8 };
                axios.post(`${externalUrl.lenderUrl}/v1/api/session`, body, {
                    headers: { Authorization: `Bearer ${token.private_token}`, },
                }).then(function (response) {
                    if (!response.data.error) {
                        const session_data = JSON.stringify(response.data.session_data);
                        if (response.data.noPerm) {
                            localStorage.removeItem('userData');
                            localStorage.removeItem('userInfo');
                            window.location.replace(`https://${window.location.host}/user/dashboard`);
                            return;
                        }
                        if (!response.data.session_data.permissions?.LND00001) {
                            window.location.replace(`https://${window.location.host}/user/dashboard`);
                            return;
                        }
                        localStorage.setItem("userData", session_data);
                        setUser({ ...response.data.session_data });
                        try {
                            if (window.location.search.split('?')[1].split('=')[0] === 'token') {
                                window.history.replaceState(null, "ALAPAR-lender", "/")
                                //window.location.assign("/");
                            }
                        } catch (e) { }
                    } else {
                        localStorage.removeItem('userData');
                        localStorage.removeItem('userInfo');
                        if (response.data.hasOwnProperty('type')) {
                            if (response.data.type === 1) {
                                window.location.replace(`${externalUrl.identityUrl}`);
                                return;
                            }
                        }
                        window.location.replace(`${externalUrl.lenderUrl}/v1/auth/logout`);
                    }
                }).catch(function () {
                    localStorage.removeItem('userData');
                    localStorage.removeItem('userInfo');
                    window.location.replace(`${externalUrl.lenderUrl}/v1/auth/logout`);
                });
            } catch (e) {
                localStorage.removeItem('userInfo');
                window.location.replace(`${externalUrl.lenderUrl}/v1/auth/logout`);
            }
        }
        if (Object.keys(user).length === 0) {
            return (<></>);
        }
    } catch (error) {

    }

    return (
        <Switch>
            <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
            <Redirect to="/admin/dashboard" />
        </Switch>
    );
}
