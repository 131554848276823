import React, { useEffect, useState } from "react";

import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  FormGroup,
  Input,
  Button,
} from "reactstrap";

import axios from "axios";
import externalUrl from "config/externalUrl";
import { Link, useParams } from "react-router-dom";
import { Redirect } from "react-router-dom";
import NotificationAlert from "react-notification-alert";

function FormLenderType(props) {
  const notificationAlert = React.useRef();

  const [name, setName] = useState('');
  const [nameError, setNameError] = useState(false);
  const [description, setDescription] = useState('');
  const [userData] = useState(JSON.parse(localStorage.getItem('userData')));
  const [redirect, setRedirect] = useState({ status: false, to: '/admin/lenders-type' });
  const [options, setOptions] = useState({});

  let { id } = useParams();

  useEffect(() => {
    if (props.location.update || (id !== null && id !== undefined)) {
      fetchData();
    }
  }, []); // eslint-disable-line

  async function fetchData() {
    axios.get(`${externalUrl.lenderUrl}/v1/lender-type/search?id=${id}`, {
      headers: { Authorization: `Bearer ${userData.private_token}`, },
    }).then(function (response) {
      if (response.data.error !== '0') {
        setRedirect({ ...redirect, status: true });
      } else {
        setName(response.data.msg.name);
        setDescription(response.data.msg.description);
      }
    }).catch(
      function () {
      }
    );
  }

  const create = () => {
    if (name === '') {
      setNameError(true);
      notify("br", "El nombre es obligatorio.", "danger");
      return;
    }

    const body = {
      name: name,
      description: description,
      created_by: userData.display_name
    }

    axios.post(`${externalUrl.lenderUrl}/v1/lender-type/add`, body, {
      headers: { Authorization: `Bearer ${userData.private_token}`, },
    }).then(function (response) {
      notify("br", "Creado correctamente.", "success");
      setRedirect({ to: 'view-lender-type/' + response.data.msg.id, status: true });
    }).catch(
      function (error) {
        console.error(error);
        notify("br", "No se ha podido crear.", "danger");
      }
    );
  };

  const update = () => {
    if (name === '') {
      setNameError(true);
      notify("br", "El nombre es obligatorio.", "danger");
      return;
    }

    const body = {
      id: id,
      name: name,
      description: description,
      created_by: userData.display_name
    }

    axios.post(`${externalUrl.lenderUrl}/v1/lender-type/modify`, body, {
      headers: { Authorization: `Bearer ${userData.private_token}`, },
    }).then(function (response) {
      if (response.data.error !== '0') {
        notify("br", "No se ha podido crear. Intenta más tarde.", "danger");
      } else {
        setOptions({ place: "br", text: "Actualizado correctamente.", type: "info" });
        sessionStorage.setItem('show', '0');
        setRedirect({ to: '/admin/view-lender-type/' + id, status: true });
      }
    }).catch(
      function (error) {
        notify("br", "No se ha podido crear.", "danger");
      }
    );
  };

  const notify = (place, text, type) => {
    var options = {
      place: place,
      message: (
        <div>
          <div>
            {text}
          </div>
        </div>
      ),
      type: type,
      icon: "nc-icon nc-simple-remove",
      autoDismiss: 7,
    };
    notificationAlert.current.notificationAlert(options);
  };

  return (
    <>
      <NotificationAlert ref={notificationAlert} />
      <div className="content">
        <Row>
          <Col md="12">
            <Card className="card-user">
              <CardHeader>
                <CardTitle tag="h5">{props.location.update || id != null ? 'Actualizar' : 'Crear'} tipo de lender</CardTitle>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col className="pr-10" md="3">
                    <FormGroup>
                      <label>Nombre</label>
                      <Input
                        placeholder={name === '' ? "Nombre" : name}
                        type="text"
                        onChange={(e) => { setName(e.target.value); setNameError(false); }}
                        invalid={nameError}
                        autoComplete="off"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <FormGroup>
                      <label>Descripción</label>
                      <Input
                        type="textarea"
                        onChange={(e) => { setDescription(e.target.value); }}
                        defaultValue={description}
                        autoComplete="off"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <div className="update ml-auto mr-auto">
                    <Button
                      className="btn button-alapar-success"
                      color="primary"
                      type="submit"
                      onClick={props.location.update || id != null ? update : create}
                    >
                      {props.location.update || id != null ? 'Actualizar' : 'Crear'}
                    </Button>
                    <Link to={'/admin/lenders-type'}>
                      <Button
                        className="button-alapar-cancel"
                        outline
                        color="primary"
                      >
                        Cancelar
                      </Button>
                    </Link>
                  </div>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
      {redirect.status ? <Redirect to={{ pathname: redirect.to, state: { options: options } }} /> : null}
    </>
  );
}

export default FormLenderType;
