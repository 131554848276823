import axios from "axios";
import externalUrl from "config/externalUrl";
import { useEffect, useRef, useState } from "react";
import ReactNotificationAlert from "react-notification-alert";
import { Button, Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";
import ModalAccess from "../components/Modals/Access/ModalAccess";
import ModalCreateUser from "../components/Modals/CreateUser/ModalCreateUser";
import ModalDeleteUser from "../components/Modals/DeleteUser/DeleteUser";
import UserTable from "../components/UserTable/UserTable";

function UserList() {
    const notificationAlert = useRef();
    const [users, setUsers] = useState([]);
    const [modalCreateUser, setModalCreateUser] = useState(false);
    const [modalDeleteUser, setModalDeleteUser] = useState(false);
    const [modalAccess, setModalAccess] = useState(false);

    const [userData] = useState(JSON.parse(localStorage.getItem('userData')));
    const [data, setData] = useState({ uid: -1, display_name: '', name: '', last_name: '', email_address: '' });

    useEffect(() => {
        fetchData();
    }, []); // eslint-disable-line

    const fetchData = () => {
        var url = `${externalUrl.lenderUrl}/v1/lender-user/get-all`;
        axios.get(url, {
            headers: { Authorization: `Bearer ${userData.private_token}`, },
        }).then(function (response) {
            if (response.data.error === 1) {
                return;
            }
            setUsers(response.data.data);
        }).catch(function (error) {
            console.log(error);
        });
    };

    const toggleCreateUser = () => {
        if (modalCreateUser) {
            setData({ uid: -1, display_name: '', name: '', last_name: '', email_address: '' });
        }
        setModalCreateUser(prevState => !prevState)
    };

    const toggleDeleteUser = () => setModalDeleteUser(prevState => !prevState);
    const toggleAccess = () => setModalAccess(prevState => !prevState);

    const notify = (place, text, type) => {
        var icon = '';
        if (type === 'success') {
            icon = 'nc-check-2';
        } else if (type === 'info') {
            icon = 'nc-alert-circle-i';
        } else if (type === 'warning') {
            icon = 'nc-bell-55';
        } else if (type === 'danger') {
            icon = 'nc-simple-remove';
        }

        var options = {
            place: place,
            message: (
                <div>
                    <div>
                        {text}
                    </div>
                </div>
            ),
            type: type,
            icon: `nc-icon ${icon}`,
            autoDismiss: 7,
        };
        notificationAlert.current.notificationAlert(options);
    };

    return (
        <>
            <ReactNotificationAlert ref={notificationAlert} />
            <div className="content">
                <Row>
                    <Col md="12">
                        <Card>
                            <CardHeader>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <CardTitle tag="h4">Usuarios</CardTitle>
                                    <Button
                                        className="btn button-alapar-success"
                                        color="primary"
                                        type="submit"
                                        onClick={toggleCreateUser}>
                                        Crear usuario
                                    </Button>
                                </div>
                            </CardHeader>
                            <CardBody>
                                <div style={{ width: '100%', overflow: 'auto' }}>
                                    <UserTable users={users} setData={setData} toggle={toggleCreateUser} toggleDelete={toggleDeleteUser} toggleAccess={toggleAccess} />
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
            <ModalCreateUser modal={modalCreateUser} toggle={toggleCreateUser} notify={notify} fetchData={fetchData} data={data} setData={setData} />
            <ModalDeleteUser modal={modalDeleteUser} toggle={toggleDeleteUser} notify={notify} fetchData={fetchData} data={data} />
            <ModalAccess modal={modalAccess} toggle={toggleAccess} notify={notify} data={data} />
        </>
    );
}

export default UserList;
