import React from "react";

import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  FormGroup,
} from "reactstrap";

import countries from "data/countries";
import Skeleton from "react-loading-skeleton";

const styleTitle = {
  fontSize: '1.1rem',
  margin: 0
};

const styleLabel = {
  fontSize: '0.9rem'
};

function ViewPersonNatural(props) {
  return (
    <>
      <Card className="card-user">
        <CardHeader>
          <CardTitle tag="h5">Persona natural</CardTitle>
        </CardHeader>
        <CardBody style={{ minHeight: 0, paddingLeft: '2rem' }}>
          <Row>
            <Col className="pr-10" md="3">
              <FormGroup>
                <p style={styleTitle}>Primer nombre</p>
                {props.loading ? <Skeleton count={1} /> : <label style={styleLabel}>{props.personNatural.first_name}</label>}
              </FormGroup>
            </Col>
            <Col className="pr-10" md="3">
              <FormGroup>
                <p style={styleTitle}>Otros nombres</p>
                {props.loading ? <Skeleton count={1} /> : <label style={styleLabel}>{props.personNatural.second_name}</label>}
              </FormGroup>
            </Col>
            <Col className="pr-10" md="3">
              <FormGroup>
                <p style={styleTitle}>Primer apellido</p>
                {props.loading ? <Skeleton count={1} /> : <label style={styleLabel}>{props.personNatural.first_surname}</label>}
              </FormGroup>
            </Col>
            <Col className="pr-10" md="3">
              <FormGroup>
                <p style={styleTitle}>Segundo apellido</p>
                {props.loading ? <Skeleton count={1} /> : <label style={styleLabel}>{props.personNatural.second_surname}</label>}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col className="pr-10" md="3">
              <FormGroup>
                <p style={styleTitle}>Apellido de casada</p>
                {props.loading ? <Skeleton count={1} /> : <label style={styleLabel}>{props.personNatural.family_lastname}</label>}
              </FormGroup>
            </Col>
            <Col className="pr-10" md="3">
              <FormGroup>
                <p style={styleTitle}>Fecha nacimiento</p>
                {props.loading ? <Skeleton count={1} /> : <label style={styleLabel}>{props.personNatural.birthday}</label>}
              </FormGroup>
            </Col>
            <Col className="pr-10" md="3">
              <FormGroup>
                <p style={styleTitle}>País residencia</p>
                {props.loading ? <Skeleton count={1} /> : <label style={styleLabel}>
                  {countries.filter(option =>
                    option.value === props.personNatural.country_residence)[0]?.label}
                </label>}
              </FormGroup>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
}

export default ViewPersonNatural;
