import { Button } from "reactstrap";
import DataTable from "react-data-table-component";
import { BsFillPencilFill, BsFillTrashFill, BsDiagram3Fill } from "react-icons/bs";

import LoadingCircles from "common/components/AnimatedIcons/LoadingCircles/LoadingCircles";
import EmailCell from "./Cells/EmailCell";
import StatusCell from "./Cells/StatusCell";
import styles from './UserTable.module.scss';

function UserTable(props) {
    const { users, setData, toggle, toggleDelete, toggleAccess } = props;

    const columns = [
        {
            name: "UID",
            selector: (row) => row.uid,
            sortable: true,
            width: '4.5rem',
            right: true
        },
        {
            name: "Nombre de despliegue",
            selector: (row) => JSON.parse(row.data).display_name,
            sortable: true,
            width: 'calc(38% - 11rem)'
        },
        {
            name: "Nombres",
            selector: (row) => JSON.parse(row.data).name,
            sortable: true,
            width: '15%'
        },
        {
            name: "Apellidos",
            selector: (row) => JSON.parse(row.data).last_name,
            sortable: true,
            width: '15%'
        },
        {
            name: "Correo",
            selector: (row) => JSON.parse(row.data).email_address,
            sortable: true,
            width: '17%',
            cell: (row) => <EmailCell value={JSON.parse(row.data).email_address} />,
        },
        {
            name: "Estado",
            selector: (row) => row.status,
            sortable: true,
            width: '15%',
            cell: (row) => <StatusCell value={row.status} />,
        },
        {
            name: "Acciones",
            selector: (row) => row.year,
            width: '6rem',
            cell: (row) => <>
                <Button className='btn' title="Acceso" onClick={(e) => { setData({ ...JSON.parse(row.data), uid: row.uid }); toggleAccess(); e.target.blur(); }} color='link' style={{ color: '#51cbce' }}><BsDiagram3Fill /></Button>
                <Button className='btn' title="Editar" onClick={(e) => { setData({ ...JSON.parse(row.data), uid: row.uid }); toggle(); e.target.blur(); }} color='link' style={{ color: '#51cbce' }}><BsFillPencilFill /></Button>
                <Button className='btn' title="Eliminar" color='link' onClick={(e) => { setData({ ...JSON.parse(row.data), uid: row.uid }); toggleDelete(); e.target.blur(); }} style={{ color: '#51cbce' }}><BsFillTrashFill /></Button>
            </>,
            center: true
        },
    ];

    return (
        <DataTable
            columns={columns}
            data={users}
            pagination
            progressComponent={
                <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                    <div className={styles.alignTextCenter} style={{ marginTop: '0.5rem', marginBottom: '1rem' }}>
                        <LoadingCircles />
                    </div>
                    <div>
                        Obteniendo información...
                    </div>
                </div>
            }
            persistTableHead={true}
            noDataComponent={
                <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                    No existen usuarios.
                </div>
            }
        />
    );
}

export default UserTable;
