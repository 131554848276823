/*!

=========================================================
* Paper Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Table,
    Row,
    Col,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Label,
    Input,
} from "reactstrap";
import NotificationAlert from "react-notification-alert";

import { BsFillTrashFill } from "react-icons/bs";
import { BsFillPencilFill } from "react-icons/bs";
import externalUrl from "config/externalUrl";
import axios from "axios";

function LenderList() {
    const notificationAlert = React.useRef();
    const [modal, setModal] = useState(false);
    const [userData] = useState(JSON.parse(localStorage.getItem('userData')));
    const [lenders, setLenders] = useState([]);
    const [lenderType, setLenderType] = useState([]);
    const [selected, setSelected] = useState({});
    const [filterData, setFilterData] = useState([]);
    const [filterVal, setFilterValues] = useState({ id: '', display_name: '' });

    useEffect(() => {
        fetchData();
    }, []); // eslint-disable-line

    async function fetchData() {
        axios.get(`${externalUrl.lenderUrl}/v1/lender/index`, {
            headers: { Authorization: `Bearer ${userData.private_token}`, },
        }).then(function (response) {
            if (response.data.error === '1') {
                setLenders([]);
            } else {
                setLenderType(response.data.types);
                setLenders(response.data.msg);
                setFilterData(response.data.msg);
            }
        }).catch(function (error) {
            if (error.response.status === 401) {
                localStorage.removeItem('userData');
                window.location.replace(`${externalUrl.lenderUrl}/v1/auth/logout`);
            } else {
                console.error(error);
            }
        });
    };

    const toggle = (e) => {
        if (e?.status === 3) {
            notify("br", 'Este tipo de lender ya se encuentra eliminado.', "info");
        } else {
            setModal(!modal);
        }
    }

    const deleteLender = () => {
        var to = '';
        if (selected.status === 1) {
            to = 'disable';
        } else if (selected.status === 2) {
            to = 'drop';
        } else {
            notify("br", "Este lender ya se encuentra eliminado.", "info");
            return;
        }

        axios.get(`${externalUrl.lenderUrl}/v1/lender/${to}?id=${selected.id}`, {
            headers: { Authorization: `Bearer ${userData.private_token}`, },
        }).then(function (response) {
            if (response.data.error === '1') {
                notify("br", response.data.msg, "danger");
                toggle(null);
            } else {
                notify("br", `El lender se ${selected.status === 1 ? ' deshabilitó' : '  eliminó'} correctamente.`, "info");
                fetchData();
                setSelected({});
                toggle(null);
            }
        }).catch(
            function (error) {
                // Arreglar este mensaje 
                notify("br", "Ocurrio un error en la base de datos", "danger");
                toggle(null);
            }
        );
    };

    const filter = (e) => {
        let keys = Object.keys(filterVal);
        let values = JSON.parse(JSON.stringify(lenders));

        keys.forEach(key => {
            if (e.target.name === key) {
                values = filterValue(e.target.value, key, values);
                setFilterValues({ ...filterVal, [e.target.name]: e.target.value });
            } else if (filterVal[key] !== '') {
                values = filterValue(filterVal[key], key, values);
            }
        });
        /* const filterValues = loans.filter((val) => (val.loan_id_exp.indexOf(e.target.value) !== -1)).map(option => option); */
        setFilterData(values);
    };

    const filterValue = (value, key, values) => {
        const filterVal = values.filter((val) => (val[key].toString().toLowerCase().indexOf(value.toLowerCase()) !== -1)).map(option => option);
        return filterVal;
    };

    const notify = (place, text, type) => {
        var icon = '';
        if (type === 'success') {
            icon = 'nc-check-2';
        } else if (type === 'info') {
            icon = 'nc-alert-circle-i';
        } else if (type === 'warning') {
            icon = 'nc-bell-55';
        } else if (type === 'danger') {
            icon = 'nc-simple-remove';
        }

        var options = {
            place: place,
            message: (
                <div>
                    <div>
                        {text}
                    </div>
                </div>
            ),
            type: type,
            icon: `nc-icon ${icon}`,
            autoDismiss: 7,
        };
        notificationAlert.current.notificationAlert(options);
    };

    return (
        <>
            <NotificationAlert ref={notificationAlert} />
            <div className="content">
                <Row>
                    <Col md="12">
                        <Card>
                            <CardHeader>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <CardTitle tag="h4">Lenders</CardTitle>
                                    <div>
                                        <Link to={'create-lender-type'}>
                                            <Button
                                                className="btn button-alapar-success"
                                                color="primary"
                                                type="submit"
                                            >
                                                Crear tipo de lender
                                            </Button>
                                        </Link>
                                        {'  '}
                                        <Link to={'create-lender'}>
                                            <Button
                                                className="btn button-alapar-success"
                                                color="primary"
                                                type="submit"
                                            >
                                                Crear
                                            </Button>
                                        </Link>
                                    </div>
                                </div>
                            </CardHeader>
                            <CardBody>
                                <Table >
                                    <thead className="text-primary">
                                        <tr>
                                            <th style={{ width: '5%' }} className="table-alapar-title-default">ID</th>
                                            <th className="table-alapar-title-default">Nombre</th>
                                            <th className="table-alapar-title-default">Tipo</th>
                                            <th className="table-alapar-title-default">Notas</th>
                                            <th className="table-alapar-title-default">Estado</th>
                                            <th style={{ width: '10%' }} className="table-alapar-title-default">Acciones</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            lenders.length === 0 ? <tr>
                                                <td colSpan={6}>
                                                    <center>
                                                        <label style={{ fontSize: '1.2rem' }}>
                                                            No hay lenders creados
                                                        </label>
                                                    </center>
                                                </td>
                                            </tr> :
                                                (
                                                    <>
                                                        <tr key='search'>
                                                            {
                                                                Object.keys(filterVal).map(key => (
                                                                    <td key={key}>
                                                                        <Input
                                                                            name={key}
                                                                            onChange={(e) => filter(e)} />
                                                                    </td>
                                                                ))
                                                            }

                                                            <td className="text-center">
                                                                {' '}
                                                            </td>
                                                            <td className="text-center">
                                                                {' '}
                                                            </td>
                                                            <td className="text-center">
                                                                {' '}
                                                            </td>
                                                            <td className="text-center">
                                                                {' '}
                                                            </td>
                                                        </tr>
                                                        {

                                                            filterData.map((lender) => (
                                                                <tr key={lender.id}>
                                                                    <td style={{ textAlign: 'center' }}>
                                                                        <Link to={`/admin/view-lender/${lender.id}`}>
                                                                            {lender.id}
                                                                        </Link>
                                                                    </td>
                                                                    <td>
                                                                        <Link to={`/admin/view-lender/${lender.id}`}>
                                                                            {lender.display_name}
                                                                        </Link>
                                                                    </td>
                                                                    <td>
                                                                        {lenderType.filter(option =>
                                                                            option.id === lender.type)[0]?.name}
                                                                    </td>
                                                                    <td>{lender.notes}</td>
                                                                    <td>{lender.status === 1 ? 'Habilitado' : lender.status === 2 ? 'Deshabilitado' : <div style={{ color: 'red' }}>Eliminado</div>}</td>
                                                                    <td className="text-center">
                                                                        <Link to={{ pathname: `/admin/update-lender/${lender.id}`, update: true }}><Button className='btn' color='link' style={{ color: '#51cbce' }}><BsFillPencilFill /></Button></Link>
                                                                        <Button className='btn' color='link' onClick={() => { setSelected(lender); toggle(lender) }} style={{ color: '#51cbce' }}><BsFillTrashFill /></Button>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        }
                                                    </>
                                                )
                                        }
                                    </tbody>
                                </Table>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div >

            <Modal isOpen={modal}
                toggle={() => toggle(null)}
                style={{ top: '5vh' }}>
                <ModalHeader toggle={() => toggle(null)}>
                    {selected.status === 1 ? 'Deshabilitar' : 'Eliminar'} lender
                </ModalHeader>
                <ModalBody style={{ paddingBottom: '0.3rem' }}>
                    <Label>
                        ¿Desea eliminar el lender con nombre <b>{selected.display_name}</b> y NIT <b>{selected.nit}</b>?
                    </Label>
                </ModalBody>
                <ModalFooter style={{ paddingTop: '0.3rem', paddingBottom: '0.3rem' }}>
                    <Button
                        className={"btn " + (selected.status === 1 ? 'button-alapar-warning' : 'button-alapar-danger') + " button-left"}
                        onClick={deleteLender}
                    >
                        {selected.status === 1 ? 'Deshabilitar' : 'Eliminar'}
                    </Button>
                    {' '}
                    <Button
                        className="button-alapar-cancel button-right"
                        outline
                        color="primary"
                        onClick={() => toggle(null)}>
                        Cancelar
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
}



export default LenderList;
