import React from "react";

import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Input,
  Button,
  Collapse,
  Spinner,
} from "reactstrap";

import { BsPlusLg, BsXLg } from "react-icons/bs";
import CedulaLender from "./CedulaLender";

function FormCedulaOwnershipTerm(props) {
  const addLenders = (i) => {
    const newLender = JSON.parse(JSON.stringify(props.cedulaOwnership));
    newLender[i].lenders.push({ percentage: 0, lender: '' });
    props.setCedulaOwnership(newLender);
  };

  const addOwnership = () => {
    props.setCedulaOwnership([...props.cedulaOwnership, { lenders: [{ percentage: 100, lender: '', view: true }], settled_on: '', price: '', owed_capital_at_sell: '' }]);

    window.setTimeout(function () {
      props.setCedulaOwnership(prevState => {
        const newState = prevState.map(obj => {
          if (!obj.hasOwnProperty('view')) {
            return { ...obj, view: true };
          }
          return obj;
        });
        return newState;
      });
    }, 1);
  };

  const changeValue = (e, i) => {
    const newLender = JSON.parse(JSON.stringify(props.cedulaOwnership));
    newLender[i][e.target.name] = e.target.value;
    props.setCedulaOwnership(newLender);

    if (!props.updatedValues) {
      props.setUpdatedValues(true);
    }
  }

  const changeHours = () => {
    var date = new Date(); // today
    date.setUTCHours(0, 0, 0, 0); //set default time 00:00 AM
    const dStr = date.toISOString()
    // remove seconds and milliseconds
    console.log(dStr.substring(0, dStr.indexOf(':', dStr.indexOf(':') + 1)))
    return dStr.substring(0, dStr.indexOf(':', dStr.indexOf(':') + 1))
  }

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card className="card-user">
              {
                props.loading ?
                  <center>
                    <Spinner style={{ marginTop: '1.5rem', marginBottom: '1.5rem' }}>
                      Cargando...
                    </Spinner>
                  </center>
                  :
                  !props.selectedCedula.hasOwnProperty('code') ?
                    <CardBody style={{ minHeight: 0 }}>
                      <center>
                        <CardTitle style={{ marginTop: '1.5rem' }}>
                          Selecciona una cédula
                        </CardTitle>
                      </center>
                    </CardBody>
                    :
                    <>
                      <CardHeader>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <CardTitle tag="h5">Registro de desembolsos</CardTitle>
                          {props.loan?.type === 1 || props.loan?.type === 3 ?
                            <Button className='btn' color='link' style={{ color: '#51cbce' }} onClick={() => addOwnership()} ><BsPlusLg /></Button>
                            :
                            <></>
                          }
                        </div>
                      </CardHeader>
                      <CardBody style={{ minHeight: 0 }}>
                        {
                          props.cedulaOwnership.map((terms, index) => (
                            <Collapse key={"term-" + index} isOpen={terms.view}>
                              {index === 0 ? null : <hr />}
                              <div style={{ display: 'flex', flexWrap: 'nowrap', justifyContent: "flex-start", gap: '0.2rem', marginLeft: '0rem' }}>
                                <div style={{ width: '60%', zIndex: '0' }}>
                                  <CedulaLender
                                    setCedulaOwnership={props.setCedulaOwnership}
                                    cedulaOwnership={props.cedulaOwnership}
                                    lenders={props.lenders}
                                    lendersCedula={terms.lenders}
                                    addLenders={addLenders}
                                    removeLenders={props.toggleOwnership}
                                    index={index}
                                    updatedValues={props.updatedValues}
                                    setUpdatedValues={props.setUpdatedValues} />
                                </div>

                                <div style={{ width: '18.2rem !important', height: '2rem', display: 'flex', justifyContent: "flex-start", gap: '0.2rem', marginLeft: '-6.4rem' }}>
                                  <div style={{ width: '7.2rem', minWidth: '7.2rem' }}>
                                    <label>Fecha de compra</label>
                                    <Input
                                      type="datetime-local"
                                      name='settled_on'
                                      style={{ width: '7.2rem', height: '30px', zIndex: '1000', position: 'absolute' }}
                                      value={!terms.hasOwnProperty('settled_on') ? '' : (terms?.settled_on === '' ? /* changeHours() */ terms?.settled_on : terms?.settled_on)}
                                      onChange={(e) => changeValue(e, index)}
                                    />
                                  </div>
                                  <div style={{ width: '5.5rem' }}>
                                    <label style={{ whiteSpace: 'nowrap' }} className="font-mini">Cuota crédito</label>
                                    <Input
                                      type="text"
                                      name='first_credit_position'
                                      onChange={(e) => changeValue(e, index)}
                                      style={{ minWidth: '5.5rem', height: '30px', textAlign: 'right' }}
                                      value={terms.first_credit_position === null ? '' : terms.first_credit_position}
                                      autoComplete="off"
                                    />
                                  </div>
                                  <div style={{ width: `5.5rem`, marginRight: index === 0 ? '2.2rem' : 'inherit' }}>
                                    <label className="font-mini" style={{ whiteSpace: 'nowrap' }}>Precio transacción</label>
                                    <div style={{ display: 'flex', justifyContent: "flex-start", gap: '0.2rem' }}>
                                      <div>
                                        <Input
                                          type="text"
                                          name='price'
                                          onChange={(e) => changeValue(e, index)}
                                          value={terms.price === null ? '' : terms.price}
                                          style={{ minWidth: '5.5rem', height: '30px' }}
                                          className='lender-field-ownership'
                                          autoComplete="off"
                                        />
                                      </div>
                                      {
                                        index === 0 ? <></> :
                                          <div className="delete-cedula-btn" style={{ marginTop: '-0.8rem' }} >
                                            <Button className='btn' color='link' style={{ color: '#51cbce' }} onClick={() => props.toggleOwnership(true, index, 0, terms?.id)}><BsXLg /></Button>
                                          </div>
                                      }
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Collapse>
                          ))
                        }
                        <Row>
                          <div className="update ml-auto mr-auto" style={{ zIndex: 1001 }}>
                            <Button
                              className="btn button-alapar-success"
                              type="submit"
                              onClick={props.create}
                            >
                              {props.update ? 'Actualizar' : 'Crear'}
                            </Button>
                          </div>
                        </Row>
                      </CardBody>
                    </>
              }
            </Card>
          </Col>
        </Row>
      </div >
    </>
  );
}

export default FormCedulaOwnershipTerm;
