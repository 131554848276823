/*!

=========================================================
* Paper Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import FormLoan from "components/Create/Loan";
import ModalLedger from "components/Create/Modals/Ledger";
import React, { useState } from "react";
// react plugin used to create charts
import { Link } from "react-router-dom";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Label,
} from "reactstrap";

function Dashboard() {
  const [show, setShow] = React.useState(false);
  const [showLedger, setShowLedger] = React.useState(false);
  const [userData] = useState(JSON.parse(localStorage.getItem("userData")));

  return (
    <>
      <div className="content">
        <Row>
          <Col md="6">
            <Card className="card-user">
              <CardHeader>
                <CardTitle tag="h5">Lenders</CardTitle>
              </CardHeader>
              <CardBody>
                <Link
                  to={{
                    pathname: "/admin/lender",
                  }}
                >
                  Lender
                </Link>
                <br />
                {!userData?.permissions.LND00002 ? null : (
                  <>
                    <Label className="label-link" onClick={() => setShow(true)}>
                      Importar cédulas de un crédito
                    </Label>
                    <br />
                    <Link
                      to={{
                        pathname: "/admin/loans",
                      }}
                    >
                      Editar nóminas de cédulas
                    </Link>
                    <br />
                    <Link
                      to={{
                        pathname: "/admin/users",
                      }}
                    >
                      Ver usuarios
                    </Link>
                  </>
                )}
              </CardBody>
            </Card>
          </Col>
          <Col md="6">
            <Card className="card-user">
              <CardHeader>
                <CardTitle tag="h5">Maestros</CardTitle>
              </CardHeader>
              <CardBody>
                <Link
                  to={{
                    pathname: "/admin/lenders-type",
                  }}
                >
                  Tipos de lender
                </Link>
                <br />
                <Link
                  to={{
                    pathname: "/admin/bank/view",
                  }}
                >
                  Bancos
                </Link>
                <br />
                {!userData?.permissions.LND00003 ? null : (
                  <>
                    <br />
                    <CardTitle tag="h5" style={{ marginBottom: 2 }}>
                      Reportes
                    </CardTitle>
                    <Label
                      className="label-link"
                      onClick={() => setShowLedger(true)}
                    >
                      Nóminas de cédula
                    </Label>
                    <br />
                  </>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>

      <FormLoan setShow={setShow} show={show} />
      <ModalLedger setShow={setShowLedger} show={showLedger} />
    </>
  );
}

export default Dashboard;
