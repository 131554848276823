import React from "react";

import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  FormGroup,
  Input,
  Button,
} from "reactstrap";

import Select from 'react-select';
import countries from "data/countries";
import { Link } from "react-router-dom";

function FormPersonNatural(props) {
  return (
    <>
      <Card className="card-user">
        <CardHeader>
          <CardTitle tag="h5">Persona natural</CardTitle>
        </CardHeader>
        <CardBody>
          <Row>
            <Col className="pr-10" md="3">
              <FormGroup>
                <label>Primer nombre *</label>
                <Input
                  placeholder={props.personNatural.first_name === '' ? 'Primer nombre' : props.personNatural.first_name}
                  type="text"
                  name='first_name'
                  onChange={(e) => { props.changeValue(e, props.setPersonNatural, props.personNatural); props.setFirstNameError(false) }}
                  invalid={props.firstNameError}
                />
              </FormGroup>
            </Col>
            <Col className="pr-10" md="3">
              <FormGroup>
                <label>Otros nombres</label>
                <Input
                  placeholder={props.personNatural.second_name === '' ? 'Otros nombres' : props.personNatural.second_name}
                  type="text"
                  name='second_name'
                  onChange={(e) => props.changeValue(e, props.setPersonNatural, props.personNatural)}
                  autoComplete="off"
                />
              </FormGroup>
            </Col>
            <Col className="pr-10" md="3">
              <FormGroup>
                <label>Primer apellido *</label>
                <Input
                  placeholder={props.personNatural.first_surname === '' ? 'Primer apellido' : props.personNatural.first_surname}
                  type="text"
                  name='first_surname'
                  onChange={(e) => { props.changeValue(e, props.setPersonNatural, props.personNatural); props.setFirstSurnameError(false); }}
                  invalid={props.firstSurnameError}
                  autoComplete="off"
                />
              </FormGroup>
            </Col>
            <Col className="pr-10" md="3">
              <FormGroup>
                <label>Segundo apellido</label>
                <Input
                  placeholder={props.personNatural.second_surname === '' ? 'Segundo apellido' : props.personNatural.second_surname}
                  type="text"
                  name='second_surname'
                  onChange={(e) => props.changeValue(e, props.setPersonNatural, props.personNatural)}
                  autoComplete="off"
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col className="pr-10" md="3">
              <FormGroup>
                <label>Apellido de casada</label>
                <Input
                  placeholder={props.personNatural.family_lastname === '' ? 'Apellido de casada' : props.personNatural.family_lastname}
                  type="text"
                  name='family_lastname'
                  onChange={(e) => props.changeValue(e, props.setPersonNatural, props.personNatural)}
                  autoComplete="off"
                />
              </FormGroup>
            </Col>
            <Col className="pr-10" md="3">
              <FormGroup>
                <label>Fecha nacimiento</label>
                <Input
                  defaultValue={props.personNatural.birthday === '' ? '' : props.personNatural.birthday}
                  type="date"
                  name='birthday'
                  onChange={(e) => props.changeValue(e, props.setPersonNatural, props.personNatural)}
                />
                {/* <DatePicker id="example-datepicker"
                  value={props.personNatural.birthday === '' ? '' : props.personNatural.birthday}
                  dayLabels={['Dom', 'Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb']}
                  monthLabels={['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']}
                  onChange={(e) => { props.changeValueSelect(e, props.setPersonNatural, props.personNatural, 'birthday'); }} */}
              </FormGroup>
            </Col>
            <Col className="pr-10" md="3">
              <FormGroup>
                <label>País residencia</label>
                <Select
                  options={countries}
                  placeholder={props.personNatural.country_residence === '' ? 'Seleccione...' :
                    countries.filter(option =>
                      option.value === props.personNatural.country_residence)[0]?.label}
                  noOptionsMessage={({ inputValue }) => !inputValue ? "Cargando..." : "Sin resultados"}
                  onChange={(e) => { props.changeValueSelect(e, props.setPersonNatural, props.personNatural, 'country_residence'); }}
                  className="create-select"
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <div className="update ml-auto mr-auto" style={{ display: 'flex' }}>
              <Button
                className="btn button-alapar-success"
                color="primary"
                type="submit"
                onClick={props.create}
              >
                {props.buttonText}
              </Button>
              <Link to={'/admin/lender'}>
                <Button
                  className="button-alapar-cancel"
                  outline
                  color="primary"
                >
                  Cancelar
                </Button>
              </Link>
            </div>
          </Row>
        </CardBody>
      </Card>
    </>
  );
}

export default FormPersonNatural;
