import DataTable from "react-data-table-component";

import LoadingCircles from "common/components/AnimatedIcons/LoadingCircles/LoadingCircles";
import styles from './../AccessTable.module.scss';
import { forwardRef } from "react";

const customStyles = {
    cells: {
        style: {
            userSelect: 'none'
        }
    }
};

function CedulaTable(props) {
    const { data, setData, indexRow, selectedLender } = props;

    const handleChange = ({ selectedRows }) => {
        let i = 0;
        const newSelected = data.map(item => {
            if (parseInt(selectedRows[0].id) === parseInt(item.id)) {
                if (!item.isSelected) {
                    i++;
                }
                return { ...item, isSelected: !item.isSelected }
            } else {
                if (item.isSelected) {
                    i++;
                }
            }
            return { ...item }
        });

        setData(prevState => {
            const newState = prevState.map(item => {
                if (item.index_row !== indexRow) {
                    return item;
                }
                return { ...item, cedulas: newSelected, isSelected: i === item.cedulas.length };
            })
            return [...newState];
        })
    };

    const columns = [
        {
            selector: (row) => row.value,
            left: true,
            cell: (row) =>
                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                    {'Cédula #' + row.value}
                    {row.actual === '1' ? null : <div style={{color: 'red', marginRight: '2rem'}}>(Vendida)</div>}
                </div>,
        },
    ];

    const Checkbox = forwardRef(({ onClick, ...rest }, ref) => {
        let flagChecked = false;
        if (rest.name.split('select-row-')[0] !== 'select-all-rows') {
            flagChecked = data[parseInt(rest.name.split('select-row-')[1]) - 1].isSelected;
        }
        return (
            <div>
                <input
                    type="checkbox"
                    ref={ref}
                    onClick={onClick}
                    {...rest}
                    checked={flagChecked}
                />
                <label className="form-check-label" id="booty-check" />
            </div>
        )
    })

    const addSelectRow = (state => {
        let i = 0;

        const newSelected = data.map(item => {
            if (parseInt(state.id) === parseInt(item.id)) {
                if (!item.isSelected) {
                    i++;
                }
                return { ...item, isSelected: !item.isSelected }
            } else {
                if (item.isSelected) {
                    i++;
                }
            }
            return { ...item }
        });

        setData(prevState => {
            const newState = prevState.map(item => {
                if (item.index_row !== indexRow) {
                    return item;
                }
                return { ...item, cedulas: newSelected, isSelected: i === item.cedulas.length };
            })
            return [...newState];
        })
    });

    return (
        <DataTable
            columns={columns}
            data={data}
            customStyles={customStyles}
            noTableHead
            selectableRowsHighlight
            highlightOnHover
            pointerOnHover
            onRowClicked={addSelectRow}
            progressComponent={
                <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                    <div className={styles.alignTextCenter} style={{ marginTop: '0.5rem', marginBottom: '1rem' }}>
                        <LoadingCircles />
                    </div>
                    <div>
                        Obteniendo información...
                    </div>
                </div>
            }
            noDataComponent={
                <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                    Este lender no tiene créditos asociados.
                </div>
            }
            selectableRows
            onSelectedRowsChange={handleChange}
            selectableRowsComponent={Checkbox}
        />
    );
}

export default CedulaTable;
