const countries = [
    { label: "-------------", value: "000", },
    { label: "Afganistán", value: "AFG", },
    { label: "Albania", value: "ALB", },
    { label: "Alemania", value: "DEU", },
    { label: "Andorra", value: "AND", },
    { label: "Angola", value: "AGO", },
    { label: "Anguila", value: "AIA", },
    { label: "Antártida", value: "ATA", },
    { label: "Antigua y Barbuda", value: "ATG", },
    { label: "Arabia Saudita", value: "SAU", },
    { label: "Argelia", value: "DZA", },
    { label: "Argentina", value: "ARG", },
    { label: "Armenia", value: "ARM", },
    { label: "Aruba", value: "ABW", },
    { label: "Australia", value: "AUS", },
    { label: "Austria", value: "AUT", },
    { label: "Azerbaiyán", value: "AZE", },
    { label: "Bélgica", value: "BEL", },
    { label: "Bahamas", value: "BHS", },
    { label: "Bahrein", value: "BHR", },
    { label: "Bangladesh", value: "BGD", },
    { label: "Barbados", value: "BRB", },
    { label: "Belice", value: "BLZ", },
    { label: "Benín", value: "BEN", },
    { label: "Bhután", value: "BTN", },
    { label: "Bielorrusia", value: "BLR", },
    { label: "Birmania", value: "MMR", },
    { label: "Bolivia", value: "BOL", },
    { label: "Bosnia y Herzegovina", value: "BIH", },
    { label: "Botsuana", value: "BWA", },
    { label: "Brasil", value: "BRA", },
    { label: "Brunéi", value: "BRN", },
    { label: "Bulgaria", value: "BGR", },
    { label: "Burkina Faso", value: "BFA", },
    { label: "Burundi", value: "BDI", },
    { label: "Cabo Verde", value: "CPV", },
    { label: "Camboya", value: "KHM", },
    { label: "Camerún", value: "CMR", },
    { label: "Canadá", value: "CAN", },
    { label: "Chad", value: "TCD", },
    { label: "Chile", value: "CHL", },
    { label: "China", value: "CHN", },
    { label: "Chipre", value: "CYP", },
    { label: "Ciudad del Vaticano", value: "VAT", },
    { label: "Colombia", value: "COL", },
    { label: "Comoras", value: "COM", },
    { label: "República del Congo", value: "COG", },
    { label: "República Democrática del Congo", value: "COD", },
    { label: "Corea del Norte", value: "PRK", },
    { label: "Corea del Sur", value: "KOR", },
    { label: "Costa de Marfil", value: "CIV", },
    { label: "Costa Rica", value: "CRI", },
    { label: "Croacia", value: "HRV", },
    { label: "Cuba", value: "CUB", },
    { label: "Curazao", value: "CWU", },
    { label: "Dinamarca", value: "DNK", },
    { label: "Dominica", value: "DMA", },
    { label: "Ecuador", value: "ECU", },
    { label: "Egipto", value: "EGY", },
    { label: "El Salvador", value: "SLV", },
    { label: "Emiratos Árabes Unidos", value: "ARE", },
    { label: "Eritrea", value: "ERI", },
    { label: "Eslovaquia", value: "SVK", },
    { label: "Eslovenia", value: "SVN", },
    { label: "España", value: "ESP", },
    { label: "Estados Unidos de América", value: "USA", },
    { label: "Estonia", value: "EST", },
    { label: "Etiopía", value: "ETH", },
    { label: "Filipinas", value: "PHL", },
    { label: "Finlandia", value: "FIN", },
    { label: "Fiyi", value: "FJI", },
    { label: "Francia", value: "FRA", },
    { label: "Gabón", value: "GAB", },
    { label: "Gambia", value: "GMB", },
    { label: "Georgia", value: "GEO", },
    { label: "Ghana", value: "GHA", },
    { label: "Gibraltar", value: "GIB", },
    { label: "Granada", value: "GRD", },
    { label: "Grecia", value: "GRC", },
    { label: "Groenlandia", value: "GRL", },
    { label: "Guadalupe", value: "GLP", },
    { label: "Guam", value: "GUM", },
    { label: "Guatemala", value: "GTM", },
    { label: "Guayana Francesa", value: "GUF", },
    { label: "Guernsey", value: "GGY", },
    { label: "Guinea", value: "GIN", },
    { label: "Guinea Ecuatorial", value: "GNQ", },
    { label: "Guinea-Bissau", value: "GNB", },
    { label: "Guyana", value: "GUY", },
    { label: "Haití", value: "HTI", },
    { label: "Honduras", value: "HND", },
    { label: "Hong kong", value: "HKG", },
    { label: "Hungría", value: "HUN", },
    { label: "India", value: "IND", },
    { label: "Indonesia", value: "IDN", },
    { label: "Irán", value: "IRN", },
    { label: "Irak", value: "IRQ", },
    { label: "Irlanda", value: "IRL", },
    { label: "Isla Bouvet", value: "BVT", },
    { label: "Isla de Man", value: "IMN", },
    { label: "Isla de Navidad", value: "CXR", },
    { label: "Isla Norfolk", value: "NFK", },
    { label: "Islandia", value: "ISL", },
    { label: "Islas Bermudas", value: "BMU", },
    { label: "Islas Caimán", value: "CYM", },
    { label: "Islas Cocos (Keeling)", value: "CCK", },
    { label: "Islas Cook", value: "COK", },
    { label: "Islas de Åland", value: "ALA", },
    { label: "Islas Feroe", value: "FRO", },
    { label: "Islas Georgias del Sur y Sandwich del Sur", value: "SGS", },
    { label: "Islas Heard y McDonald", value: "HMD", },
    { label: "Islas Maldivas", value: "MDV", },
    { label: "Islas Malvinas", value: "FLK", },
    { label: "Islas Marianas del Norte", value: "MNP", },
    { label: "Islas Marshall", value: "MHL", },
    { label: "Islas Pitcairn", value: "PCN", },
    { label: "Islas Salomón", value: "SLB", },
    { label: "Islas Turcas y Caicos", value: "TCA", },
    { label: "Islas Ultramarinas Menores de Estados Unidos", value: "UMI", },
    { label: "Islas Vírgenes Británicas", value: "VGB", },
    { label: "Islas Vírgenes de los Estados Unidos", value: "VIR", },
    { label: "Israel", value: "ISR", },
    { label: "Italia", value: "ITA", },
    { label: "Jamaica", value: "JAM", },
    { label: "Japón", value: "JPN", },
    { label: "Jersey", value: "JEY", },
    { label: "Jordania", value: "JOR", },
    { label: "Kazajistán", value: "KAZ", },
    { label: "Kenia", value: "KEN", },
    { label: "Kirguistán", value: "KGZ", },
    { label: "Kiribati", value: "KIR", },
    { label: "Kuwait", value: "KWT", },
    { label: "Líbano", value: "LBN", },
    { label: "Laos", value: "LAO", },
    { label: "Lesoto", value: "LSO", },
    { label: "Letonia", value: "LVA", },
    { label: "Liberia", value: "LBR", },
    { label: "Libia", value: "LBY", },
    { label: "Liechtenstein", value: "LIE", },
    { label: "Lituania", value: "LTU", },
    { label: "Luxemburgo", value: "LUX", },
    { label: "México", value: "MEX", },
    { label: "Mónaco", value: "MCO", },
    { label: "Macao", value: "MAC", },
    { label: "Macedônia", value: "MKD", },
    { label: "Madagascar", value: "MDG", },
    { label: "Malasia", value: "MYS", },
    { label: "Malawi", value: "MWI", },
    { label: "Mali", value: "MLI", },
    { label: "Malta", value: "MLT", },
    { label: "Marruecos", value: "MAR", },
    { label: "Martinica", value: "MTQ", },
    { label: "Mauricio", value: "MUS", },
    { label: "Mauritania", value: "MRT", },
    { label: "Mayotte", value: "MYT", },
    { label: "Micronesia", value: "FSM", },
    { label: "Moldavia", value: "MDA", },
    { label: "Mongolia", value: "MNG", },
    { label: "Montenegro", value: "MNE", },
    { label: "Montserrat", value: "MSR", },
    { label: "Mozambique", value: "MOZ", },
    { label: "Namibia", value: "NAM", },
    { label: "Nauru", value: "NRU", },
    { label: "Nepal", value: "NPL", },
    { label: "Nicaragua", value: "NIC", },
    { label: "Niger", value: "NER", },
    { label: "Nigeria", value: "NGA", },
    { label: "Niue", value: "NIU", },
    { label: "Noruega", value: "NOR", },
    { label: "Nueva Caledonia", value: "NCL", },
    { label: "Nueva Zelanda", value: "NZL", },
    { label: "Omán", value: "OMN", },
    { label: "Países Bajos", value: "NLD", },
    { label: "Pakistán", value: "PAK", },
    { label: "Palau", value: "PLW", },
    { label: "Palestina", value: "PSE", },
    { label: "Panamá", value: "PAN", },
    { label: "Papúa Nueva Guinea", value: "PNG", },
    { label: "Paraguay", value: "PRY", },
    { label: "Perú", value: "PER", },
    { label: "Polinesia Francesa", value: "PYF", },
    { label: "Polonia", value: "POL", },
    { label: "Portugal", value: "PRT", },
    { label: "Puerto Rico", value: "PRI", },
    { label: "Qatar", value: "QAT", },
    { label: "Reino Unido", value: "GBR", },
    { label: "República Centroafricana", value: "CAF", },
    { label: "República Checa", value: "CZE", },
    { label: "República Dominicana", value: "DOM", },
    { label: "República de Sudán del Sur", value: "SSD", },
    { label: "Reunión", value: "REU", },
    { label: "Ruanda", value: "RWA", },
    { label: "Rumanía", value: "ROU", },
    { label: "Rusia", value: "RUS", },
    { label: "Sahara Occidental", value: "ESH", },
    { label: "Samoa", value: "WSM", },
    { label: "Samoa Americana", value: "ASM", },
    { label: "San Bartolomé", value: "BLM", },
    { label: "San Cristóbal y Nieves", value: "KNA", },
    { label: "San Marino", value: "SMR", },
    { label: "San Martín (Francia)", value: "MAF", },
    { label: "San Pedro y Miquelón", value: "SPM", },
    { label: "San Vicente y las Granadinas", value: "VCT", },
    { label: "Santa Elena", value: "SHN", },
    { label: "Santa Lucía", value: "LCA", },
    { label: "Santo Tomé y Príncipe", value: "STP", },
    { label: "Senegal", value: "SEN", },
    { label: "Serbia", value: "SRB", },
    { label: "Seychelles", value: "SYC", },
    { label: "Sierra Leona", value: "SLE", },
    { label: "Singapur", value: "SGP", },
    { label: "Sint Maarten", value: "SMX", },
    { label: "Siria", value: "SYR", },
    { label: "Somalia", value: "SOM", },
    { label: "Sri lanka", value: "LKA", },
    { label: "Sudáfrica", value: "ZAF", },
    { label: "Sudán", value: "SDN", },
    { label: "Suecia", value: "SWE", },
    { label: "Suiza", value: "CHE", },
    { label: "Surinám", value: "SUR", },
    { label: "Svalbard y Jan Mayen", value: "SJM", },
    { label: "Swazilandia", value: "SWZ", },
    { label: "Tayikistán", value: "TJK", },
    { label: "Tailandia", value: "THA", },
    { label: "Taiwán", value: "TWN", },
    { label: "Tanzania", value: "TZA", },
    { label: "Territorio Británico del Océano Índico", value: "IOT", },
    { label: "Territorios Australes y Antárticas Franceses", value: "ATF", },
    { label: "Timor Oriental", value: "TLS", },
    { label: "Togo", value: "TGO", },
    { label: "Tokelau", value: "TKL", },
    { label: "Tonga", value: "TON", },
    { label: "Trinidad y Tobago", value: "TTO", },
    { label: "Tunez", value: "TUN", },
    { label: "Turkmenistán", value: "TKM", },
    { label: "Turquía", value: "TUR", },
    { label: "Tuvalu", value: "TUV", },
    { label: "Ucrania", value: "UKR", },
    { label: "Uganda", value: "UGA", },
    { label: "Uruguay", value: "URY", },
    { label: "Uzbekistán", value: "UZB", },
    { label: "Vanuatu", value: "VUT", },
    { label: "Venezuela", value: "VEN", },
    { label: "Vietnam", value: "VNM", },
    { label: "Wallis y Futuna", value: "WLF", },
    { label: "Yemen", value: "YEM", },
    { label: "Yibuti", value: "DJI", },
    { label: "Zambia", value: "ZMB", },
    {
        label: "Zimbabue", value: "ZWE",

    }
];

export default countries;