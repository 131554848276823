import React from "react";

import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Col,
    Nav,
    NavItem,
    Row,
} from "reactstrap";

import { useState } from "react";
import FormDistribution from "./Distribution";
import axios from "axios";
import externalUrl from "config/externalUrl";

function FormOwnershipDistribution(props) {
    const [toggle, setToggle] = useState(false);
    const [active, setActive] = useState(52);
    const [error, setError] = useState({ 1: false, 2: false, 3: false, 4: false, 7: false, 'null': false });
    // 1. Capital 2. Interés 3. Mora 4. Multa 5. Cargos Administrativos 6. Adelanto 7. IVA 8. Seguro C/A

    const onCheckboxBtnClick = (selected) => {
        const index = props.cSelected.indexOf(selected);
        if (index < 0) {
            /* if (selected == 2) {
                const c = props.cSelected.indexOf(1);
                if (c < 0) {
                    props.notify("br", "Para retener los servicios de Alapar tienes que seleccionar primero el ISR.", "warning");
                    return;
                }
            } */
            props.cSelected.push(selected);
        } else {
            /* if (selected == 1) {
                props.setCSelected([]);
                return;
            } */
            props.cSelected.splice(index, 1);
        }
        props.setCSelected([...props.cSelected]);
    };

    if (props.distribution.length === 0) {
        return null;
    }

    const changeActive = (e) => {
        setActive(e);
        try {
            document.getElementById('distCapital').value = '';
        } catch (error) { }
        document.getElementById('distMora').value = '';
        document.getElementById('distMulta').value = '';
        document.getElementById('distIva').value = '';
        document.getElementById('distIntereses').value = '';
        document.getElementById('distGeneral').value = '';
    };

    const changeGlobalDistribution = () => {
        const newDistribution = JSON.parse(JSON.stringify(props.distribution));
        let lender = props.lenders.filter(option => parseInt(option.value) === active)[0]?.value;
        let per = newDistribution[props.lenders.filter(option => parseInt(option.value) === active)[0]?.value]['null']['percentage'];
        if (parseInt(lender) !== 52) {
            newDistribution[props.lenders.filter(option => parseInt(option.value) === active)[0]?.value][1] = { ...newDistribution[props.lenders.filter(option => parseInt(option.value) === active)[0]?.value][1], percentage: per };
        }
        newDistribution[props.lenders.filter(option => parseInt(option.value) === active)[0]?.value]['null'] = { ...newDistribution[props.lenders.filter(option => parseInt(option.value) === active)[0]?.value]['null'], percentage: per };
        newDistribution[props.lenders.filter(option => parseInt(option.value) === active)[0]?.value][2] = { ...newDistribution[props.lenders.filter(option => parseInt(option.value) === active)[0]?.value][2], percentage: per };
        newDistribution[props.lenders.filter(option => parseInt(option.value) === active)[0]?.value][3] = { ...newDistribution[props.lenders.filter(option => parseInt(option.value) === active)[0]?.value][3], percentage: per };
        newDistribution[props.lenders.filter(option => parseInt(option.value) === active)[0]?.value][4] = { ...newDistribution[props.lenders.filter(option => parseInt(option.value) === active)[0]?.value][4], percentage: per };
        newDistribution[props.lenders.filter(option => parseInt(option.value) === active)[0]?.value][7] = { ...newDistribution[props.lenders.filter(option => parseInt(option.value) === active)[0]?.value][7], percentage: per };

        props.setDistribution(newDistribution);
        setToggle(false);
    };

    const saveDistribution = () => {
        let a = 0;
        let b = 0;
        let c = 0;
        let d = 0;
        let e = 0;
        let f = 0;
        Object.keys(props.distribution).map((dist, i) => { // eslint-disable-line
            if (dist !== '52') {
                a += parseFloat(props.distribution[dist][1]['percentage']);
                b += parseFloat(props.distribution[dist][2]['percentage']);
                c += parseFloat(props.distribution[dist][3]['percentage']);
                d += parseFloat(props.distribution[dist][4]['percentage']);
                e += parseFloat(props.distribution[dist][7]['percentage']);
                f += parseFloat(props.distribution[dist]['null']['percentage']);
            }
        });

        if (a !== 100 || b !== 100 || c !== 100 || d !== 100 || e !== 100 || f !== 100) {
            props.notify("br", "Todos los porcentajes tienen que sumar 100%.", "danger");
            setError({ 1: a !== 100, 2: b !== 100, 3: c !== 100, 4: d !== 100, 7: e !== 100, 'null': f !== 100 });
            return;
        }

        const body = {
            distribution: props.distribution,
            billingType: props.billingType,
            isr: props.cSelected.indexOf(1) < 0 ? 0 : 1,
            alapar: props.cSelected.indexOf(2) < 0 ? 0 : 1,
            fIva: props.cSelected.indexOf(3) < 0 ? 0 : 1,
        }

        axios.post(`${externalUrl.lenderUrl}/v1/cedula-ownership/save-distribution`, body, {
            headers: { Authorization: `Bearer ${props.userData.private_token}`, },
        }).then(function (response) {
            props.notify("br", "Repartición actualizada correctamente.", "success");
            setError({ 1: false, 2: false, 3: false, 4: false, 7: false, 'null': false });
            props.toggle();
            props.changeCedula(props.selectedCedula.code);
        }).catch(function () {
            /* localStorage.removeItem('userData');
            window.location.replace(`${externalUrl.loanUrl}/v1/auth/logout`); */
        });
    };


    return (
        <>
            <Modal isOpen={props.modalDistribution.view}
                size={'lg'}
                style={{ top: '5vh' }}>
                <ModalHeader style={{ userSelect: 'none' }}>
                    Distribución pago
                </ModalHeader>
                <ModalBody style={{ paddingBottom: '0.3rem' }}>
                    <Row>
                        <Col md={4} style={{ borderRight: '0.5px solid black' }}>
                            <Nav vertical>
                                <NavItem style={{ borderBottom: '0.5px solid black' }} onClick={() => changeActive(52)}>
                                    <Button className='btn' color={active === 52 ? '' : 'link'} style={{ textAlign: 'left', color: active === 52 ? 'white' : '#51cbce', width: '100%' }}>
                                        ALAPAR
                                    </Button>
                                </NavItem>
                                {
                                    props.cedulaOwnership.map((ownership, i) => (
                                        ownership.lender === '' ? <NavItem key={i}>No has seleccionado ninguna cédula</NavItem> :
                                            <NavItem key={i} onClick={() => changeActive(parseInt(ownership.lender))}>
                                                <Button className='btn' color={active === parseInt(ownership.lender) ? '' : 'link'} style={{ textAlign: 'left', color: active === parseInt(ownership.lender) === 52 ? 'white' : '#51cbce', width: '100%' }}>
                                                    {props.lenders.filter(option =>
                                                        option.value === ownership.lender)[0]?.label}
                                                </Button>
                                            </NavItem>
                                    ))
                                }
                            </Nav>
                        </Col>
                        <Col md={8}>
                            {
                                <FormDistribution
                                    lender={props.lenders.filter(option => parseInt(option.value) === active)[0]}
                                    type={active === 52 ? 1 : 2}
                                    active={active}
                                    distribution={props.distribution}
                                    setDistribution={props.setDistribution}
                                    error={error}
                                    billingType={props.billingType}
                                    setBillingType={props.setBillingType}
                                    changeGlobalDistribution={changeGlobalDistribution}
                                    onCheckboxBtnClick={onCheckboxBtnClick}
                                    cSelected={props.cSelected}
                                />
                            }
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter style={{ paddingTop: '0.3rem', paddingBottom: '0.3rem' }}>
                    <Button
                        className={"btn button-alapar-success button-left"}
                        onClick={saveDistribution}
                    >
                        Guardar
                    </Button>
                    {' '}
                    <Button
                        className="button-alapar-cancel button-right"
                        outline
                        onClick={props.toggle}
                        color="primary">
                        Cancelar
                    </Button>
                </ModalFooter>
            </Modal>

            <Modal isOpen={toggle}
                style={{ top: '5vh' }}>
                <ModalHeader style={{ userSelect: 'none' }}>
                    ¿Quieres continuar?
                </ModalHeader>
                <ModalBody style={{ paddingBottom: '0.3rem' }}>
                    <Row>
                        <Col className="pr-10">
                            Si cambias el valor del porcentaje de distribución del lender general, se cambiaran también los porcentajes del detalle de distribución para este lender.
                            <br />
                            ¿Quieres continuar?
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter style={{ paddingTop: '0.3rem', paddingBottom: '0.3rem' }}>
                    <Button
                        className={"btn button-alapar-success button-left"}
                        onClick={changeGlobalDistribution}
                    >
                        Continuar
                    </Button>
                    {' '}
                    <Button
                        className="button-alapar-cancel button-right"
                        outline
                        onClick={() => (setToggle(false))}
                        color="primary">
                        Cancelar
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
}

export default FormOwnershipDistribution;
