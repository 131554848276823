import React from "react";

import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  FormGroup,
  Input,
  Button,
} from "reactstrap";

import Select from 'react-select'
import countries from "data/countries";
import { Link } from "react-router-dom";

function FormPersonLegal(props) {
  return (
    <>
      <Card className="card-user">
        <CardHeader>
          <CardTitle tag="h5">Persona legal</CardTitle>
        </CardHeader>
        <CardBody>
          <Row>
            <Col className="pr-10" md="6">
              <FormGroup>
                <label>Nombre *</label>
                <Input
                  placeholder={props.personLegal.display_name === '' ? 'Nombre empresa' : props.personLegal.display_name}
                  type="text"
                  name='display_name'
                  onChange={(e) => { props.changeValue(e, props.setPersonLegal, props.personLegal); props.setDisplayError(false) }}
                  invalid={props.displayError}
                  autoComplete="off"
                />
              </FormGroup>
            </Col>
            <Col md="3">
              <FormGroup>
                <label>País principal</label>
                <Select
                  options={countries}
                  placeholder={props.personLegal.main_country === '' ? 'Seleccione...' :
                    countries.filter(option =>
                      option.value === props.personLegal.main_country)[0]?.label}
                  noOptionsMessage={({ inputValue }) => !inputValue ? "Cargando..." : "Sin resultados"}
                  onChange={(e) => { props.changeValueSelect(e, props.setPersonLegal, props.personLegal, 'main_country'); }}
                  className="create-select"
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <FormGroup>
                <label>Descripción</label>
                <Input
                  type="textarea"
                  defaultValue={props.personLegal.description}
                  name='description'
                  onChange={(e) => props.changeValue(e, props.setPersonLegal, props.personLegal)}
                  autoComplete="off"
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <div className="update ml-auto mr-auto" style={{ display: 'flex' }}>
              <Button
                className="btn button-alapar-success"
                color="primary"
                type="submit"
                onClick={props.create}
              >
                {props.buttonText}
              </Button>
              <Link to={'/admin/lender'}>
                <Button
                  className="button-alapar-cancel"
                  outline
                  color="primary"
                >
                  Cancelar
                </Button>
              </Link>
            </div>
          </Row>
        </CardBody>
      </Card>
    </>
  );
}

export default FormPersonLegal;
