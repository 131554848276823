import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Table,
    Row,
    Col,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Label,
} from "reactstrap";
import NotificationAlert from "react-notification-alert";

import { BsFillTrashFill } from "react-icons/bs";
import { BsFillPencilFill } from "react-icons/bs";
import externalUrl from "config/externalUrl";
import axios from "axios";

function LenderTypeList() {
    const notificationAlert = React.useRef();
    const [modal, setModal] = useState(false);
    const [userData] = useState(JSON.parse(localStorage.getItem('userData')));
    const [lenderType, setLenderType] = useState([]);
    const [selected, setSelected] = useState({});

    useEffect(() => {
        fetchData();
    }, []); // eslint-disable-line

    async function fetchData() {
        axios.get(`${externalUrl.lenderUrl}/v1/lender-type/index`, {
            headers: { Authorization: `Bearer ${userData.private_token}`, },
        }).then(function (response) {
            if (response.data.error === '1') {
                setLenderType([]);
            } else {
                setLenderType(response.data.msg);
            }
        }).catch(function (error) {
            if (error.response.status === 401) {
                localStorage.removeItem('userData');
                window.location.replace(`${externalUrl.lenderUrl}/v1/auth/logout`);
            } else {
                console.error(error);
            }
        });
    };

    const toggle = (e) => {
        if (e?.status === 3) {
            notify("br", 'Este tipo de lender ya se encuentra eliminado.', "info");
        } else {
            setModal(!modal);
        }
    }

    const deleteLender = () => {
        var to = '';
        if (selected.status === 1) {
            to = 'disable';
        } else if (selected.status === 2) {
            to = 'drop';
        } else {
            notify("br", "Este tipo de lender ya se encuentra eliminado.", "info");
            return;
        }

        axios.get(`${externalUrl.lenderUrl}/v1/lender-type/${to}?id=${selected.id}`, {
            headers: { Authorization: `Bearer ${userData.private_token}`, },
        }).then(function (response) {
            if (response.data.error === '1') {
                notify("br", response.data.msg, "danger");
                toggle(null);
            } else {
                notify("br", `El tipo de lender se ${selected.status === 1 ? ' deshabilitó' : '  eliminó'} correctamente.`, "info");
                fetchData();
                setSelected({});
                toggle(null);
            }
        }).catch(
            function (error) {
                // Arreglar este mensaje 
                notify("br", "Ocurrio un error en la base de datos", "danger");
                toggle(null);
            }
        );
    };

    const notify = (place, text, type) => {
        var icon = '';
        if (type === 'success') {
            icon = 'nc-check-2';
        } else if (type === 'info') {
            icon = 'nc-alert-circle-i';
        } else if (type === 'warning') {
            icon = 'nc-bell-55';
        } else if (type === 'danger') {
            icon = 'nc-simple-remove';
        }

        var options = {
            place: place,
            message: (
                <div>
                    <div>
                        {text}
                    </div>
                </div>
            ),
            type: type,
            icon: `nc-icon ${icon}`,
            autoDismiss: 7,
        };
        notificationAlert.current.notificationAlert(options);
    };

    return (
        <>
            <NotificationAlert ref={notificationAlert} />
            <div className="content">
                <Row>
                    <Col md="12">
                        <Card>
                            <CardHeader>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <CardTitle tag="h4">Tipos de lender</CardTitle>
                                    <div >
                                        <Link to={'create-lender-type'}>
                                            <Button
                                                className="btn button-alapar-success"
                                                color="primary"
                                                type="submit"
                                            >
                                                Crear
                                            </Button>
                                        </Link>
                                    </div>
                                </div>
                            </CardHeader>
                            <CardBody>

                                <Table >
                                    <thead className="text-primary">
                                        <tr>
                                            <th style={{ width: '2%' }} className="table-alapar-title-default">ID</th>
                                            <th className="table-alapar-title-default">Nombre</th>
                                            <th className="table-alapar-title-default">Descripción</th>
                                            <th style={{ width: '20%' }} className="table-alapar-title-default">Estado</th>
                                            <th style={{ width: '5%' }} className="table-alapar-title-default">Acciones</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            lenderType.length === 0 ? <tr>
                                                <td colSpan={5}>
                                                    <center>
                                                        <label style={{ fontSize: '1.2rem' }}>
                                                            No hay tipos de lenders creados
                                                        </label>
                                                    </center>
                                                </td>
                                            </tr> :
                                                lenderType.map((lender) => (
                                                    <tr key={lender.id}>
                                                        <td>
                                                            <Link to={{ pathname: `/admin/view-lender-type/${lender.id}` }}>
                                                                {lender.id}
                                                            </Link>
                                                        </td>
                                                        <td>
                                                            <Link to={{ pathname: `/admin/view-lender-type/${lender.id}` }}>
                                                                {lender.name}
                                                            </Link>
                                                        </td>
                                                        <td>{lender.description === '' ? <label>Sin descripción</label> : lender.description}</td>
                                                        <td>{lender.status === 1 ? 'Habilitado' : lender.status === 2 ? 'Deshabilitado' : <div style={{ color: 'red' }}>Eliminado</div>}</td>
                                                        <td className="text-center">
                                                            <Link to={{ pathname: '/admin/update-lender-type/' + lender.id, update: true }}><Button className='btn' color='link' style={{ color: '#51cbce' }}><BsFillPencilFill /></Button></Link>
                                                            <Button className='btn' color='link' onClick={() => { setSelected(lender); toggle(lender) }} style={{ color: '#51cbce' }}><BsFillTrashFill /></Button>
                                                        </td>
                                                    </tr>
                                                ))
                                        }
                                    </tbody>
                                </Table>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>

            <Modal isOpen={modal}
                toggle={() => toggle(null)}
                style={{ top: '5vh' }}>
                <ModalHeader toggle={() => toggle(null)}>
                    {selected.status === 1 ? 'Deshabilitar' : 'Eliminar'} tipo de lender
                </ModalHeader>
                <ModalBody style={{ paddingBottom: '0.3rem' }}>
                    <Label>
                        ¿Desea eliminar el tipo de lender con nombre <b>{selected.name}</b>?
                    </Label>
                </ModalBody>
                <ModalFooter style={{ paddingTop: '0.3rem', paddingBottom: '0.3rem' }}>
                    <Button
                        className={"btn " + (selected.status === 1 ? 'button-alapar-warning' : 'button-alapar-danger') + " button-left"}
                        onClick={deleteLender}
                    >
                        {selected.status === 1 ? 'Deshabilitar' : 'Eliminar'}
                    </Button>
                    {' '}
                    <Button
                        className="button-alapar-cancel button-right"
                        outline
                        color="primary"
                        onClick={() => toggle(null)}>
                        Cancelar
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
}



export default LenderTypeList;
