import React, { useState } from "react";

import {
    Button,
    Modal,
    ModalHeader,
    ModalFooter,
    ModalBody,
} from "reactstrap";
import externalUrl from "config/externalUrl";

import axios from "axios";

function ModalDeleteUser(props) {
    const { modal, toggle, notify, fetchData, data } = props;

    const [userData] = useState(JSON.parse(localStorage.getItem('userData')));

    const deleteUser = () => {
        const body = {
            uid: data.uid
        };

        axios.post(`${externalUrl.lenderUrl}/v1/lender-user/delete`,
            body,
            {
                headers: { Authorization: `Bearer ${userData.private_token}`, },
            }).then(function (response) {
                console.log(response.data);
                if (response.data.error === 1) {
                    notify("br", response.data.msg, "danger");
                } else {
                    notify("br", 'Usuario eliminado correctamente', "success");
                    fetchData();
                    toggle();
                }
            }).catch(function (err) { });
    }

    return (
        <Modal isOpen={modal} toggle={toggle} style={{ top: '5vh' }} >
            <ModalHeader toggle={toggle}>
                Crear usuario
            </ModalHeader>
            <ModalBody style={{ paddingBottom: '1rem' }}>
                ¿Quieres borrar el usuario con correo <b>{data.email_address}</b>?
            </ModalBody>
            <ModalFooter style={{ paddingTop: '0.3rem', paddingBottom: '0.3rem' }}>
                <Button
                    className="btn button-alapar-danger"
                    onClick={deleteUser}
                >
                    Eliminar
                </Button>
                {' '}
                <Button
                    className="button-alapar-cancel button-right"
                    outline
                    color="primary"
                    onClick={toggle}>
                    Cerrar
                </Button>
            </ModalFooter>
        </Modal >
    );
}

export default ModalDeleteUser;
