import React, { useEffect, useContext } from "react";

import {
    Row,
    Col,
    Button,
    ButtonGroup
} from "reactstrap";

import Select from 'react-select';
import { CedulaLenderContext } from "components/Context/CedulaLenderContext";

const billingType = [
    { value: 1, label: 'Lender factura todo' },
    { value: 2, label: 'Alapar factura su parte' },
    { value: 3, label: 'Alapar factura todo' },
];

function FormCedulaLender({ active, selectOptions, setSelectOptions, cedulaOptions, setCedulaOptions, selectedCedula, setSelectedCedula }) {
    const [id, cedulas] = useContext(CedulaLenderContext); // eslint-disable-line
 
    useEffect(() => {
        setCedulaOptions({ billing_type: 0, isr_retention: 0, alapar_service: 0, force_iva: 0 });
        setSelectedCedula(-1);
        setSelectOptions([])
        if (active !== -1)
            createCedulasForSelect();
    }, [active]); // eslint-disable-line

    const createCedulasForSelect = () => {
        const positions = cedulas[active].position.split(',');
        if (positions.length === 1) {
            const selectCedulas = positions.map((position, i) => ({ value: i, label: 'Cédula # ' + position }));
            setSelectOptions(selectCedulas);
            changeValue(0);
        } else {
            const selectCedulas = [{ value: -2, label: 'Aplicar a todas las cédulas del lender en este crédito' }].concat(positions.map((position, i) => ({ value: i, label: 'Cédula # ' + position })));
            setSelectOptions(selectCedulas);
        }

    }

    const changeValue = (e) => {
        if (e === -2) {
            setCedulaOptions({ billing_type: 0, isr_retention: 0, alapar_service: 0, force_iva: 0 });
        } else {
            const options = {
                billing_type: cedulas[active].billing_type.split(',')[e],
                isr_retention: cedulas[active].isr_retention.split(',')[e],
                alapar_service: cedulas[active].alapar_service.split(',')[e],
                force_iva: cedulas[active].force_iva.split(',')[e]
            };
            setCedulaOptions(options);
        }
        setSelectedCedula(e);
    };

    const changeOption = (e) => {
        switch (e) {
            case 'isr_retention':
                setCedulaOptions((prevOption) => ({ ...cedulaOptions, isr_retention: parseInt(prevOption.isr_retention) === 1 ? 0 : 1 }));
                break;
            case 'alapar_service':
                setCedulaOptions((prevOption) => ({ ...cedulaOptions, alapar_service: parseInt(prevOption.alapar_service) === 1 ? 0 : 1 }));
                break;
            case 'force_iva':
                setCedulaOptions((prevOption) => ({ ...cedulaOptions, force_iva: parseInt(prevOption.force_iva) === 1 ? 0 : 1 }));
                break;
            default:
                setCedulaOptions({ ...cedulaOptions, billing_type: e });
                break;
        }
    };

    return (
        <>
            <h5>
                {active === -1 ? 'Aplicar a todos' : cedulas[active].loan + ' - ' + cedulas[active].loan_name}
            </h5>

            {
                active === -1 ? null :
                    <Select
                        key={'select-cedula-' + selectedCedula}
                        options={selectOptions}
                        placeholder={selectedCedula === -1 ? 'Seleccione...' : selectedCedula === -2 ? selectOptions[0].label : selectOptions.length === 1 ? selectOptions[0].label : selectOptions[selectedCedula + 1].label}
                        noOptionsMessage={({ inputValue }) => !inputValue ? "Cargando..." : "Sin resultados"}
                        onChange={(e) => changeValue(e.value)}
                        className="create-select"
                        menuPortalTarget={document.body}
                        styles={{ textOverflow: 'ellipsis', menuPortal: base => ({ ...base, zIndex: 9999 }) }}

                    />
            }
            <br />
            {
                selectedCedula === -1 && active !== -1 ? null :
                    <>
                        <Row style={{ marginLeft: '1rem', marginBottom: '1rem' }}>
                            <Col md="10">
                                <div style={{ width: '100%' }}>
                                    <Row>
                                        <div style={{ marginBottom: '0.4rem' }}>
                                            Tipo facturación
                                        </div>
                                        <Col md={12}>
                                            <Select
                                                key={'select-value-' + selectedCedula}
                                                options={billingType}
                                                placeholder={active === -1 || selectedCedula === -2 ? 'Seleccione...' :
                                                    billingType.filter(option =>
                                                        parseInt(option.value) === parseInt(cedulaOptions.billing_type))[0]?.label}
                                                noOptionsMessage={({ inputValue }) => !inputValue ? "Cargando..." : "Sin resultados"}
                                                onChange={(e) => changeOption(e.value)}
                                                className="create-select"
                                                menuPortalTarget={document.body}
                                                styles={{ textOverflow: 'ellipsis', menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                            />
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                        <Row style={{ marginLeft: '1rem' }}>
                            <Col md="10">
                                <div style={{ width: '100%' }}>
                                    <Row>
                                        Retención
                                        <Col md={12}>
                                            <ButtonGroup>
                                                <Button
                                                    style={{ padding: 5, minWidth: '4rem', marginRight: 5 }}
                                                    color="primary"
                                                    outline
                                                    onClick={(e) => { changeOption('isr_retention'); e.target.blur() }}
                                                    active={parseInt(cedulaOptions.isr_retention) === 1}
                                                >
                                                    ISR Lender
                                                </Button>
                                                <Button
                                                    style={{ padding: 5, minWidth: '4rem', marginRight: 5 }}
                                                    color="primary"
                                                    outline
                                                    onClick={(e) => { changeOption('alapar_service'); e.target.blur() }}
                                                    active={parseInt(cedulaOptions.alapar_service) === 1}
                                                >
                                                    Alapar Servicios
                                                </Button>
                                                <Button
                                                    style={{ padding: 5, minWidth: '4rem' }}
                                                    color="primary"
                                                    outline
                                                    onClick={(e) => { changeOption('force_iva'); e.target.blur() }}
                                                    active={parseInt(cedulaOptions.force_iva) === 1}
                                                >
                                                    Forzar IVA
                                                </Button>
                                            </ButtonGroup>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </>
            }
        </>
    );
}

export default FormCedulaLender;