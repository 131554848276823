import React, { useEffect, useState } from "react";

import {
    Row,
    Col,
    Input,
    Button,
    Label,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from "reactstrap";

import axios from "axios";
import externalUrl from "config/externalUrl";
import { Redirect } from "react-router-dom";

function FormLoan(props) {
    const [userData] = useState(JSON.parse(localStorage.getItem('userData')));
    const [loan, setLoan] = useState({ loan_id: '', loan_id_exp: '', name: '', notes: '', authorized_amt: '', currency: '', total_payments: '', low_rate: '', regular_rate: '', montly_late_pmt_rate: '', after90_late_pmt_rate: '', start_date: '', grace_period: '', starting_pmt_amt: '', min_capital_pmt: '', penalty_fee: '', bad_check_fee: '', max_capital_wout_penalty: '', authorized_by: '', authorization_date: '', created_by: '', status: '', type: '', last_update: '', deptors: '', loan_parent: '', pre_status: '' });
    const [loanError, setLoanError] = useState({ msg: '', error: false });
    const [loading, setLoading] = useState(false);

    const [redirect, setRedirect] = useState({ status: false, to: '/admin/Dashboard' });

    const [options/* , setOptions */] = useState({});

    const [id, setId] = useState(0);
    const [showData, setShowData] = useState(false);

    useEffect(() => {
        /* if (props.location.update || id != null) {
            fetchData();
        }
        getLendersType(); */
    }, []);

    async function fetchData() {
        axios.get(`${externalUrl.lenderUrl}/v1/api/get-credito-cedulizado?id=${id}`, {
            headers: { Authorization: `Bearer ${userData.private_token}`, },
        }).then(function (response) {
            if (response.data.error) {
                console.error(response.data.msg);
                setLoanError({ msg: response.data.msg, error: true });
            } else {
                setLoanError({ msg: '', error: false });
                setLoan({ ...response.data.msg, start_date: response.data.start_date });
            }
            setShowData(true);
        }).catch(
            function () {
            }
        );
    }

    const create = () => {
        if (id === '') {
            return;
        }
        if (loading) {
            return;
        } else {
            setLoading(true);
        }

        let body = {};
        axios.get(`${externalUrl.lenderUrl}/v1/api/get-credito-cedulizado?id=${id}`, {
            headers: { Authorization: `Bearer ${userData.private_token}`, },
        }).then(function (response) {
            if (response.data.error) { }
            else {
                body = { ...response.data.msg, start_date: response.data.start_date };
                var url = externalUrl.lenderUrl + '/v1/loan/add';
                axios.post(url, body, {
                    headers: { Authorization: `Bearer ${userData.private_token}`, },
                }).then(function (response) {
                    if (!response.data.error) {
                        const cedulaBody = {
                            loan: response.data.loan.loan_id,
                            created_by: userData.display_name,
                            id: loan.credito_cedulizado_id
                        };
                        axios.post(`${externalUrl.lenderUrl}/v1/cedula/add`, cedulaBody, {
                            headers: { Authorization: `Bearer ${userData.private_token}`, },
                        }).then(function (res) {
                            if (res.data.error) {
                                setLoanError({ msg: res.data.msg, error: false });
                            } else {
                                setRedirect({ to: '/admin/view-loan/' + response.data.loan.loan_id, status: true });
                            }
                            setLoading(false);
                        }).catch(
                            function (error) {
                                setLoading(false);
                                console.error(error);
                            }
                        );
                    } else {
                        setLoanError({ msg: response.data.msg, error: true });
                        setLoading(false);
                    }
                }).catch(
                    function (error) {
                        setLoading(false);
                        console.error(error);
                    }
                );
            }
            setShowData(true);
            setLoading(false);
        }).catch(function () {
            setLoading(false);
        });
    };

    /* const update = () => {
        var body = {};
        var url = '';
    
        axios.post(url, body, {
            headers: { Authorization: `Bearer ${userData.private_token}`, },
        }).then(function (response) {
            if (response.data.error === '0') {
                const lenderBody = {};
                axios.post(`${externalUrl.lenderUrl}/v1/lender/modify`, lenderBody, {
                    headers: { Authorization: `Bearer ${userData.private_token}`, },
                }).then(function (res) {
                    notify("br", "Creado correctamente.", "success");
                    setRedirect({ to: '/admin/view-lender/' + res.data.msg.id, status: true });
                }).catch(
                    function (error) {
                        notify("br", "No se ha podido crear.", "danger");
                    }
                );
            } else {
                notify("br", "No se ha podido crear. Hubo un error en la base de datos.", "danger");
            }
        }).catch(
            function (error) {
                console.error(error);
                notify("br", "No se ha podido crear.", "danger");
            }
        );
    }; */

    /* const notify = (place, text, type) => {
        var options = {
            place: place,
            message: (
                <div>
                    <div>
                        {text}
                    </div>
                </div>
            ),
            type: type,
            icon: "nc-icon nc-simple-remove",
            autoDismiss: 7,
        };
        notificationAlert.current.notificationAlert(options);
    }; */

    const toggle = () => {
        props.setShow(!props.show);
    }

    return (
        <>
            <Modal isOpen={props.show}
                toggle={toggle}
                style={{ top: '5vh' }}>
                <ModalHeader style={{ justifyContent: 'center !important' }}>
                    Importar cédulas de un crédito
                </ModalHeader>
                <ModalBody style={{ paddingBottom: '0.3rem' }}>
                    <Label>
                        Ingrese el número de expediente del crédito, esta información se obtendrá del sistema de créditos
                    </Label>
                    <Row style={{ marginBottom: '1rem' }}>
                        <Col md='4' />
                        <Col md='2.5'>
                            <Label style={{ marginTop: '0.2rem' }}>
                                No. Exp.:
                            </Label>
                        </Col>
                        <Col md='3'>
                            <Input
                                style={{ width: '5rem' }}
                                onChange={(e) => setId(e.target.value)}
                                onBlur={fetchData}
                                type='number' params
                                min='0' />
                        </Col>
                    </Row>
                    {showData ?
                        <>
                            <hr />
                            {loanError.error ? <center><Label>{loanError.msg}</Label></center> :
                                <>
                                    <Row>
                                        <Col md="2" />
                                        <Col md="3">
                                            <p className="p-title-view">
                                                Nombre:
                                            </p>
                                        </Col>
                                        <Col md="6">
                                            <label className="label-detail-view">
                                                {loan.name}
                                            </label>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="2" />
                                        <Col md="3">
                                            <p className="p-title-view">
                                                Notas:
                                            </p>
                                        </Col>
                                        <Col md="6">
                                            <label className="label-detail-view" style={{ paddingBottom: loan.notes === '' ? '0.9rem' : '' }}>
                                                {loan.notes}
                                            </label>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="2" />
                                        <Col md="3">
                                            <p className="p-title-view">
                                                Monto aut.:
                                            </p>
                                        </Col>
                                        <Col md="6">
                                            <label className="label-detail-view">
                                                {loan.authorized_amt}
                                            </label>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="2" />
                                        <Col md="3">
                                            <p className="p-title-view">
                                                Moneda:
                                            </p>
                                        </Col>
                                        <Col md="6">
                                            <label className="label-detail-view">
                                                {loan.currency}
                                            </label>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="2" />
                                        <Col md="3">
                                            <p className="p-title-view">
                                                No. Pagos:
                                            </p>
                                        </Col>
                                        <Col md="6">
                                            <label className="label-detail-view">
                                                {loan.total_payments}
                                            </label>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="2" />
                                        <Col md="3">
                                            <p className="p-title-view">
                                                Cédulas aut.:
                                            </p>
                                        </Col>
                                        <Col md="6">
                                            <label className="label-detail-view">
                                                {loan.numero_cedulas_autorizadas}
                                            </label>
                                        </Col>
                                    </Row>
                                </>
                            }
                        </>
                        : null}

                </ModalBody>
                <ModalFooter style={{ paddingTop: '0.3rem', paddingBottom: '0.3rem' }}>
                    <Button
                        className={"btn button-alapar-success button-left"}
                        onClick={create}
                    >
                        Importar
                    </Button>
                    {' '}
                    <Button
                        className="button-alapar-cancel button-right"
                        outline
                        color="primary"
                        onClick={toggle}>
                        Cancelar
                    </Button>
                </ModalFooter>
            </Modal>
            {redirect.status ? <Redirect to={{ pathname: redirect.to, state: { options: options } }} /> : null}
        </>
    );
}

export default FormLoan;
