import { React, useState } from "react";

import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  FormGroup,
  Input,
  Button
} from "reactstrap";

import Select from 'react-select';
import FormOwnershipDistribution from "./OwnershipDistribution";

function FormCedula(props) {
  const [modalDistribution, setModalDistribution] = useState({ view: false, title: '', msg: '', type: 'success' });

  const setCedula = (e) => {
    props.setCedula(props.selectedCedula.hasOwnProperty('code'), e.value);
  }

  const toggle = () => {
    if (props.updatedValues) {
      props.notify("br", "Primero guarde los cambios de los endosos.", "warning");
    } else {
      setModalDistribution({ ...modalDistribution, view: !modalDistribution.view });
    }
  }

  const changeValue = (e) => {
    if (props.selectedCedula.hasOwnProperty('code')) {
      if (e.target.name === 'canceled_on') {
        props.setSelectedCedula({ ...props.selectedCedula, [e.target.name]: e.target.value, canceled_by: props.userData.display_name });
      } else {
        props.setSelectedCedula({ ...props.selectedCedula, [e.target.name]: e.target.value });
      }
    } else {
      // Mostrar mensaje de error
    }
  }

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card className="card-user">
              <CardHeader>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <CardTitle tag="h5">Datos cédula</CardTitle>
                  {
                    !props.selectedCedula.hasOwnProperty('id') ? <></> :
                      <Button
                        className="button-alapar-cancel"
                        outline
                        color="primary"
                        onClick={toggle}
                      >
                        Detalles distribución
                      </Button>
                  }
                </div>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col className="pr-10" md="1" />
                  <Col className="pr-10" md="10">
                    <FormGroup>
                      <label>Cédula</label>
                      <Select
                        options={props.cedulas}
                        placeholder={'Seleccione...'}
                        value={!props.selectedCedula.hasOwnProperty('code') ? '' : { value: props.selectedCedula.code, label: 'Cédula # ' + props.selectedCedula.position }}
                        noOptionsMessage={({ inputValue }) => !inputValue ? "Cargando..." : "Sin resultados"}
                        onChange={(e) => setCedula(e)}
                        className="create-select"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col className="pr-10" md="1" />
                  <Col className="pr-10" md="10">
                    <FormGroup>
                      <label>Desembolso</label>
                      <Input
                        value={!props.selectedCedula.hasOwnProperty('valid_since') ? '' : props.selectedCedula?.valid_since?.split(' ')[0]}
                        type="date"
                        name='valid_since'
                        onChange={(e) => changeValue(e)}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col className="pr-10" md="1" />
                  <Col className="pr-10" md="10">
                    <FormGroup>
                      <label>Cancelación</label>
                      <Input
                        value={!props.selectedCedula.hasOwnProperty('canceled_on') ? '' : props.selectedCedula?.canceled_on?.split(' ')[0]}
                        type="date"
                        name='canceled_on'
                        onChange={(e) => changeValue(e)}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col className="pr-10" md="1" />
                  <Col className="pr-10" md="10">
                    <Row>
                      <Col className="col-6" md="6">
                        <p>Código: </p>
                      </Col>
                      <Col className="col-6" md="6">
                        <p className="text-right">{props.selectedCedula?.code}</p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col className="pr-10" md="1" />
                  <Col className="pr-10" md="10">
                    <Row>
                      <Col className="col-6" md="6">
                        <p>Monto emitido: </p>
                      </Col>
                      <Col className="col-6" md="6">
                        <p className="text-right">{!props.selectedCedula.hasOwnProperty('auth_amnt') ? null : props.loan?.currency === 'USD' ? '$ ' : 'Q '}{props.selectedCedula?.auth_amnt}</p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col className="pr-10" md="1" />
                  <Col className="pr-10" md="10">
                    <Row>
                      <Col className="col-6" md="6">
                        <p>Total crédito: </p>
                      </Col>
                      <Col className="col-6" md="6">
                        <p className="text-right">{
                          (new Intl.NumberFormat((props.loan?.currency === 'USD' ? 'en-US' : 'es-GT'), { style: 'currency', currency: props.loan?.currency === 'USD' ? 'USD' : 'GTQ', }).format(props.loan?.authorized_amt === null ? 0 : props.loan?.authorized_amt))}</p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col className="pr-10" md="1" />
                  <Col className="pr-10" md="10">
                    <Row>
                      <Col className="col-6" md="6">
                        <p>Moneda: </p>
                      </Col>
                      <Col className="col-6" md="6">
                        <p className="text-right">{props.loan?.currency}</p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col className="pr-10" md="1" />
                  <Col className="pr-10" md="10">
                    <Row>
                      <Col className="col-8" md="8">
                        <p>No. cédulas emitidas: </p>
                      </Col>
                      <Col className="col-4" md="4">
                        <p className="text-right">{props.cedulasData?.length/* props.loan?.numero_cedulas_autorizadas */}</p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col className="pr-10" md="1" />
                  <Col className="pr-10" md="10">
                    {
                      !props.selectedCedula.hasOwnProperty('id') ? <></> :
                        <Row>
                          <Col className="col-8" md="8">
                            <p>% Distribución ALAPAR: </p>
                          </Col>
                          <Col className="col-4 " md="4">
                            <Input
                              type="text"
                              name='owed_capital_at_sell'
                              className="float-right"
                              onChange={(e) => props.setAlaparGeneralDistribution(parseInt(e.target.value) > 100 ? 100 : e.target.value)}
                              style={{ height: '30px', maxWidth: '4.5rem', textAlign: 'right' }}
                              placeholder={props.distribution[52]?.['null']?.percentage}
                              autoComplete="off"
                              readOnly={true}
                            />
                          </Col>
                        </Row>
                    }
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
      <FormOwnershipDistribution
        lenders={props.lenders}
        cedulaOwnership={props.cedulaOwnership}
        toggle={toggle}
        modalDistribution={modalDistribution}
        setModalDistribution={setModalDistribution}
        distribution={props.distribution}
        userData={props.userData}
        setDistribution={props.setDistribution}
        notify={props.notify}
        updatedValues={props.updatedValues}
        billingType={props.billingType}
        setBillingType={props.setBillingType}
        changeCedula={props.changeCedula}
        selectedCedula={props.selectedCedula}
        setCSelected={props.setCSelected}
        cSelected={props.cSelected}
      />
    </>
  );
}

export default FormCedula;