import React from "react";

import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  FormGroup,
  Collapse,
  Spinner,
} from "reactstrap";

import CedulaLender from "./CedulaLender";

const styleTitle = {
  fontSize: '1rem',
  margin: 0,
  fontWeight: 700,
  lineHeight: 1.42857143,
};

const styleLabel = {
  fontSize: '1rem',
  color: '#252422',
  lineHeight: 1.42857143,
};


function ViewCedulaOwnershipTerm(props) {
  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card className="card-user">
              {
                props.loading ?
                  <center>
                    <Spinner style={{ marginTop: '1.5rem', marginBottom: '1.5rem' }}>
                      Cargando...
                    </Spinner>
                  </center>
                  :
                  !props.selectedCedula.hasOwnProperty('code') ?
                    <CardBody style={{ minHeight: 0 }}>
                      <center>
                        <CardTitle style={{ marginTop: '1.5rem' }}>
                          Selecciona una cédula
                        </CardTitle>
                      </center>
                    </CardBody>
                    :
                    props.cedulaOwnership.length === 0 ?
                      <CardBody style={{ minHeight: 0 }}>
                        <center>
                          <CardTitle style={{ marginTop: '1.5rem' }}>
                            Esta cédula aún no posee endosos registrados.
                          </CardTitle>
                        </center>
                      </CardBody>
                      :
                      <>
                        <CardHeader>
                          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <CardTitle tag="h5">Registro de desembolsos</CardTitle>
                          </div>
                        </CardHeader>
                        <CardBody style={{ minHeight: 0 }}>
                          {
                            props.cedulaOwnership.map((terms, index) => (
                              <Collapse key={"term-" + index} isOpen={terms.view} >
                                {index === 0 ? null : <hr />}
                                <Row style={{ marginLeft: '1rem' }}>
                                  <div style={{ minWidth: '9.6rem', width: 'calc(10rem + 2rem)' }}>
                                    <CedulaLender setCedulaOwnership={props.setCedulaOwnership} cedulaOwnership={props.cedulaOwnership} lenders={props.lenders} lendersCedula={terms.lenders} index={index} />
                                  </div>

                                  <div style={{ paddingLeft: '1rem' }}>
                                    <FormGroup>
                                      <p style={styleTitle}>Fecha de compra</p>
                                      <div style={{ float: 'right' }}>
                                        <label style={styleLabel}>{terms?.settled_on?.split(' ')[0]}</label>
                                      </div>
                                    </FormGroup>
                                  </div>

                                  <div style={{ paddingLeft: '0.6rem' }}>
                                    <FormGroup>
                                      <p style={styleTitle}>Capital a la fecha</p>
                                      <div style={{ float: 'right' }}>
                                        <label style={styleLabel}>{terms?.owed_capital_at_sell === '' || terms?.owed_capital_at_sell === null ? '(No definido)' : (props.loan?.currency === 'USD' ? "US$" : "Q") + terms?.owed_capital_at_sell}</label>
                                      </div>
                                    </FormGroup>
                                  </div>

                                  <div style={{ paddingLeft: '0.6rem' }}>
                                    <FormGroup>
                                      <p style={styleTitle}>Precio transacción</p>
                                      <div style={{ float: 'right' }}>
                                        <label style={styleLabel}>{terms?.owed_capital_at_sell === '' || terms?.owed_capital_at_sell === null ? '(No definido)' : (props.loan?.currency === 'USD' ? "US$" : "Q") + terms?.price}</label>
                                      </div>
                                    </FormGroup>
                                  </div>
                                </Row>
                              </Collapse>
                            ))
                          }

                        </CardBody>
                      </>
              }
            </Card>
          </Col>
        </Row>
      </div >
    </>
  );
}

export default ViewCedulaOwnershipTerm;
