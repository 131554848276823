function EmailCell(props) {
    const { value } = props;
    return (
        <a href={`mailto:${value}`}>
            {value}
        </a>
    );
};

export default EmailCell;
