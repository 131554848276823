import styles from './Cell.module.scss';

function StatusCell(props) {
    const { value } = props;

    switch (parseInt(value)) {
        case 1:
            return (
                <div>
                    Habilitado
                </div>
            );
        case 2:
            return (
                <div className={styles.disabled}>
                    Deshabilitado
                </div>
            );
        case 3:
            return (
                <div className={styles.erased}>
                    Eliminado
                </div>
            );
        default:
            return (
                <div className={styles.erased}>
                    Error
                </div>
            );
    }
};

export default StatusCell;
