import React, { useState } from "react";

import {
    Row,
    FormGroup,
    Input,
    Button,
    Collapse,
} from "reactstrap";

import Select from 'react-select';
import { BsPlusLg, BsXLg } from "react-icons/bs";
import { useEffect } from "react";

function CedulaLender(props) {
    const [lastShow, setLastShow] = useState(0);

    useEffect(() => {
        props.lendersCedula.map((lender, j) => {
            if (!lender.hasOwnProperty('view')) {
                const newLender = JSON.parse(JSON.stringify(props.cedulaOwnership));
                newLender[props.index].lenders[j] = { ...newLender[props.index].lenders[j], view: true };
                props.setCedulaOwnership(newLender);
                if (j === props.lendersCedula.length - 1) {
                    setLastShow(j);
                }
            } else {
                if (lender.view) {
                    setLastShow(j);
                }
            }
            return lender;
        });
    }, [props]);

    const changeValue = (e, i, j) => {
        const newLender = JSON.parse(JSON.stringify(props.cedulaOwnership));
        const point = e.target.value.includes('.') ? '.' : '';
        if (e.target.name === 'percentage') {
            e.target.value = e.target.value === '' ? 0 : e.target.value.includes('.') ? parseFloat(e.target.value + 0.0000) : parseInt(e.target.value);
            let lastIndex = 0;
            let generalPercentage = 0;
            let percentage100 = 0;

            newLender[i].lenders.forEach((val, index) => {
                if (val.view) {
                    lastIndex = index;
                    if (index !== j) {
                        percentage100 += parseFloat(val.percentage);
                        generalPercentage += parseFloat(val.percentage);
                    } else {
                        generalPercentage += parseFloat(e.target.value);
                    }
                }
            });

            generalPercentage -= parseFloat(newLender[i].lenders[lastIndex][e.target.name]);
            percentage100 -= parseFloat(newLender[i].lenders[lastIndex][e.target.name]);


            if (generalPercentage > 100) {
                e.target.value = 100 - percentage100;
            }

            let newPercentage = 0;
            newLender[i].lenders.forEach((val, index) => {
                if (index === j) {
                    if ((newPercentage + parseFloat(e.target.value)) > 100.0000) {
                        // Mostrar mensaje que se paso de 100
                        //badPercentage = true;
                        e.target.value = 100 - newPercentage;
                    }
                    newPercentage += parseFloat(e.target.value);
                } else if (index === lastIndex) {
                    newLender[i].lenders[index][e.target.name] = (100.0000 - newPercentage).toFixed(4);
                } else {
                    newPercentage += parseFloat(val.percentage);
                }
            });

            if (e.target.value < 0) {
                newLender[i].lenders[j][e.target.name] = 0;
            } else {
                newLender[i].lenders[j][e.target.name] = parseFloat(e.target.value) + (e.target.value.includes('.') ? '' : point);
            }
        } else {
            newLender[i].lenders[j][e.target.name] = e.target.value;
        }
        props.setCedulaOwnership(newLender);

        if (!props.updatedValues) {
            props.setUpdatedValues(true);
        }
    }

    return (
        <>
            {
                props.lendersCedula.map((lender, i) => (
                    <Collapse key={"lender-" + i} isOpen={lender.view === undefined ? false : lender.view}>
                        {
                            i === 0 ?
                                <Row style={{ minWidth: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: "flex-start", gap: '0.2rem', marginLeft: '0rem' }}>
                                    <div style={{ width: 'calc(100% - (4rem + 10.4rem))' }}>
                                        <label>Lender *</label>
                                    </div>
                                    <div>
                                        <label>% Pago</label>
                                    </div>
                                </Row>
                                : null
                        }
                        <FormGroup>
                            <Row style={{ display: 'flex', flexWrap: 'wrap', justifyContent: "flex-start", gap: '0.2rem', marginLeft: '0rem' }}>
                                {/* <div style={{ marginTop: '-0.8rem' }}>
                                    <Button className='btn' color='link' style={{ color: '#51cbce' }} onClick={() => props.addLenders(props.index)} ><BsPlusLg /></Button>
                                </div> */}
                                <div style={{ width: 'calc(100% - (4rem + 10.4rem))' }} >
                                    <Select
                                        options={props.lenders.filter(val => parseInt(val.value) !== 52)}
                                        placeholder={lender.lender === '' ? 'Seleccione...' :
                                            props.lenders.filter(option =>
                                                option.value === lender.lender)[0]?.label}
                                        noOptionsMessage={({ inputValue }) => !inputValue ? "Cargando..." : "Sin resultados"}
                                        name='lender'
                                        onChange={(e) => changeValue({ target: { value: e.value, name: 'lender' } }, props.index, i)}
                                        className="create-select lender-select"
                                        menuPortalTarget={document.body}
                                        styles={{ textOverflow: 'ellipsis', menuPortal: base => ({ ...base, zIndex: 9999 }), width: '5vw' }}
                                        isOptionDisabled={(option) => parseInt(option.value) === 52}
                                    />
                                </div>
                                <div>
                                    <Input
                                        type="text"
                                        name='percentage'
                                        title={lender.percentage}
                                        style={{ minWidth: '4.4rem', width: '4.4rem' }}
                                        readOnly={lastShow === i}
                                        onChange={(e) => changeValue(e, props.index, i)}
                                        value={lender.percentage === null ? '' : lender.percentage}
                                        autoComplete="off"
                                    />
                                </div>
                                <div style={{ marginTop: '-0.7rem', padding: 0 }}>
                                    <Button className='btn' color='link' style={{ color: '#51cbce' }} onClick={() => props.addLenders(props.index)} ><BsPlusLg /></Button>
                                    {
                                        i === 0 ? null : <Button className='btn' color='link' style={{ color: '#51cbce' }} onClick={() => props.removeLenders(false, props.index, i, lender?.id)}><BsXLg /></Button>
                                    }
                                </div>
                            </Row>
                        </FormGroup>
                    </Collapse>
                ))
            }
        </>
    );
}

export default CedulaLender;