/*!

=========================================================
* Paper Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Dashboard from "views/Dashboard.js";
/* import Icons from "views/Icons.js"; */

import FormLenderType from "components/Create/Lender/LenderType";
import FormLender from "components/Create/Lender/Lender";
import FormBankAccount from "components/Create/Bank/BankAccount";
import FormBank from "components/Create/Bank/Bank";
import FormCedulaOwnership from "components/Create/CedulaOwnership/CedulaOwnership";

import BankList from "components/List/Bank";
import LenderList from "components/List/Lender";
import LenderTypeList from "components/List/LenderType";
import LoanList from "components/List/Loan";

import ViewLender from "components/View/Lender/Lender";
import ViewLenderType from "components/View/LenderType";
import ViewLoan from "components/View/Loan/Loan";
import UserList from "modules/user/views/UserList";

var routes = [
  {
    path: "/dashboard",
    name: "Inicio",
    icon: "nc-icon nc-layout-11",
    //component: Dashboard,
    component: Dashboard,
    layout: "/admin",
    show: true,
  },
  {
    path: "/lender",
    name: "Lenders",
    icon: "nc-icon nc-single-02",
    component: LenderList,
    layout: "/admin",
    show: true,
  },
  {
    path: "/loans",
    name: "Créditos",
    icon: "nc-icon nc-single-copy-04",
    component: LoanList,
    layout: "/admin",
    show: true,
  },
  {
    path: "/lenders-type",
    name: "Tipos de lenders",
    icon: "nc-icon nc-single-02",
    component: LenderTypeList,
    layout: "/admin",
    show: false,
  },
  {
    path: "/bank/create",
    name: "Crear bancos",
    icon: "nc-icon nc-bank",
    component: FormBank,
    layout: "/admin",
    show: false,
  },
  {
    path: "/create-lender-type",
    name: "Tipo de lender",
    icon: "nc-icon nc-bank",
    component: FormLenderType,
    layout: "/admin",
    show: false,
  },
  {
    path: "/bank/update/:id",
    name: "Actualizar bancos",
    icon: "nc-icon nc-bank",
    component: FormBank,
    layout: "/admin",
    show: false,
  },
  {
    path: "/create-lender",
    name: "Lender",
    icon: "nc-icon nc-bank",
    component: FormLender,
    layout: "/admin",
    show: false,
  },
  {
    path: "/create-bank-account",
    name: "Cuentas de banco",
    icon: "nc-icon nc-bank",
    component: FormBankAccount,
    layout: "/admin",
    show: false,
  },
  {
    path: "/create-cedula-ownership/:id",
    name: "Cédulas",
    icon: "nc-icon nc-bank",
    component: FormCedulaOwnership,
    layout: "/admin",
    show: false,
  },
  {
    path: "/update-lender/:id",
    name: "Actualizar lender",
    icon: "nc-icon nc-bank",
    component: FormLender,
    layout: "/admin",
    show: false,
  },
  {
    path: "/update-lender-type/:id",
    name: "Actualizar tipo de lender",
    icon: "nc-icon nc-bank",
    component: FormLenderType,
    layout: "/admin",
    show: false,
  },
  {
    path: "/view-lender/:id",
    name: "Lenders",
    icon: "nc-icon nc-bank",
    component: ViewLender,
    layout: "/admin",
    show: false,
  },
  {
    path: "/view-lender-type/:id",
    name: "Lenders",
    icon: "nc-icon nc-bank",
    component: ViewLenderType,
    layout: "/admin",
    show: false,
  },
  {
    path: "/view-loan/:id",
    name: "Loan",
    icon: "nc-icon nc-bank",
    component: ViewLoan,
    layout: "/admin",
    show: false,
  },
  {
    path: "/users/",
    name: "Loan",
    icon: "nc-icon nc-bank",
    component: UserList,
    layout: "/admin",
    show: false,
  },
  {
    path: "/bank/view",
    name: "Bancos",
    icon: "nc-icon nc-bank",
    component: BankList,
    layout: "/admin",
    show: true,
  },
  {
    path: "/bank",
    name: "Bancos",
    icon: "nc-icon nc-bank",
    component: BankList,
    layout: "/admin",
    show: false,
  },
  /*   {
    path: "/icons",
    name: "Icons",
    icon: "nc-icon nc-diamond",
    component: Icons,
    layout: "/admin",
    show: true
  }, */
  /* 
  {
    path: "/maps",
    name: "Maps",
    icon: "nc-icon nc-pin-3",
    component: Maps,
    layout: "/admin",
    show: true
  },
  {
    path: "/notifications",
    name: "Notifications",
    icon: "nc-icon nc-bell-55",
    component: Notifications,
    layout: "/admin",
    show: true
  },
  {
    path: "/user-page",
    name: "User Profile",
    icon: "nc-icon nc-single-02",
    component: UserPage,
    layout: "/admin",
    show: true
  },
  {
    path: "/tables",
    name: "Table List",
    icon: "nc-icon nc-tile-56",
    component: TableList,
    layout: "/admin",
  },
  {
    path: "/typography",
    name: "Typography",
    icon: "nc-icon nc-caps-small",
    component: Typography,
    layout: "/admin",
    show: true
  },
  {
    pro: true,
    path: "/upgrade",
    name: "Upgrade to PRO",
    icon: "nc-icon nc-spaceship",
    component: UpgradeToPro,
    layout: "/admin",
    show: true
  }, */
];
export default routes;
