import countries from "data/countries";
import React from "react";
import Skeleton from "react-loading-skeleton";

import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Form,
  FormGroup,
} from "reactstrap";

const styleTitle = {
  fontSize: '1.1rem',
  margin: 0
};

const styleLabel = {
  fontSize: '0.9rem'
};


function ViewPersonLegal(props) {
  return (
    <>
      <Card className="card-user">
        <CardHeader>
          <CardTitle tag="h5">Persona legal</CardTitle>
        </CardHeader>
        <CardBody style={{ minHeight: 0, paddingLeft: '2rem' }}>
          <Form>
            <Row>
              <Col className="pr-10" md="4">
                <FormGroup>
                  <p style={styleTitle}>Nombre</p>
                  {props.loading ? <Skeleton count={1} /> : <label style={styleLabel}>{props.personLegal.display_name}</label>}
                </FormGroup>
              </Col>
              <Col md="2">
                <FormGroup>
                  <p style={styleTitle}>País principal</p>
                  {props.loading ? <Skeleton count={1} /> : <label style={styleLabel}>
                    {countries.filter(option =>
                      option.value === props.personLegal.main_country)[0]?.label}
                  </label>}
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <p style={styleTitle}>Descripción</p>
                  {props.loading ? <Skeleton count={1} /> : <label style={styleLabel}>{props.personLegal.description}</label>}
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
    </>
  );
}

export default ViewPersonLegal;
