import React, { useEffect } from "react";

import {
  Row,
  Col,
} from "reactstrap";
import NotificationAlert from "react-notification-alert";

import { useParams } from "react-router";
import axios from "axios";
import { useState } from "react";
import externalUrl from "config/externalUrl";
import ViewCedulaOwnershipTerm from "./CedulaOwnershipTerm";
import ViewCedula from "./Cedula";

function ViewCedulaOwnership(props) {
  const notificationAlert = React.useRef();

  let { id } = useParams();
  const [loan, setLoan] = useState({});
  const [cedulas, setCedulas] = useState([]);
  const [cedulasData, setCedulasData] = useState([]);
  const [cedulaOwnership, setCedulaOwnership] = useState([{ lenders: [{ percentage: 100, lender: '', view: true }], settled_on: '', price: '', owed_capital_at_sell: '', view: true }]);
  const [selectedCedula, setSelectedCedula] = useState({});
  const [userData] = useState(JSON.parse(localStorage.getItem('userData')));
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState([]);

  useEffect(() => {
    fetchData();
  }, [props.cedulas]); // eslint-disable-line

  async function fetchData() {
    axios.get(`${externalUrl.lenderUrl}/v1/loan/search?id=${id}`, {
      headers: { Authorization: `Bearer ${userData.private_token}`, },
    }).then(function (response) {
      if (response.data.error) {
        setLoan({});
        setCedulas([]);
      } else {
        setLoan(response.data.msg);
        const data = response.data.cedulas.map((cedula => ({ value: cedula.code, label: "Cédula # " + cedula.position })));
        setCedulas(data);
        setCedulasData(response.data.cedulas);
      }
    }).catch(function (response) { console.error(response); });
  };

  const setCedula = (typeSet, value) => {
    changeCedula(value);
  };

  const changeCedula = (value) => {
    const cedula = cedulasData.find(val => (val.code === value));
    setSelectedCedula(cedula);
    setLoading(true);
    axios.get(`${externalUrl.lenderUrl}/v1/cedula-ownership/search?id=${cedula.id}`, {
      headers: { Authorization: `Bearer ${userData.private_token}`, },
    }).then(function (response) {
      if (response.data.error) {
        setCedulaOwnership([]);
      } else {
        setCedulaOwnership(response.data.msg);
      }
      setLoading(false);
    }).catch(function (response) { console.error(response); });
  }

  return (
    <>
      <NotificationAlert ref={notificationAlert} />
      <div className="content">
        <Row>
          <Col sm="8" md="6" lg="6" xl="4" className="left-padding">
            <ViewCedula setCedulaOwnership={setCedulaOwnership} setCedula={setCedula} userData={userData} setLoading={setLoading} loan={loan} setCedulas={setCedulas} cedulas={cedulas} cedulasData={cedulasData} selectedCedula={selectedCedula} setSelectedCedula={setSelectedCedula} />
          </Col>

          <Col md="12" lg="12" xl="8">
            <ViewCedulaOwnershipTerm loan={props.loan} loading={loading} error={error} setError={setError} cedulaOwnership={cedulaOwnership} setCedulaOwnership={setCedulaOwnership} lenders={props.lenders} selectedCedula={selectedCedula} />
          </Col>
        </Row>
      </div>
    </>
  );
}

export default ViewCedulaOwnership;
