import React from "react";

import {
    Row,
    Col,
    FormGroup,
    Input,
    Button,
    ButtonGroup
} from "reactstrap";

import Select from 'react-select';

const billingType = [
    { value: 1, label: 'Lender factura todo' },
    { value: 2, label: 'Alapar factura su parte' },
    { value: 3, label: 'Alapar factura todo' },
];

function FormDistribution(props) {

    const changeValue = (e, type) => {
        const newDistribution = JSON.parse(JSON.stringify(props.distribution));
        if (parseInt(e.target.value) > 100) {
            e.target.value = 100
        }
        newDistribution[props.lender.value][type] = { ...newDistribution[props.lender.value][type], percentage: e.target.value };
        props.setDistribution(newDistribution);
    };

    return (
        <>
            <h5>
                {props.lender.label}
            </h5>
            <Row>
                <Col className="pr-10" md="5">
                    <div style={{ display: 'flex' }}>
                        <label style={{ paddingRight: '1rem', width: '50%' }}>% Pago</label>
                        <Input
                            type="text"
                            id="distGeneral"
                            className={(props.error['null'] && props.lender.value !== 52) || (
                                (props.distribution[props.lender.value][1].percentage !== props.distribution[props.lender.value]['null'].percentage && props.lender.value !== 52) ||
                                (props.distribution[props.lender.value][2].percentage !== props.distribution[props.lender.value]['null'].percentage) ||
                                (props.distribution[props.lender.value][3].percentage !== props.distribution[props.lender.value]['null'].percentage) ||
                                (props.distribution[props.lender.value][4].percentage !== props.distribution[props.lender.value]['null'].percentage) ||
                                (props.distribution[props.lender.value][7].percentage !== props.distribution[props.lender.value]['null'].percentage)
                            ) ? "dist-error" : ""}
                            style={{ width: '70%', textAlign: 'right' }}
                            onChange={(e) => changeValue(e, 'null')}
                            placeholder={props.distribution[props.lender.value]['null'].percentage}
                            invalid={(props.error['null'] && props.lender.value !== 52) || (
                                (props.distribution[props.lender.value][1].percentage !== props.distribution[props.lender.value]['null'].percentage && props.lender.value !== 52) ||
                                (props.distribution[props.lender.value][2].percentage !== props.distribution[props.lender.value]['null'].percentage) ||
                                (props.distribution[props.lender.value][3].percentage !== props.distribution[props.lender.value]['null'].percentage) ||
                                (props.distribution[props.lender.value][4].percentage !== props.distribution[props.lender.value]['null'].percentage) ||
                                (props.distribution[props.lender.value][7].percentage !== props.distribution[props.lender.value]['null'].percentage)
                            )
                            }
                        />
                    </div>
                </Col>
                <Col className="pr-10" md="7">
                    <div style={{ marginTop: '-1rem' }}>
                        <Button onClick={props.changeGlobalDistribution}>Aplicar porcentaje</Button>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col className="pr-10" md="7">
                    {
                        props.type === 1 ?
                            <FormGroup>
                                <div style={{ width: '100%' }}>
                                    <Row>
                                        <label style={{ paddingLeft: '1rem' }}>Tipo facturación</label>
                                    </Row>
                                    <Row>
                                        <Col md={8}>
                                            <Select
                                                options={billingType}
                                                placeholder={/* props.billingType === '' ? 'Seleccione...' : */
                                                    billingType.filter(option =>
                                                        option.value === parseInt(props.billingType))[0]?.label}
                                                noOptionsMessage={({ inputValue }) => !inputValue ? "Cargando..." : "Sin resultados"}
                                                onChange={(e) => props.setBillingType(e.value)}
                                                className="create-select"
                                            />
                                        </Col>
                                    </Row>
                                </div>
                            </FormGroup>
                            : <></>
                    }
                </Col>
                <Col md="7" style={{ marginLeft: '-7rem' }}>
                    {
                        props.type === 1 ?
                            <div style={{ width: '100%' }}>
                                <Row>
                                    <Col md={12}>
                                        Retención
                                        <ButtonGroup>
                                            <Button
                                                style={{ padding: 5, minWidth: '4rem', marginRight: 5 }}
                                                color="primary"
                                                outline
                                                onClick={(e) => { props.onCheckboxBtnClick(1); e.target.blur() }}
                                                active={props.cSelected.includes(1)}
                                            >
                                                ISR Lender
                                            </Button>
                                            <Button
                                                style={{ padding: 5, minWidth: '4rem', marginRight: 5 }}
                                                color="primary"
                                                outline
                                                onClick={(e) => { props.onCheckboxBtnClick(2); e.target.blur() }}
                                                active={props.cSelected.includes(2)}
                                            >
                                                Alapar Servicios
                                            </Button>
                                            <Button
                                                style={{ padding: 5, minWidth: '4rem' }}
                                                color="primary"
                                                outline
                                                onClick={(e) => { props.onCheckboxBtnClick(3); e.target.blur() }}
                                                active={props.cSelected.includes(3)}
                                            >
                                                F. IVA
                                            </Button>
                                        </ButtonGroup>
                                    </Col>
                                </Row>
                            </div>
                            : <></>
                    }
                </Col>
            </Row>
            <hr />
            <>
                <Row>
                    <Col className="pr-10" md="6">
                        <div style={{ display: 'flex' }}>
                            <label style={{ paddingRight: '1rem', width: '50%' }}>Capital</label>
                            {
                                props.type === 1 ?
                                    <label style={{ width: '50%', textAlign: 'right' }}>0.0 %</label>
                                    :
                                    <Input
                                        type="text"
                                        id="distCapital"
                                        className={props.error[1] && props.lender.value !== 52 ? "dist-error" : ""}
                                        style={{ width: '50%', textAlign: 'right' }}
                                        onChange={(e) => changeValue(e, 1)}
                                        placeholder={props.distribution[props.lender.value][1].percentage}
                                        invalid={props.error[1] && props.lender.value !== 52}
                                    />
                            }
                        </div>
                    </Col>
                    <Col className="pr-10" md="6">
                        <div style={{ display: 'flex' }}>
                            <label style={{ paddingRight: '1rem', width: '50%' }}>Dif. Cambiario</label>
                            {
                                props.type === 1 ?
                                    <label style={{ width: '50%', textAlign: 'right' }}>100.0 %</label>
                                    :
                                    <label style={{ width: '50%', textAlign: 'right' }}>0.0 %</label>
                            }
                        </div>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col className="pr-10" md="6">
                        <div style={{ display: 'flex' }}>
                            <label style={{ paddingRight: '1rem', width: '50%' }}>Seguro C/A</label>
                            {
                                props.type === 1 ?
                                    <label style={{ width: '50%', textAlign: 'right' }}>100.0 %</label>
                                    :
                                    <label style={{ width: '50%', textAlign: 'right' }}>0.0 %</label>
                            }
                        </div>
                    </Col>
                    <Col className="pr-10" md="6">
                        <div style={{ display: 'flex' }}>
                            <label style={{ paddingRight: '1rem', width: '50%' }}>Mora</label>
                            <Input
                                type="text"
                                className={props.error[3] && props.lender.value !== 52 ? "dist-error" : ""}
                                style={{ width: '50%', textAlign: 'right' }}
                                onChange={(e) => changeValue(e, 3)}
                                placeholder={props.distribution[props.lender.value][3].percentage}
                                id="distMora"
                                invalid={props.error[3] && props.lender.value !== 52}
                            />
                        </div>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col className="pr-10" md="6">
                        <div style={{ display: 'flex' }}>
                            <label style={{ paddingRight: '1rem', width: '50%' }}>Intereses</label>
                            <Input
                                type="text"
                                className={props.error[2] && props.lender.value !== 52 ? "dist-error" : ""}
                                style={{ width: '50%', textAlign: 'right' }}
                                onChange={(e) => changeValue(e, 2)}
                                placeholder={props.distribution[props.lender.value][2].percentage}
                                id="distIntereses"
                                invalid={props.error[2] && props.lender.value !== 52}
                            />
                        </div>
                    </Col>
                    <Col className="pr-10" md="6">
                        <div style={{ display: 'flex' }}>
                            <label style={{ paddingRight: '1rem', width: '50%' }}>Multa</label>
                            <Input
                                type="text"
                                id="distMulta"
                                className={props.error[4] && props.lender.value !== 52 ? "dist-error" : ""}
                                style={{ width: '50%', textAlign: 'right' }}
                                onChange={(e) => changeValue(e, 4)}
                                placeholder={props.distribution[props.lender.value][4].percentage}
                                invalid={props.error[4] && props.lender.value !== 52}
                            />
                        </div>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col className="pr-10" md="6">
                        <div style={{ display: 'flex' }}>
                            <label style={{ paddingRight: '1rem', width: '50%' }}>IVA</label>
                            <Input
                                type="text"
                                className={props.error[7] && props.lender.value !== 52 ? "dist-error" : ""}
                                style={{ width: '50%', textAlign: 'right' }}
                                onChange={(e) => changeValue(e, 7)}
                                placeholder={props.distribution[props.lender.value][7].percentage}
                                id="distIva"
                                invalid={props.error[7] && props.lender.value !== 52}
                            />
                        </div>
                    </Col>
                    <Col className="pr-10" md="6"></Col>
                </Row>
                <br />
            </>
        </>
    );
}

export default FormDistribution;