import React from "react";

import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Button,
} from "reactstrap";

import Select from 'react-select';
import DatePicker from "reactstrap-date-picker/lib/DatePicker";

const options = [
  { value: 'chocolate', label: 'Chocolate' },
  { value: 'strawberry', label: 'Strawberry' },
  { value: 'vanilla', label: 'Vanilla' }
];

function FormBankAccount() {
  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card className="card-user">
              <CardHeader>
                <CardTitle tag="h5">Crear cuenta de banco</CardTitle>
              </CardHeader>
              <CardBody>
                <Form>
                  <Row>
                    <Col className="pr-10" lg="3" md="4">
                      <FormGroup>
                        <label>No. Cuenta de banco</label>
                        <Input
                          placeholder="No. Cuenta de banco"
                          type="text"
                        />
                      </FormGroup>
                    </Col>

                    <Col className="pr-10" lg="3" md="4">
                      <FormGroup>
                        <label>Banco</label>
                        <Select
                          options={options}
                          placeholder="Seleccione..."
                          noOptionsMessage={({ inputValue }) => !inputValue ? "Cargando..." : "Sin resultados"}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pr-10" lg="2" md="4">
                      <FormGroup>
                        <label>Tipo</label>
                        <Select
                          options={options}
                          placeholder="Seleccione..."
                          noOptionsMessage={({ inputValue }) => !inputValue ? "Cargando..." : "Sin resultados"}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-10" lg="2" md="3">
                      <FormGroup>
                        <label>IBAN</label>
                        <Input
                          placeholder="IBAN"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pr-10" lg="5" md="6">
                      <FormGroup>
                        <label>Nombre cuenta</label>
                        <Input
                          placeholder="Nombre de la cuenta"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pr-10" lg="2" md="3">
                      <FormGroup>
                        <label>Moneda</label>
                        <Select
                          options={options}
                          placeholder="Seleccione..."
                          noOptionsMessage={({ inputValue }) => !inputValue ? "Cargando..." : "Sin resultados"}
                        />
                      </FormGroup>
                    </Col>

                  </Row>
                  <Row>
                    <Col className="pr-10" lg="3">
                      <FormGroup>
                        <label>Firmante #1</label>
                        <Input
                          placeholder="Firmante #1"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pr-10" lg="3">
                      <FormGroup>
                        <label>Firmante #2</label>
                        <Input
                          placeholder="Firmante #2"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pr-10" lg="3">
                      <FormGroup>
                        <label>Firmante #3</label>
                        <Input
                          placeholder="Firmante #3"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pr-10" lg="3">
                      <FormGroup>
                        <label>Cant. firmas requeridas</label>
                        <Input
                          placeholder="Cant. firmas requeridas"
                          type="number"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-10" lg="3">
                      <FormGroup>
                        <label>Beneficiario</label>
                        <Input
                          placeholder="Beneficiario"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pr-10" lg="3" md="6">
                      <FormGroup>
                        <label>Fecha de apertura</label>
                        <DatePicker id="example-datepicker"
                          dayLabels={['Dom', 'Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb']}
                          monthLabels={['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']} />
                      </FormGroup>
                    </Col>
                    <Col className="pr-10" lg="3" md="6">
                      <FormGroup>
                        <label>Fecha de cierre</label>
                        <DatePicker id="example-datepicker"
                          dayLabels={['Dom', 'Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb']}
                          monthLabels={['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']} />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-10" lg="3" md="9">
                      <FormGroup>
                        <label>Dueño de la cuenta</label>
                        <Select
                          options={options}
                          placeholder="Seleccione..."
                          noOptionsMessage={({ inputValue }) => !inputValue ? "Cargando..." : "Sin resultados"}
                        />
                      </FormGroup>
                    </Col>
                    {/* <Col lg="2">
                      <FormGroup>
                        <Row>
                          <div className="update mt-2 ml-auto mr-auto ">
                            <Button
                              className="btn-round"
                              color="primary"
                              type="submit"
                            >
                              Detalles
                            </Button>
                          </div>
                        </Row>
                      </FormGroup>
                    </Col> */}
                    <Col className="pr-10" lg="3" md="3">
                      <FormGroup>
                        <label>Tipo de deposito preferido</label>
                        <Select
                          options={options}
                          placeholder="Seleccione..."
                          noOptionsMessage={({ inputValue }) => !inputValue ? "Cargando..." : "Sin resultados"}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <div className="update ml-auto mr-auto">
                      <Button
                        className="btn-round"
                        color="primary"
                        type="submit"
                      >
                        Crear
                      </Button>
                    </div>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default FormBankAccount;
