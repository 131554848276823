import DataTable from "react-data-table-component";

import LoadingCircles from "common/components/AnimatedIcons/LoadingCircles/LoadingCircles";
import styles from './AccessTable.module.scss';
import CedulaTable from "./CedulaTable/CedulaTable";
import { forwardRef, useEffect } from "react";

import './AccessTable.css';

const customStyles = {
    cells: {
        style: {
            userSelect: 'none'
        }
    }
};

const conditionalRowStyles = [
    {
        when: row => row.isSelected,
        style: {
            backgroundColor: "#e3f2fd",
        },
    },
];

function AccessTable(props) {
    const { data, setData, selectedLender } = props;

    useEffect(() => {
        try {
            /* document.getElementsByClassName('sc-hLseeU jiWeOY')[0].style.display = 'none';
            try {
                document.getElementsByClassName('sc-hLseeU jiWeOY')[0].nextSibling.style.padding = 0;
                document.getElementsByClassName('sc-hLseeU jiWeOY')[0].nextSibling.style.cursor = 'pointer';
            } catch (error) { } */
        } catch (error) { }
    }, [])

    const selectAll = () => {
        let allSelected = document.getElementById('select-all-access').checked;
        const newData = data.map(item => {
            const newCedulas = item.cedulas.map(cedula => ({ ...cedula, isSelected: !allSelected }));
            return { ...item, isSelected: !allSelected, cedulas: newCedulas };
        });
        document.getElementById('select-all-access').checked = !allSelected;
        setData(newData);
    }

    const columns = [
        {
            name:
                <div className={styles.header} onClick={selectAll}>
                    <input className={styles.headerCheckbox} type={'checkbox'} id='select-all-access' onClick={selectAll} />
                    <div className={styles.headerText}>Seleccionar todos los créditos</div>
                    <label style={{ position: 'absolute', marginTop: '10px', left: '19.5px', fontSize: '22px', fontWeight: '900', color: 'gray', display: 'none' }} id='select-some'>-</label>
                    <label className="form-check-label" id="booty-check-all" />
                </div>,
            selector: (row) => row.loan,
            left: true,
            cell: (row) => (
                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }} onClick={() => addSelectRow(row)}>
                    <div style={{ maxWidth: props.data[row.index_row].cedulas.length === 1 ? (props.data[row.index_row].cedulas[0]?.actual === '1' ? '100%' : '70%'): '100%' }}>
                        {row.loan + ' - ' + row.loan_name + (selectedLender?.id === row.owner ? '' : ' - ')}
                        <b>{(selectedLender?.id === row.owner ? '' : 'Propietario: ' + row.owner_name)}</b>
                    </div>
                    {props.data[row.index_row].cedulas.length === 1 ? (props.data[row.index_row].cedulas[0]?.actual === '1' ? null : <div style={{ color: 'red', marginRight: '2rem' }}>(Vendida)</div>) : null}
                </div>
            )
        },
    ];

    const Checkbox = forwardRef(({ onClick, ...rest }, ref) => {
        let flagChecked = false;
        let someChecked = false;
        let allChecked = false;
        if (rest.name.split('select-row-')[0] !== 'select-all-rows') {
            flagChecked = data[parseInt(rest.name.split('select-row-')[1])].isSelected;
            if (!flagChecked) {
                const cedulas = data[parseInt(rest.name.split('select-row-')[1])].cedulas;
                let childsSelected = 0;
                for (let index = 0; index < cedulas.length; index++) {
                    childsSelected += cedulas[index].isSelected ? 1 : 0;
                }

                someChecked = (childsSelected < data[parseInt(rest.name.split('select-row-')[1])].position.split(',').length && childsSelected !== 0);
            }
        }

        try {
            /* document.getElementsByClassName('sc-hLseeU jiWeOY')[0].nextSibling.style.padding = 0;
            document.getElementsByClassName('sc-hLseeU jiWeOY')[0].nextSibling.style.cursor = 'pointer'; */
        } catch (error) { }

        for (let index = 0; index < data.length; index++) {
            allChecked += data[index].isSelected ? 1 : 0;
        }
        try {
            if (data.length > allChecked && allChecked !== 0) {
                document.getElementById('select-some').style.display = '';
            } else {
                document.getElementById('select-some').style.display = 'none';
            }
            document.getElementById('select-all-access').checked = allChecked === data.length;
        } catch (error) { }

        return (
            <>
                <input
                    type="checkbox"
                    ref={ref}
                    onClick={onClick}
                    {...rest}
                    checked={flagChecked}
                    id={rest.name}
                />
                {
                    someChecked ?
                        <label style={{ position: 'absolute', left: '19.5px', marginTop: '4px', fontSize: '22px', fontWeight: '900', color: 'gray' }} >-</label>
                        :
                        <label className="form-check-label" id="booty-check" />
                }
            </>
        )
    });

    const handleChange = (state => {
        if (state.selectedCount === 0) {
            const newSelected = data.map(item => {
                if (item.byButton) {
                    const newCedulas = item.cedulas.map(row => ({ ...row, isSelected: false }));
                    delete item.byButton;
                    return { ...item, isSelected: false, cedulas: newCedulas }
                }
                return { ...item }
            });

            setData(newSelected);
        } else {
            const newSelected = data.map(item => {
                for (let index = 0; index < state.selectedRows.length; index++) {
                    if (parseInt(state.selectedRows[index].index_row) === parseInt(item.index_row)) {
                        const newCedulas = item.cedulas.map(row => ({ ...row, isSelected: true }));
                        return { ...item, isSelected: true, cedulas: newCedulas, byButton: true }
                    }
                }
                if (item.byButton) {
                    const newCedulas = item.cedulas.map(row => ({ ...row, isSelected: false }));
                    return { ...item, isSelected: false, cedulas: newCedulas }
                }
                return { ...item }
            });

            setData(newSelected);
        }
    });

    const addSelectRow = (state => {
        const newSelected = data.map(item => {
            if (state.index_row !== item.index_row) {
                return item;
            }
            const newCedulas = item.cedulas.map(row => ({ ...row, isSelected: !item.isSelected }));
            return { ...item, isSelected: !item.isSelected, cedulas: newCedulas }
        });
        setData(newSelected);
    });

    const ExpandedComponent = ({ data }) => {
        return (<div style={{ marginLeft: '3rem' }}>
            <CedulaTable
                data={props.data[data.index_row].cedulas}
                setData={setData}
                indexRow={data.index_row}
                selectedLender={selectedLender}
            />
        </div>);
    };

    const rowPreExpanded = row => !(row.position.split(',').length === 1);
    return (
        <DataTable
            noHeader
            columns={columns}
            data={data}
            customStyles={customStyles}
            allowOverflow
            pagination
            fixedHeader
            fixedHeaderScrollHeight="50vh"
            selectableRowsHighlight
            highlightOnHover
            pointerOnHover
            onRowClicked={addSelectRow}
            progressComponent={
                <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                    <div className={styles.alignTextCenter} style={{ marginTop: '0.5rem', marginBottom: '1rem' }}>
                        <LoadingCircles />
                    </div>
                    <div>
                        Obteniendo información...
                    </div>
                </div>
            }
            persistTableHead={true}
            noDataComponent={
                <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                    Este lender no tiene créditos asociados.
                </div>

            }
            selectableRows
            selectableRowsNoSelectAll={true}
            onSelectedRowsChange={handleChange}
            selectableRowsComponent={Checkbox}

            expandableRows
            expandableRowsComponent={ExpandedComponent}
            expandableRowExpanded={rowPreExpanded}
            expandableRowsHideExpander

            conditionalRowStyles={conditionalRowStyles}
        />
    );
}

export default AccessTable;
