import React, { useEffect } from "react";

import {
  Card,
  CardHeader,
  CardTitle,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  ModalFooter,
} from "reactstrap";
import NotificationAlert from "react-notification-alert";

import { useParams } from "react-router";
import axios from "axios";
import { useState } from "react";
import externalUrl from "config/externalUrl";
import FormCedulaOwnershipTerm from "./CedulaOwnershipTerm";
import { Link } from "react-router-dom";
import FormCedula from "./Cedula";

function FormCedulaOwnership(props) {
  const notificationAlert = React.useRef();

  let { id } = useParams();
  const [loan, setLoan] = useState({});
  const [lenders, setLenders] = useState([]);
  const [idCedulaPreSelected, setIdCedulaPreSelected] = useState(0);
  const [cedulas, setCedulas] = useState([]);
  const [cedulasData, setCedulasData] = useState([]);
  const [cedulaOwnership, setCedulaOwnership] = useState([{ lenders: [{ percentage: 100, lender: '', view: true }], settled_on: '', price: '', owed_capital_at_sell: '', view: true }]);
  const [distribution, setDistribution] = useState([]);
  const [billingType, setBillingType] = useState(1);
  const [alaparGeneralDistribution, setAlaparGeneralDistribution] = useState(44);
  const [update, setUpdate] = useState(false);
  const [updatedValues, setUpdatedValues] = useState(false);
  const [selectedCedula, setSelectedCedula] = useState({});
  const [cSelected, setCSelected] = useState([]); // 1. Retención de ISR 2. Servicios Alapar

  const [userData] = useState(JSON.parse(localStorage.getItem('userData')));
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState([]);

  const [modal, setModal] = useState({ view: false, title: '', msg: '', type: 'success' });
  const [modalOwner, setModalOwner] = useState({ view: false, type: true, id: 0 });

  useEffect(() => {
    if (props.location.update || id != null) {
      fetchData();
    }
  }, []); // eslint-disable-line

  async function fetchData() {
    axios.get(`${externalUrl.lenderUrl}/v1/lender/enabled`, {
      headers: { Authorization: `Bearer ${userData.private_token}`, },
    }).then(function (response) {
      if (response.data.error === '1') {
        setModal({ ...modal, title: 'Crear lenders', msg: 'No hay ningún lender creado o todos están deshabilitados. Pulse el botón para ir a crear uno.', type: 'error' });
        toggle();
        setLenders([]);
      } else {
        const data = response.data.msg.map((lender => ({ value: lender.id, label: lender.id + " - " + lender.display_name + " - " + lender.nit })));
        setLenders(data);
      }
    }).catch(function (response) { console.error(response); });

    axios.get(`${externalUrl.lenderUrl}/v1/loan/search?id=${id}`, {
      headers: { Authorization: `Bearer ${userData.private_token}`, },
    }).then(function (response) {
      if (response.data.error) {
        setLoan({});
        setCedulas([]);
      } else {
        setLoan(response.data.msg);
        const data = response.data.cedulas.map((cedula => ({ value: cedula.code, label: "Cédula # " + cedula.position })));
        setCedulas(data);
        setCedulasData(response.data.cedulas);
      }
    }).catch(function (error) {
      if (error.response.status === 401) {
        localStorage.removeItem('userData');
        window.location.replace(`${externalUrl.lenderUrl}/v1/auth/logout`);
      }
    });
  };

  const toggle = () => {
    setModal({ ...modal, view: !modal.view });
  }

  const toggleSuccess = () => {
    setModal({ ...modal, view: !modal.view });
    //notify("br", "Endoso de la cédula actualizado correctamente.", "success");
  }

  const togglePer = () => {
    setModal({ view: true, title: 'Cambio de cédula', msg: 'Si cambia la cédula seleccionada se perderá la información ya ingresada. ¿Desea continuar?', type: 'warning' });
  }

  const create = () => {
    const ownership = cedulaOwnership.filter(obj => obj.view).map(obj => obj);
    let errorSettled = false;
    let errorPrice = false;
    let errorLender = false;
    ownership.map((obj, index) => {
      if (obj.settled_on === '') {
        setError([...error, `settled_on-${index}`])
        if (!errorSettled) {
          notify("br", "No se puede dejar la fecha de venta vacío.", "danger");
          errorSettled = true;
        }
      }
      obj.lenders.map((lender, j) => {
        if (lender.lender === '' && lender.view) {
          setError([...error, `lender-${index}-${j}`]);
          if (!errorLender) {
            notify("br", "No se puede dejar el lender vacío.", "danger");
            errorLender = true;
          }
        }
        return lender;
      })
      return obj;
    });

    if (errorSettled || errorPrice || errorLender) {
      return;
    }

    const body = {
      userData: userData,
      cedula: selectedCedula,
      cedulaOwnership: ownership,
      alaparDistribution: alaparGeneralDistribution
    }

    axios.post(`${externalUrl.lenderUrl}/v1/cedula-ownership/${update ? 'update' : 'add'}`, body, {
      headers: { Authorization: `Bearer ${userData.private_token}`, },
    }).then(function (response) {
      changeCedula(selectedCedula.code);
      notify("br", "Endoso de la cédula actualizado correctamente.", "success");
      setModal({ view: true, title: '¿Continuar editando?', msg: '¿Desea continuar editando los endosos de las cédulas?', type: 'success' });
    }).catch(
      function () {
        /* localStorage.removeItem('userData');
        window.location.replace(`${externalUrl.loanUrl}/v1/auth/logout`); */
      }
    );
  }

  const notify = (place, text, type) => {
    var options = {
      place: place,
      message: (
        <div>
          <div>
            {text}
          </div>
        </div>
      ),
      type: type,
      icon: "nc-icon nc-simple-remove",
      autoDismiss: 7,
    };
    notificationAlert.current.notificationAlert(options);
  };

  const setCedula = (typeSet, value) => {
    if (typeSet && updatedValues) {
      setIdCedulaPreSelected(value);
      togglePer();
    } else {
      changeCedula(value);
    }
  };

  const changeCedula = (value) => {
    const cedula = cedulasData.find(val => (val.code === value));
    setSelectedCedula(cedula);
    setLoading(true);
    axios.get(`${externalUrl.lenderUrl}/v1/cedula-ownership/search?id=${cedula.id}`, {
      headers: { Authorization: `Bearer ${userData.private_token}`, },
    }).then(function (response) {
      if (response.data.error) {
        setUpdate(false);
        setCedulaOwnership([{ lenders: [{ percentage: 100, lender: '', view: true }], settled_on: '', price: '', owed_capital_at_sell: '', view: true }]);
      } else {
        setUpdate(true);
        setCedulaOwnership(response.data.msg);
        setBillingType(response.data.msg[0]['billing_type'])
        const retention = [];
        if (response.data.msg[0]['isr_retention'] == 1) { // eslint-disable-line
          retention.push(1);
        }
        if (response.data.msg[0]['alapar_service'] == 1) { // eslint-disable-line
          retention.push(2);
        }
        if (response.data.msg[0]['force_iva'] == 1) { // eslint-disable-line
          retention.push(3);
        }
        setCSelected(retention)
        const newDistribution = {};
        var before = '';
        var group = {};

        response.data.distribution.map((value, index) => {  // eslint-disable-line
          if (before !== value.lender) {
            if (index !== 0) {
              newDistribution[before] = group;
              group = {};
            }
            group[value.balance_type === null ? 'null' : value.balance_type] = value;
            before = value.lender;
          } else if ((response.data.distribution.length - 1) === index) {
            group[value.balance_type === null ? 'null' : value.balance_type] = value;
            newDistribution[value.lender] = group;
          } else {
            group[value.balance_type === null ? 'null' : value.balance_type] = value;
          }
        });
        setDistribution(newDistribution);
      }

      setUpdatedValues(false);
      setLoading(false);
    }).catch(function (response) {
      console.error(response);
    });

    if (modal.view) {
      toggle();
    }
  }

  const toggleOwnership = (type, i, j, id) => {
    if (update) {
      setModalOwner({ ...modalOwner, view: true, i: i, j: j, type: type, id: id })
    } else {
      if (type) {
        removeOwnership(i);
      } else {
        removeLenders(i, j);
      }
    }
  }

  const deleteOwnership = () => {
    axios.get(`${externalUrl.lenderUrl}/v1/cedula-ownership/delete-${modalOwner.type ? 'ownership' : 'term'}?id=${modalOwner.id}`, {
      headers: { Authorization: `Bearer ${userData.private_token}`, },
    }).then(function (response) {
      if (response.data.error) {
        notify("br", "Dato eliminado correctamente.", "success");
      } else {
        fetchData();
        notify("br", "Dato eliminado correctamente.", "success");
      }

      let newPercentage = 0;
      if (modalOwner.type) {
        removeOwnership(modalOwner.i);
      } else {
        newPercentage = removeLenders(modalOwner.i, modalOwner.j);
      }

      if (!response.data.error) {
        axios.post(`${externalUrl.lenderUrl}/v1/cedula-ownership/update-percentage?id=${cedulaOwnership[modalOwner.i].lenders[0].id}`, { percentage: newPercentage }, {
          headers: { Authorization: `Bearer ${userData.private_token}`, },
        }).then(function (response) {
          // Actualizado
        }).catch(function (error) { console.error(error); });
      }
      setModalOwner({ view: false, type: true, id: 0 })

    }).catch(function (response) {
      notify("br", "No se pudo completar la acción.", "danger");
      setModalOwner({ view: false, type: true, id: 0 })
    });
  }

  const removeOwnership = (i) => {
    setCedulaOwnership(prevState => {
      const newState = prevState.map((obj, index) => {
        if (index === i) {
          return { ...obj, view: false };
        }
        return obj;
      });
      return newState;
    });
  };

  const removeLenders = (i, j) => {
    const newLender = JSON.parse(JSON.stringify(cedulaOwnership));
    newLender[i].lenders[j] = { ...newLender[i].lenders[j], view: false };
    const newPercentage = parseFloat(newLender[i].lenders[0]['percentage']) + parseFloat(newLender[i].lenders[j]['percentage']);
    newLender[i].lenders[0]['percentage'] = newPercentage;
    setCedulaOwnership(newLender);
    return newPercentage;
  };

  return (
    <>
      <NotificationAlert ref={notificationAlert} />
      <div className="content">
        <Row>
          <Col sm="12" md="12" lg="12" xl="12">
            <div className="content">
              <Row>
                <Col md="12">
                  <Card className="card-user">
                    <CardHeader style={{ paddingTop: 0, display: 'flex', justifyContent: 'space-between' }}>
                      <CardTitle tag="h5">{loan?.name + (loan?.numero_cedulas_autorizadas === 1 ? '' : ' - (Cedulizado) ') + ' Exp.: ' + loan?.loan_id_exp}</CardTitle>
                      <Link to={`/admin/view-loan/${id}`}>
                        <Button
                          className="button-alapar-cancel"
                          outline
                          color="primary"
                        >
                          Volver al crédito
                        </Button>
                      </Link>
                    </CardHeader>
                  </Card>
                </Col>
              </Row>
            </div>
          </Col>

        </Row>
        <Row>
          <Col sm="8" md="6" lg="6" xl="4" className="left-padding">
            <FormCedula
              togglePer={togglePer}
              setCedulaOwnership={setCedulaOwnership}
              setIdCedulaPreSelected={setIdCedulaPreSelected}
              setCedula={setCedula}
              setUpdate={setUpdate}
              userData={userData}
              setLoading={setLoading}
              loan={loan}
              setCedulas={setCedulas}
              cedulas={cedulas}
              cedulasData={cedulasData}
              selectedCedula={selectedCedula}
              setSelectedCedula={setSelectedCedula}
              updatedValues={updatedValues}
              setUpdatedValues={setUpdatedValues}
              cedulaOwnership={cedulaOwnership[0].lenders}
              billingType={billingType}
              setBillingType={setBillingType}
              lenders={lenders}
              distribution={distribution}
              setDistribution={setDistribution}
              notify={notify}
              setAlaparGeneralDistribution={setAlaparGeneralDistribution}
              changeCedula={changeCedula}
              cSelected={cSelected}
              setCSelected={setCSelected}
            />
          </Col>

          <Col md="12" lg="12" xl="8">
            <FormCedulaOwnershipTerm
              loading={loading}
              error={error}
              setError={setError}
              update={update}
              toggleOwnership={toggleOwnership}
              removeOwnership={removeOwnership}
              cedulaOwnership={cedulaOwnership}
              setCedulaOwnership={setCedulaOwnership}
              loan={loan}
              lenders={lenders}
              create={create}
              selectedCedula={selectedCedula}
              updatedValues={updatedValues}
              setUpdatedValues={setUpdatedValues}
            />
          </Col>
        </Row>
      </div>

      <Modal isOpen={modal.view}
        style={{ top: '5vh' }}>
        <ModalHeader style={{ userSelect: 'none' }}>
          {modal.title}
        </ModalHeader>
        <ModalBody style={{ paddingBottom: '0.3rem' }}>
          <Label style={{ userSelect: 'none' }}>
            {modal.msg}
          </Label>
        </ModalBody>
        <ModalFooter style={{ paddingTop: '0.3rem', paddingBottom: '0.3rem' }}>
          {
            modal.type === 'success' ?
              <>
                <Link to={`/admin/view-loan/${id}`}>
                  <Button
                    className={"btn button-alapar-success button-left"}
                  >
                    Volver al detalle del crédito
                  </Button>
                </Link>
                {' '}
                <Button
                  className="button-alapar-cancel button-right"
                  outline
                  onClick={toggleSuccess}
                  color="primary">
                  Continuar editando
                </Button>
              </> :
              modal.type === 'warning' ?
                <>
                  <Button
                    className={"btn button-alapar-warning button-left"}
                    onClick={() => changeCedula(idCedulaPreSelected)}
                  >
                    Aceptar
                  </Button>
                  {' '}
                  <Button
                    className="button-alapar-cancel button-right"
                    outline
                    color="primary"
                    onClick={toggle}>
                    Cancelar
                  </Button>
                </>
                :
                <Link to={`/admin/create-lender`}>
                  <Button
                    className="button-alapar-success"
                    color="primary">
                    Ir a crear
                  </Button>
                </Link>
          }
        </ModalFooter>
      </Modal>


      <Modal isOpen={modalOwner.view}
        style={{ top: '5vh' }}>
        <ModalHeader style={{ userSelect: 'none' }}>
          Eliminar {modal.type ? ' endoso de cédula' : ' lender de la cédula'}
        </ModalHeader>
        <ModalBody style={{ paddingBottom: '0.3rem' }}>
          <Label style={{ userSelect: 'none' }}>
            ¿Desea eliminar {modal.type ? ' endoso de cédula' : ' lender de la cédula'}?
          </Label>
        </ModalBody>
        <ModalFooter style={{ paddingTop: '0.3rem', paddingBottom: '0.3rem' }}>
          <>
            <Button
              className={"btn button-alapar-danger button-left"}
              onClick={() => deleteOwnership()}
            >
              Eliminar
            </Button>
            {' '}
            <Button
              className="button-alapar-cancel button-right"
              outline
              color="primary"
              onClick={() => setModalOwner({ ...modalOwner, view: false })}>
              Cancelar
            </Button>
          </>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default FormCedulaOwnership;
