import React, { useState } from "react";

import {

    Button,
    Modal,
    ModalHeader,
    ModalFooter,
    ModalBody,
} from "reactstrap";
import externalUrl from "config/externalUrl";

import axios from "axios";
import CreateUser from "./CreateUser";

function ModalCreateUser(props) {
    const { modal, toggle, notify, fetchData, data, setData } = props;

    const [userData] = useState(JSON.parse(localStorage.getItem('userData')));

    const addUser = () => {
        const body = {
            uid: data.uid,
            display_name: data.display_name,
            name: data.name,
            last_name: data.last_name,
            email_address: data.email_address,
        };
        let to = '';
        if (data.uid === -1) {
            to = 'add';
        } else {
            to = 'update';
        }
        axios.post(`${externalUrl.lenderUrl}/v1/lender-user/${to}`,
            body,
            {
                headers: { Authorization: `Bearer ${userData.private_token}`, },
            }).then(function (response) {
                console.log(response.data);
                if (response.data.error === 1) {
                    notify("br", response.data.msg, "danger");
                } else {
                    notify("br", response.data.msg, "success");
                    fetchData();
                    toggle();
                }
            }).catch(function (err) { });
    }

    return (
        <Modal isOpen={modal} toggle={toggle} style={{ top: '5vh' }} >
            <ModalHeader toggle={toggle}>
                Crear usuario
            </ModalHeader>
            <ModalBody style={{ paddingBottom: '1rem' }}>
                <CreateUser data={data} setData={setData} />
            </ModalBody>
            <ModalFooter style={{ paddingTop: '0.3rem', paddingBottom: '0.3rem' }}>
                <Button
                    className="btn button-alapar-success"
                    onClick={addUser}
                >
                    {data.uid !== -1 ? 'Actualizar' : 'Crear'}
                </Button>
                {' '}
                <Button
                    className="button-alapar-cancel button-right"
                    outline
                    color="primary"
                    onClick={toggle}>
                    Cerrar
                </Button>
            </ModalFooter>
        </Modal >
    );
}

export default ModalCreateUser;
