/*!

=========================================================
* Paper Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import externalUrl from "config/externalUrl";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Container,
} from "reactstrap";

import routes from "routes.js";

function Header(props) {
    const [isOpen, setIsOpen] = React.useState(false);
    const [dropdownOpen, setDropdownOpen] = React.useState(false);
    const [color, setColor] = React.useState("transparent");
    const sidebarToggle = React.useRef();
    const location = useLocation();
    const [userData] = useState(JSON.parse(localStorage.getItem('userData')));
    const toggle = () => {
        if (isOpen) {
            setColor("transparent");
        } else {
            setColor("dark");
        }
        setIsOpen(!isOpen);
    };
    const dropdownToggle = (e) => {
        setDropdownOpen(!dropdownOpen);
    };
    const getBrand = () => {
        /* console.log(routes);
        console.log(window.location.href); */
        let brandName = "Volver al inicio";
        routes.map((prop, key) => { // eslint-disable-line
            if (window.location.href.indexOf(prop.layout + prop.path) !== -1) {
                brandName = prop.name;
            }
            //return null;
        });
        return brandName;
    };
    const openSidebar = () => {
        document.documentElement.classList.toggle("nav-open");
        sidebarToggle.current.classList.toggle("toggled");
    };
    // function that adds color dark/transparent to the navbar on resize (this is for the collapse)
    const updateColor = () => {
        if (window.innerWidth < 993 && isOpen) {
            setColor("dark");
        } else {
            setColor("transparent");
        }
    };
    React.useEffect(() => {
        window.addEventListener("resize", updateColor.bind(this));
    });
    React.useEffect(() => {
        if (
            window.innerWidth < 993 &&
            document.documentElement.className.indexOf("nav-open") !== -1
        ) {
            document.documentElement.classList.toggle("nav-open");
            sidebarToggle.current.classList.toggle("toggled");
        }
    }, [location]);

    const logout = () => {
        localStorage.removeItem('userData');
        window.location.replace(`${externalUrl.lenderUrl}/v1/auth/logout`);
    }

    return (
        // add or remove classes depending if we are on full-screen-maps page or not
        <Navbar
            color={
                props.location.pathname.indexOf("full-screen-maps") !== -1
                    ? "dark"
                    : color
            }
            expand="lg"
            className={
                props.location.pathname.indexOf("full-screen-maps") !== -1
                    ? "navbar-absolute fixed-top"
                    : "navbar-absolute fixed-top " +
                    (color === "transparent" ? "navbar-transparent " : "")
            }
        >
            <Container fluid>
                <div className="navbar-wrapper">
                    <div className="navbar-toggle">
                        <button
                            type="button"
                            ref={sidebarToggle}
                            className="navbar-toggler"
                            onClick={() => openSidebar()}
                        >
                            <span className="navbar-toggler-bar bar1" />
                            <span className="navbar-toggler-bar bar2" />
                            <span className="navbar-toggler-bar bar3" />
                        </button>
                    </div>
                    <NavbarBrand href="/">{getBrand()}</NavbarBrand>
                </div>
                <NavbarToggler onClick={toggle}>
                    <span className="navbar-toggler-bar navbar-kebab" />
                    <span className="navbar-toggler-bar navbar-kebab" />
                    <span className="navbar-toggler-bar navbar-kebab" />
                </NavbarToggler>
                <Collapse isOpen={isOpen} navbar className="justify-content-end">
                    {/* <form>
                            <InputGroup className="no-border">
                            <Input placeholder="Search..." />
                            <InputGroupAddon addonType="append">
                            <InputGroupText>
                            <i className="nc-icon nc-zoom-split" />
                            </InputGroupText>
                            </InputGroupAddon>
                            </InputGroup>
                            </form> */}
                    <Nav navbar>
                        {/* <NavItem>
                                <Link to="#pablo" className="nav-link btn-magnify">
                                <i className="nc-icon nc-layout-11" />
                                <p>
                                <span className="d-lg-none d-md-block">Stats</span>
                                </p>
                                </Link>
                                </NavItem> */}
                        <Dropdown
                            nav
                            isOpen={dropdownOpen}
                            toggle={(e) => dropdownToggle(e)}
                        >
                            <center>
                                <DropdownToggle caret nav>
                                    {userData == null ? 'Nombre usuario' : userData.display_name} &nbsp;
                                </DropdownToggle>
                                <DropdownMenu /* left */>
                                    {/* <DropdownItem tag="a">Cuenta</DropdownItem> */}
                                    <DropdownItem tag="a" onClick={logout}>Cerrar sesión</DropdownItem>
                                </DropdownMenu>
                            </center>
                        </Dropdown>
                        {/* <NavItem>
                                <Link to="#pablo" className="nav-link btn-rotate">
                                <i className="nc-icon nc-settings-gear-65" />
                                <p>
                                <span className="d-lg-none d-md-block">Account</span>
                                </p>
                                </Link>
                                </NavItem> */}
                    </Nav>
                </Collapse>
            </Container>
        </Navbar>
    );
}

export default Header;
