import Select from 'react-select';
import styles from './LenderSelect.module.scss';

function LenderSelect(props) {
    const { selectedLender, setSelectedLender, lenders } = props;
    const filterOption = (option, inputValue) => {
        return (option.data.label.toString().normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().match(inputValue.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) || []).length > 0;
    }

    return (
        <Select
            options={lenders}
            getOptionLabel={(option) =>
                <div className={styles.optionLabel}>
                    <div className={styles.label}>
                        {option.label}
                    </div>
                    {
                        option.data.count === '0' ? <></> :
                            <div className={styles.countDiv}>
                                <div className={styles.countLabel} id={'count-access'}>
                                    {option.data.count}
                                </div>
                            </div>
                    }
                </div>
            }
            filterOption={filterOption}
            placeholder={selectedLender.id === '' ? 'Seleccione...' :
                lenders.filter(option =>
                    option.value === selectedLender.id)[0]?.label}
            noOptionsMessage={({ inputValue }) => !inputValue ? "Cargando..." : "Sin resultados"}
            name='lender'
            onChange={(e) => setSelectedLender(e.data)}
            className="create-select lender-select"
            menuPortalTarget={document.body}
            styles={{ textOverflow: 'ellipsis', menuPortal: base => ({ ...base, zIndex: 9999 }), width: '5vw' }}
        />
    );
}

export default LenderSelect;


