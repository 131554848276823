import React, { useEffect, useState } from "react";

import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  FormGroup,
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import NotificationAlert from "react-notification-alert";

import Skeleton from 'react-loading-skeleton'

import { Link, Redirect, useParams } from "react-router-dom";
import axios from "axios";
import externalUrl from "config/externalUrl";
import ViewCedulaOwnership from "./CedulaOwnership";
import { BsArrowClockwise } from "react-icons/bs";

const styleTitle = {
  fontSize: '1rem',
  margin: 0,
  fontWeight: 700,
  lineHeight: 1.42857143,
};

const styleLabel = {
  fontSize: '1rem',
  color: '#252422',
  lineHeight: 1.42857143,
};

const boxInfo = { display: 'flex', flexDirection: 'column', paddingLeft: '0.5rem', paddingRight: '2px' };

function ViewLoan(props) {
  const notificationAlert = React.useRef();
  //const [state] = useState(props.location.state);
  let { id } = useParams();
  const [userData] = useState(JSON.parse(localStorage.getItem('userData')));

  //const [modal, setModal] = useState(false);
  const [redirect/* , setRedirect */] = useState({ status: false, to: '/admin/lender' });
  /* const [loanError, setLoanError] = useState({ msg: '', error: false }); */
  const [loadingLoan, setLoadingLoan] = useState(false);
  const [loading, setLoading] = useState(false);

  const [loan, setLoan] = useState({});
  /* const [loanTypeSelected, setLoanType] = useState({}); */
  const [lenders, setLenders] = useState([]);
  const [cedulas, setCedulas] = useState([]);
  const [cedulasData, setCedulasData] = useState([]);

  /* const [modal, setModal] = useState(false); */

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  useEffect(() => {
    if (id !== null) {
      fetchData();
    }
  }, []); // eslint-disable-line

  const loanType = [
    { value: 1, label: 'Cédula' },
    { value: 2, label: 'Mutuo Hipotecario ' },
    { value: 3, label: 'Pagaré' },
    { value: 4, label: 'Compraventa' },
    { value: 5, label: 'Reconocimiento de deuda' },
    { value: 6, label: 'Promesa de venta' },
    { value: 7, label: 'Contrato fiduciario' },
  ];

  /* const changeValueSelect = (e) => {
    setLoanType(e.value);
  }; */

  async function fetchData() {
    if (!loadingLoan) {
      setLoadingLoan(true);
    }

    axios.get(`${externalUrl.lenderUrl}/v1/lender/enabled`, {
      headers: { Authorization: `Bearer ${userData.private_token}`, },
    }).then(function (response) {
      if (response.data.error === '1') {
        setLenders([]);
      } else {
        setLenders(response.data.msg);
      }
    }).catch(function (response) { console.error(response); });

    axios.get(`${externalUrl.lenderUrl}/v1/loan/search?id=${id}`, {
      headers: { Authorization: `Bearer ${userData.private_token}`, },
    }).then(function (response) {
      if (response.data.error) {
        setLoan({});
        setCedulas([]);
      } else {
        setLoan(response.data.msg);
        const data = response.data.cedulas.map((cedula => ({ value: cedula.code, label: "Cédula # " + cedula.position })));
        setCedulas(data);
        setCedulasData(response.data.cedulas);
        setLoadingLoan(false);
        /* setLoanType(response.data.msg.type); */
      }

    }).catch(function (error) {
      if (error.response.status === 401) {
        localStorage.removeItem('userData');
        window.location.replace(`${externalUrl.lenderUrl}/v1/auth/logout`);
      } else {
        console.error(error);
      }
    });
  }

  const updateLoan = () => {
    if (loading) {
      notify("br", "El crédito se está actualizando.", "warning");
      return;
    } else {
      setLoading(true);
    }

    axios.get(`${externalUrl.lenderUrl}/v1/loan/update?id=${id}`, {
      headers: { Authorization: `Bearer ${userData.private_token}`, },
    }).then(function (response) {
      if (!response.data.error) {
        axios.get(`${externalUrl.lenderUrl}/v1/cedula/update-all?id=${id}`, {
          headers: { Authorization: `Bearer ${userData.private_token}`, },
        }).then(function (res) {
          if (res.data.error) {
            /* setLoanError({ msg: res.data.msg, error: false }); */
            notify("br", res.data.msg, "danger");
          } else {
            notify("br", "Crédito actualizado correctamente.", "success");
            fetchData();
          }
          setLoading(false);
        }).catch(
          function (error) {
            setLoading(false);
            console.error(error);
            notify("br", "No se ha actualizar.", "danger");
          });
      } else {
        /* setLoanError({ msg: 'No se ha podido actualizar', error: true }); */
        notify("br", 'No se ha podido actualizar', "danger");
      }
      setLoading(false);
    }).catch(
      function (error) {
        setLoading(false);
        console.error(error);
        /* notify("br", "No se ha podido crear.", "danger"); */
      });
  };

  const changeLoanType = (value) => {
    axios.get(`${externalUrl.lenderUrl}/v1/loan/change-loan-type?id=${loan?.loan_id}&type=${value}`, {
      headers: { Authorization: `Bearer ${userData.private_token}`, },
    }).then(function (response) {
      if (response.data.error === 1) {
        notify("br", "El crédito no existe", "danger");
      } else {
        notify("br", "Tipo actualizado correctamente.", "success");
        setLoan({ ...loan, type: value });
      }
    }).catch(function (response) { console.error(response); });
    /* setModal(false); */
  }

  const notify = (place, text, type) => {
    var icon = '';
    if (type === 'success') {
      icon = 'nc-check-2';
    } else if (type === 'info') {
      icon = 'nc-alert-circle-i';
    } else if (type === 'warning') {
      icon = 'nc-bell-55';
    } else if (type === 'danger') {
      icon = 'nc-simple-remove';
    }

    var options = {
      place: place,
      message: (
        <div>
          <div>
            {text}
          </div>
        </div>
      ),
      type: type,
      icon: `nc-icon ${icon}`,
      autoDismiss: 7,
    };
    notificationAlert.current.notificationAlert(options);
  };

  return (
    <>
      <NotificationAlert ref={notificationAlert} />
      <div className="content">
        <Row>
          <Col md="12">
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Card className="card-user" style={{ width: '100%', minWidth: '340px' }}>
                <CardHeader>
                  <CardTitle tag="h5">
                    {
                      loadingLoan ?
                        <Skeleton count={1} /> :
                        <div style={{ paddingBottom: '0.4rem', marginTop: '-0.4rem', display: 'flex' }}>
                          {loan?.name + (loan?.numero_cedulas_autorizadas === 1 ? '' : ' - (Cedulizado) ') + ' Exp.: ' + loan?.loan_id_exp} &nbsp;&nbsp;&nbsp;

                          {loan?.numero_cedulas_autorizadas === 1 ?
                            <Dropdown isOpen={dropdownOpen} toggle={toggle} direction='right'>
                              <DropdownToggle caret size="sm" className="btn" style={{ backgroundColor: 'transparent', fontSize: '14px', height: '2.2rem', color: '#51cbce', paddingTop: 0, marginTop: 0, paddingBottom: 0, marginBottom: 0 }}>
                                {
                                  loanType.find(val => (val.value === loan?.type))?.label
                                }
                                &nbsp;&nbsp;
                              </DropdownToggle>
                              <DropdownMenu>
                                <DropdownItem header>Cambiar tipo</DropdownItem>
                                {
                                  loanType.map(type => (
                                    <DropdownItem key={type.value} onClick={() => changeLoanType(type.value)}>{type.label}</DropdownItem>
                                  ))
                                }
                              </DropdownMenu>
                            </Dropdown>
                            :
                            <></>
                          }
                        </div>
                    }
                  </CardTitle>
                </CardHeader>
              </Card>
            </div>
          </Col>
          <Col md="12">
            <Card className="card-user" style={{ width: '100%', minWidth: '340px' }}>
              <CardHeader>
                <CardTitle tag="h5">
                  <div style={{ marginTop: '-0.4rem', display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex' }}>
                      Datos del préstamo&nbsp;&nbsp;
                      <Button className='btn' color='link' onClick={() => { updateLoan(loan); }} style={{ height: '2.2rem', display: 'flex', color: '#51cbce', paddingTop: 4, marginTop: 0, paddingBottom: 0, marginBottom: 0 }}>
                        <p style={{ fontSize: '14px', paddingBottom: 0, marginBottom: 0 }}>Actualizar &nbsp;</p>
                        <BsArrowClockwise size={20} style={{ marginTop: '3px' }} />
                      </Button>
                    </div>
                    <Link to={`/admin/create-cedula-ownership/${id}`}>
                      <Button
                        className="btn button-alapar-success"
                        color="primary"
                        type="submit"
                        style={{ marginTop: '-0.2rem' }}
                      >
                        Registrar endosos de la cédula
                      </Button>
                    </Link>
                  </div>
                </CardTitle>
              </CardHeader>
              <CardBody style={{ minHeight: 0, paddingLeft: '2rem' }}>
                <Row>
                  <div style={boxInfo}>
                    <FormGroup>
                      <p style={styleTitle}>Monto autorizado</p>
                      {
                        loadingLoan ?
                          <Skeleton count={1} /> :
                          <div style={{ float: 'right' }}>
                            <label style={styleLabel}>{(loan.currency === 'USD' ? "US$" : "Q") + loan.authorized_amt}</label>
                          </div>
                      }
                    </FormGroup>
                  </div>
                  <div style={boxInfo}>
                    <FormGroup>
                      <p style={styleTitle}>Plazo</p>
                      {
                        loadingLoan ?
                          <Skeleton count={2} /> :
                          <div style={{ float: 'right' }}>
                            <label style={styleLabel}>{loan.total_payments + ' Meses'}</label>
                            <br />
                            <label style={styleLabel}>{(loan.total_payments / 12).toFixed(2) + ' (Años)'}</label>
                          </div>
                      }
                    </FormGroup>
                  </div>
                  <div style={boxInfo}>
                    <FormGroup>
                      <p style={styleTitle}>Cuota nivelada</p>
                      {
                        loadingLoan ?
                          <Skeleton count={1} /> :
                          <div style={{ float: 'right' }}>
                            <label style={styleLabel}>{(loan.currency === 'USD' ? "US$" : "Q") + loan.starting_pmt_amt}</label>
                          </div>
                      }
                    </FormGroup>
                  </div>
                  <div style={boxInfo}>
                    <FormGroup>
                      <p style={styleTitle}>Tasa por pago pronto</p>
                      {
                        loadingLoan ?
                          <Skeleton count={1} /> :
                          <div style={{ float: 'right' }}>
                            <label style={styleLabel}>{(loan.low_rate * 100).toFixed(2) + '%'}</label>
                          </div>
                      }
                    </FormGroup>
                  </div>
                  <div style={boxInfo}>
                    <FormGroup>
                      <p style={styleTitle}>Tasa regular</p>
                      {
                        loadingLoan ?
                          <Skeleton count={1} /> :
                          <div style={{ float: 'right' }}>
                            <label style={styleLabel}>{(loan.regular_rate * 100).toFixed(2) + '%'}</label>
                          </div>
                      }
                    </FormGroup>
                  </div>
                  <div style={boxInfo}>
                    <FormGroup>
                      <p style={styleTitle}>Mora</p>
                      {
                        loadingLoan ?
                          <Skeleton count={1} /> :
                          <div style={{ float: 'right' }}>
                            <label style={styleLabel}>{(loan.montly_late_pmt_rate * 100).toFixed(2) + '%'}</label>
                          </div>
                      }
                    </FormGroup>
                  </div>
                  <div style={boxInfo}>
                    <FormGroup>
                      <p style={styleTitle}>Multa</p>
                      {
                        loadingLoan ?
                          <Skeleton count={1} /> :
                          <div style={{ float: 'right' }}>
                            <label style={styleLabel}>{(loan.currency === 'USD' ? "US$" : "Q") + loan.penalty_fee}</label>
                          </div>
                      }
                    </FormGroup>
                  </div>
                  <div style={boxInfo}>
                    <FormGroup>
                      <p style={styleTitle}>Día de pago</p>
                      {
                        loadingLoan ?
                          <Skeleton count={1} /> :
                          <div style={{ float: 'right' }}>
                            <label style={styleLabel}>{loan.pmt_day_of_month}</label>
                          </div>
                      }
                    </FormGroup>
                  </div>
                </Row>
                <hr />
                <Row>
                  <div style={boxInfo}>
                    <FormGroup>
                      <p style={styleTitle}>Multa por cheque rechazado</p>
                      {
                        loadingLoan ?
                          <Skeleton count={1} /> :
                          <div style={{ float: 'right' }}>
                            <label style={styleLabel}>{(loan.currency === 'USD' ? "US$" : "Q") + loan.bad_check_fee}</label>
                          </div>
                      }
                    </FormGroup>
                  </div>
                  <div style={boxInfo}>
                    <FormGroup>
                      <p style={styleTitle}>Fecha de desembolso</p>
                      {
                        loadingLoan ?
                          <Skeleton count={1} /> :
                          <div style={{ float: 'right' }}>
                            <label style={styleLabel}>{loan.start_date}</label>
                          </div>
                      }
                    </FormGroup>
                  </div>
                  <div style={boxInfo}>
                    <FormGroup>
                      <p style={styleTitle}>Mínimo aporte a capital</p>
                      {
                        loadingLoan ?
                          <Skeleton count={1} /> :
                          <div style={{ float: 'right' }}>
                            <label style={styleLabel}>{(loan.currency === 'USD' ? "US$" : "Q") + loan.min_capital_pmt}</label>
                          </div>
                      }
                    </FormGroup>
                  </div>
                  <div style={boxInfo}>
                    <FormGroup>
                      <p style={styleTitle}>Max. diferencia cuota sin penalidad</p>
                      {
                        loadingLoan ?
                          <Skeleton count={1} /> :
                          <div style={{ float: 'right' }}>
                            <label style={styleLabel}>{(loan.currency === 'USD' ? "US$" : "Q") + loan.max_capital_wout_penalty}</label>
                          </div>
                      }
                    </FormGroup>
                  </div>
                  <div style={boxInfo}>
                    <FormGroup>
                      <p style={styleTitle}>Cant. de cédulas autorizadas</p>
                      {
                        loadingLoan ?
                          <Skeleton count={1} /> :
                          <div style={{ float: 'right' }}>
                            <label style={styleLabel}>{loan.numero_cedulas_autorizadas}</label>
                          </div>
                      }
                    </FormGroup>
                  </div>
                  {/* <div style={boxInfo}>
                    <FormGroup>
                      <p style={styleTitle}>Notas</p>
                      <div style={{ float: 'right' }}>
                        <label style={styleLabel}>{loan.notes}</label>
                      </div>
                    </FormGroup>
                  </div> */}
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col md="12">
            <ViewCedulaOwnership lenders={lenders} loan={loan} cedulas={cedulas} cedulasData={cedulasData} />
          </Col>
        </Row>

        <Row>
          <Col md="12">
          </Col>
        </Row>
      </div>
      {redirect.status ? <Redirect to={redirect.to} /> : null}
    </>
  );
}

export default ViewLoan;
